import React, { useEffect, useRef, useState } from "react";
import styles from "./signUp.module.scss";
import { Field, Form, Formik } from "formik";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { NavLink, useNavigate } from "react-router-dom";
import * as YUP from "yup";
import ErrorMessage from "../../components/ErrorMessage";
import SignUpDialog from "./signUpDialog";
import { api } from "../../api/api";
import { Toast } from "primereact/toast";
import { useLoginUserData } from "../../store/login";
import { userRegistration, adminRegistration } from "../../services/signUp";
import { appInsights } from "../../AppInsights";
const initialValue = {
  firstName: "",
  lastName: "",
  userName: "",
  email: "",
  password: "",
  roleId: "",
};

const SignUp = () => {
  appInsights.trackPageView("UI",{name: 'SignUp.Jsx component visited' });
  const { loginUserData } = useLoginUserData();
  const navigate = useNavigate();
  const toast = useRef(null);
  const [selectedTitle, setSelectedTitle] = useState(null);
  const [visible, setVisible] = useState(false);
  const [verificationError, setVerificationError] = useState(null);
  const [emailForDialog, setEmailForDialog] = useState("");
  const [otpValue, setOtpValue] = useState("");
  const [otpVerified, setOtpVerified] = useState(false);
  const [loading, setLoading] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [emailVerifiedMessage, setEmailVerifiedMessage] = useState("");
  const [signUpError, setSignUpError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [adminRole, setAdminRole] = useState([]);
  const [selectedAdminRole, setSelectedAdminRole] = useState(null);
  const [adminRoleType, setAdminRoleType] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState("");
  const superAdminLogin = loginUserData && loginUserData.roleId === 1;

  const title = [
    { name: "Select", value: 1 },
    { name: "Mr", value: 2 },
    { name: "Mrs", value: 3 },
    { name: "Miss", value: 4 },
    { name: "Other", value: 5 },
  ];

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await api.get(`api/Roles`);
        const fetchedRoles = response?.data?.result?.map((role) => ({
          name: role?.roleName,
          value: role?.roleId,
        }));
        setAdminRole(fetchedRoles);
      } catch (error) {
        appInsights.trackException("UI",{ exception: error });
        console.error("Failed to fetch roles:", error);
      }
    };
    if (superAdminLogin) {
      fetchRoles();
    }
  }, []);

  const validationSchema = YUP.object().shape({
    firstName: YUP.string()
      .required("First Name is required")
      .test(
        "no-only-spaces",
        "Field can't be empty",
        (value) => value && value?.trim()?.length > 0
      )
      .matches(/^[^0-9]*$/, "Field cannot contain numbers")
      .max(50, "Name must be at most 50 characters"),
    lastName: YUP.string()
      .required("Last Name is required")
      .test(
        "no-only-spaces",
        "Field can't be empty",
        (value) => value && value?.trim()?.length > 0
      )
      .matches(/^[^0-9]*$/, "Field cannot contain numbers")
      .max(50, "Name must be at most 50 characters"),
    userName: YUP.string()
      .required("Username is required")
      .min(6, "Username must be at least 6 characters")
      .max(12, "Username must be at most 12 characters")
      .matches(
        /^(?!.*\s)[a-zA-Z0-9]+$/,
        "Username can only contain letters and numbers without spaces"
      ),
    email: YUP.string()
      .required("Email is required")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Invalid email address"
      ),
    password: YUP.string()
      .required("Password is required")
      .min(6, "The password must meet the following criteria")
      .matches(/[A-Za-z]/, "The password must meet the following criteria")
      .matches(/[0-9]/, "The password must meet the following criteria"),
    // roleId: YUP.number().required("Field is required")
    roleId: superAdminLogin
      ? YUP.number().required("Role is required")
      : YUP.number().nullable(true),
  });
  const handleTitleChange = (e) => {
    setSelectedTitle(Number(e.target.value));
  };
  const handleRoleChange = (e) => {
    setSelectedAdminRole(e.value);
    if (e?.value === 4) {
      setAdminRoleType("Primaryadmin");
    } else if (e?.value === 5) {
      setAdminRoleType("Secondaryadmin");
    } else {
      setAdminRoleType(0);
    }
  };
  const handleVerify = async (email) => {
    try {
      setOtpLoading(true);
      const response = await api.post(`api/User/Register/SendOTP`, { email });
      setVisible(true);
      setVerificationError(null);
      setEmailForDialog(email);
      setEmailVerifiedMessage("Email Verified");
    } catch (error) {
      appInsights.trackException("UI",{ exception: error });
      console.log(error);
      setVerificationError(error?.response?.data?.title);
      setOtpLoading(false);
    } finally {
      setOtpLoading(false);
    }
  };
  const handleOTPVerified = (otp) => {
    setOtpValue(otp);
    setOtpVerified(true);
    setEmailVerified(true);
  };
  const showSuccess = () => {
    toast?.current?.show({
      severity: "success",
      summary: "Sign Up Successful",
      detail: "Redirecting to Login Page",
      life: 3000,
    });
  };
  const adminSuccess = () => {
    toast?.current?.show({
      severity: "success",
      summary: "Admin Added Successfully",
      detail: "Redirecting to Home Page",
      life: 3000,
    });
  };
  // const showError = () => {
  //     toast.current.show({ severity: 'error', summary: 'Error', detail: 'An Unexpected Error Occurred', life: 3000 });
  // }
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleSignUp = async (values) => {
    try {
      if (!superAdminLogin) {
        if (!otpVerified) {
          setVerificationError("Please verify your email first.");
          return;
        } else {
          setLoading(true);
          const response = await userRegistration({
            ...values,
            titleId: selectedTitle,
            otp: otpValue,
            roleId: 0,
          });
          setButtonDisabled(true);
          showSuccess();

          setTimeout(() => {
            navigate("/Login");
          }, 3000);
        }
      } else {
        if (selectedAdminRole == null) {
          setSignUpError("Role is required.");
          return;
        }
        setLoading(true);
        const response = await adminRegistration(
          {
            ...values,
            titleId: selectedTitle,
            roleId: selectedAdminRole,
            otp: "",
          }
          // {
          //     headers: {
          //         Authorization: `Bearer ${loginUserData.token}`
          //     }
          // }
        );
        setButtonDisabled(true);
        adminSuccess();
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
    } catch (error) {
      appInsights.trackException("UI",{ exception: error });
      setSignUpError(error?.message);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container-fluid">
      <Toast ref={toast} />
      <div className="row justify-content-center">
        <div className="col-12 col-md-8 col-lg-6 col-xl-5 px-lg-0">
          <Formik
            initialValues={initialValue}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              handleSignUp(values);
            }}
            validateOnChange={true}
            validateOnBlur={true}
          >
            {({
              values,
              setFieldValue,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <Form name="signUpForm" onSubmit={handleSubmit}>
                <div className={`bg-white my-5 p-3 ${styles.signup_container}`}>
                  <div className="row">
                    <div className={`col-12 ${styles.signup_header}`}>
                  
                        <>
                          <h4>SIGN UP</h4>
                          <p className="mb-4">
                            Join Us Today: Please Create Your Account.
                          </p>
                        </>
                      
                    </div>
                    {superAdminLogin ? (
                      <div className="col-12">
                        <div className="mb-2">
                          <label htmlFor="adminRole">
                            <span>Role</span>
                            <span className={`ms-1 ${styles.asterisk}`}>*</span>
                          </label>
                        </div>
                        <Dropdown
                          value={selectedAdminRole}
                          onChange={(e) => {
                            setFieldValue("roleId", e.value);
                            handleRoleChange(e);
                          }}
                          options={adminRole}
                          optionLabel="name"
                          placeholder="Select"
                          className={`${styles.roleDropdown}`}
                        />
                        <div className={`${styles.admin_type}`}>
                          {adminRoleType === "Primaryadmin" && (
                            <div>
                              <span>
                                <svg
                                  xmlns="tp://www.w3.org/2000/svg"
                                  width="17"
                                  height="17"
                                  viewBox="0 0 18 18"
                                  fill="none"
                                >
                                  <path
                                    d="M9 13.75C8.80189 13.7474 8.61263 13.6676 8.47253 13.5275C8.33244 13.3874 8.25259 13.1981 8.25 13V8C8.25 7.80109 8.32902 7.61032 8.46967 7.46967C8.61032 7.32902 8.80109 7.25 9 7.25C9.19891 7.25 9.38968 7.32902 9.53033 7.46967C9.67098 7.61032 9.75 7.80109 9.75 8V13C9.74741 13.1981 9.66756 13.3874 9.52747 13.5275C9.38737 13.6676 9.19811 13.7474 9 13.75V13.75Z"
                                    fill="#0EA5E9"
                                  />
                                  <path
                                    d="M9 6.25C8.80189 6.24741 8.61263 6.16756 8.47253 6.02747C8.33244 5.88737 8.25259 5.69811 8.25 5.5V5C8.25 4.80109 8.32902 4.61032 8.46967 4.46967C8.61032 4.32902 8.80109 4.25 9 4.25C9.19891 4.25 9.38968 4.32902 9.53033 4.46967C9.67098 4.61032 9.75 4.80109 9.75 5V5.5C9.74741 5.69811 9.66756 5.88737 9.52747 6.02747C9.38737 6.16756 9.19811 6.24741 9 6.25Z"
                                    fill="#0EA5E9"
                                  />
                                  <path
                                    d="M9 18C7.21997 18 5.47991 17.4722 3.99987 16.4832C2.51983 15.4943 1.36628 14.0887 0.685088 12.4442C0.00389951 10.7996 -0.17433 8.99002 0.172937 7.24419C0.520204 5.49836 1.37737 3.89472 2.63604 2.63604C3.89472 1.37737 5.49836 0.520204 7.24419 0.172937C8.99002 -0.17433 10.7996 0.00389951 12.4442 0.685088C14.0887 1.36628 15.4943 2.51983 16.4832 3.99987C17.4722 5.47991 18 7.21997 18 9C18 11.387 17.0518 13.6761 15.364 15.364C13.6761 17.0518 11.387 18 9 18V18ZM9 1.5C7.51664 1.5 6.0666 1.93987 4.83323 2.76398C3.59986 3.58809 2.63856 4.75943 2.07091 6.12988C1.50325 7.50032 1.35473 9.00832 1.64411 10.4632C1.9335 11.918 2.64781 13.2544 3.6967 14.3033C4.7456 15.3522 6.08197 16.0665 7.53683 16.3559C8.99168 16.6453 10.4997 16.4968 11.8701 15.9291C13.2406 15.3614 14.4119 14.4001 15.236 13.1668C16.0601 11.9334 16.5 10.4834 16.5 9C16.5 7.01088 15.7098 5.10323 14.3033 3.6967C12.8968 2.29018 10.9891 1.5 9 1.5Z"
                                    fill="#0EA5E9"
                                  />
                                </svg>
                              </span>
                              <span className="ps-1 pt-1">
                                Capable of uploading,updating, and viewing
                                quizzes.
                              </span>
                            </div>
                          )}
                          {adminRoleType === "Secondaryadmin" && (
                            <div>
                              <span>
                                <svg
                                  xmlns="tp://www.w3.org/2000/svg"
                                  width="17"
                                  height="17"
                                  viewBox="0 0 18 18"
                                  fill="none"
                                >
                                  <path
                                    d="M9 13.75C8.80189 13.7474 8.61263 13.6676 8.47253 13.5275C8.33244 13.3874 8.25259 13.1981 8.25 13V8C8.25 7.80109 8.32902 7.61032 8.46967 7.46967C8.61032 7.32902 8.80109 7.25 9 7.25C9.19891 7.25 9.38968 7.32902 9.53033 7.46967C9.67098 7.61032 9.75 7.80109 9.75 8V13C9.74741 13.1981 9.66756 13.3874 9.52747 13.5275C9.38737 13.6676 9.19811 13.7474 9 13.75V13.75Z"
                                    fill="#0EA5E9"
                                  />
                                  <path
                                    d="M9 6.25C8.80189 6.24741 8.61263 6.16756 8.47253 6.02747C8.33244 5.88737 8.25259 5.69811 8.25 5.5V5C8.25 4.80109 8.32902 4.61032 8.46967 4.46967C8.61032 4.32902 8.80109 4.25 9 4.25C9.19891 4.25 9.38968 4.32902 9.53033 4.46967C9.67098 4.61032 9.75 4.80109 9.75 5V5.5C9.74741 5.69811 9.66756 5.88737 9.52747 6.02747C9.38737 6.16756 9.19811 6.24741 9 6.25Z"
                                    fill="#0EA5E9"
                                  />
                                  <path
                                    d="M9 18C7.21997 18 5.47991 17.4722 3.99987 16.4832C2.51983 15.4943 1.36628 14.0887 0.685088 12.4442C0.00389951 10.7996 -0.17433 8.99002 0.172937 7.24419C0.520204 5.49836 1.37737 3.89472 2.63604 2.63604C3.89472 1.37737 5.49836 0.520204 7.24419 0.172937C8.99002 -0.17433 10.7996 0.00389951 12.4442 0.685088C14.0887 1.36628 15.4943 2.51983 16.4832 3.99987C17.4722 5.47991 18 7.21997 18 9C18 11.387 17.0518 13.6761 15.364 15.364C13.6761 17.0518 11.387 18 9 18V18ZM9 1.5C7.51664 1.5 6.0666 1.93987 4.83323 2.76398C3.59986 3.58809 2.63856 4.75943 2.07091 6.12988C1.50325 7.50032 1.35473 9.00832 1.64411 10.4632C1.9335 11.918 2.64781 13.2544 3.6967 14.3033C4.7456 15.3522 6.08197 16.0665 7.53683 16.3559C8.99168 16.6453 10.4997 16.4968 11.8701 15.9291C13.2406 15.3614 14.4119 14.4001 15.236 13.1668C16.0601 11.9334 16.5 10.4834 16.5 9C16.5 7.01088 15.7098 5.10323 14.3033 3.6967C12.8968 2.29018 10.9891 1.5 9 1.5Z"
                                    fill="#0EA5E9"
                                  />
                                </svg>
                              </span>
                              <span className="ps-1 pt-1">
                                Capable of viewing quizzes.
                              </span>
                            </div>
                          )}
                        </div>
                        {touched.roleId && errors.roleId ? (
                          <ErrorMessage>{errors.roleId}</ErrorMessage>
                        ) : null}

                        {/* { selectedAdminRole==null && signUpError && (
                                                        <ErrorMessage>{signUpError}</ErrorMessage>
                                                    )}  */}
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="col-12">
                      <div
                        className={`d-block d-sm-flex w-100 ${styles.userdetail_names}`}
                      >
                        <div className={`${styles.title}`}>
                          <div className="mb-2 mt-3">
                            <label htmlFor="salutation">
                              <span>Title</span>
                            </label>
                          </div>
                          <Dropdown
                            id="salutation"
                            value={selectedTitle}
                            onChange={handleTitleChange}
                            options={title}
                            optionLabel="name"
                            placeholder="Select"
                            className={`${styles.signUpDropdown}`}
                          />
                        </div>
                        <div className="d-flex w-100 justify-content-between ms-sm-3">
                          <div className={`${styles.names}`}>
                            <div className="mb-2 mt-3">
                              <label htmlFor="firstName">
                                <span>First Name</span>
                                <span className={`ms-1 ${styles.asterisk}`}>
                                  *
                                </span>
                              </label>
                            </div>
                            <Field
                              id="firstName"
                              name="firstName"
                              className="w-100 p-2 mb-1"
                              style={{
                                border:
                                  touched.firstName && errors.firstName
                                    ? "1px solid #EF4444"
                                    : "1px solid #cbd5e1",
                              }}
                              value={values.firstName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              // maxLength={20}
                              autoComplete="off"
                            />
                            {touched.firstName && errors.firstName ? (
                              <ErrorMessage>{errors.firstName}</ErrorMessage>
                            ) : null}
                          </div>

                          <div className={`${styles.names}`}>
                            <div className="mb-2 mt-3">
                              <label htmlFor="lastName">
                                <span>Last Name</span>
                                <span className={`ms-1 ${styles.asterisk}`}>
                                  *
                                </span>
                              </label>
                            </div>
                            <Field
                              id="lastName"
                              name="lastName"
                              className="w-100 p-2 mb-1"
                              style={{
                                border:
                                  touched.lastName && errors.lastName
                                    ? "1px solid #EF4444"
                                    : "1px solid #cbd5e1",
                              }}
                              value={values.lastName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              // maxLength={50}
                              autoComplete="off"
                            />
                            {touched.lastName && errors.lastName ? (
                              <ErrorMessage>{errors.lastName}</ErrorMessage>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="mb-2 mt-3">
                        <label htmlFor="userName">
                          <span>Username</span>
                          <span className={`ms-1 ${styles.asterisk}`}>*</span>
                        </label>
                      </div>
                      <Field
                        id="userName"
                        name="userName"
                        className="w-100 p-2 mb-1"
                        style={{
                          border:
                            touched.userName && errors.userName
                              ? "1px solid #EF4444"
                              : "1px solid #cbd5e1",
                        }}
                        placeholder="Only letters or numbers with a maximum limit of 12 please."
                        value={values.userName}
                        onChange={(e)=>{handleChange(e);setSignUpError("");}}
                        onBlur={handleBlur}
                        maxLength={12}
                        autoComplete="on"
                      />
                      {touched.userName && errors.userName ? (
                        <ErrorMessage>{errors.userName}</ErrorMessage>
                      ) : null}
                    </div>
                    <div className="col-12">
                      <div className="mb-2 mt-3">
                        <label htmlFor="email">
                          <span>Email</span>
                          <span className={`ms-1 ${styles.asterisk}`}>*</span>
                        </label>
                      </div>
                      {!superAdminLogin ? (
                        <div className="row">
                          <div className="col-9 col-xl-10 pe-0">
                            <Field
                              id="email"
                              name="email"
                              className="w-100 p-2 mb-1"
                              style={{
                                border:
                                  touched.email && errors.email
                                    ? "1px solid #EF4444"
                                    : "1px solid #cbd5e1",
                              }}
                              placeholder="John@email.com"
                              autoComplete="on"
                              value={values.email}
                              onChange={(e) => {
                                handleChange(e);
                                setVerificationError(null);
                                setSignUpError("");
                              }}
                              onBlur={handleBlur}
                              disabled={emailVerified}
                            />
                            {touched.email && errors.email ? (
                              <ErrorMessage>{errors.email}</ErrorMessage>
                            ) : null}
                            <div>
                              {emailVerified && !errors.email && (
                                <div className="d-flex align-items-center">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      d="M12 2.25C6.62391 2.25 2.25 6.62391 2.25 12C2.25 17.3761 6.62391 21.75 12 21.75C17.3761 21.75 21.75 17.3761 21.75 12C21.75 6.62391 17.3761 2.25 12 2.25ZM17.0742 8.73234L10.7742 16.2323C10.7051 16.3147 10.6191 16.3812 10.5221 16.4273C10.425 16.4735 10.3192 16.4983 10.2117 16.5H10.1991C10.0939 16.5 9.99 16.4778 9.89398 16.435C9.79797 16.3922 9.71202 16.3297 9.64172 16.2516L6.94172 13.2516C6.87315 13.1788 6.81981 13.0931 6.78483 12.9995C6.74986 12.9059 6.73395 12.8062 6.73805 12.7063C6.74215 12.6064 6.76617 12.5084 6.8087 12.4179C6.85124 12.3275 6.91142 12.2464 6.98572 12.1796C7.06002 12.1127 7.14694 12.0614 7.24136 12.0286C7.33579 11.9958 7.43581 11.9822 7.53556 11.9886C7.63531 11.995 7.73277 12.0213 7.82222 12.0659C7.91166 12.1106 7.99128 12.1726 8.05641 12.2484L10.1794 14.6072L15.9258 7.76766C16.0547 7.61863 16.237 7.52631 16.4335 7.51066C16.6299 7.49501 16.8246 7.55728 16.9754 7.68402C17.1263 7.81075 17.2212 7.99176 17.2397 8.18793C17.2582 8.3841 17.1988 8.57966 17.0742 8.73234Z"
                                      fill="#22C55E"
                                    />
                                  </svg>
                                  <span
                                    className={`ps-2 ${styles.success_message}`}
                                  >
                                    {emailVerifiedMessage}
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>

                          <div
                            className={`col-3 col-xl-2 ${styles.signup_verify}`}
                          >
                            <Button
                              type="button"
                              onClick={() => {
                                if (!errors.email) {
                                  handleVerify(values.email);
                                } else {
                                  setVerificationError(null);
                                }
                              }}
                              className="w-100 d-flex justify-content-center align-items-center"
                              disabled={!values.email || emailVerified}
                            >
                              {otpLoading ? (
                                <span
                                  className={`${styles.loader_spinner} py-1`}
                                ></span>
                              ) : (
                                <span>Verify</span>
                              )}
                            </Button>
                          </div>
                          {verificationError && !errors.email && (
                            <ErrorMessage>{verificationError}</ErrorMessage>
                          )}
                          <SignUpDialog
                            visible={visible}
                            onHide={() => {
                              if (!visible) return;
                              setVisible(false);
                              if (!otpVerified) setEmailVerified(false);
                            }}
                            email={values.email}
                            onOTPVerified={handleOTPVerified}
                          />
                        </div>
                      ) : (
                        <div className="col-12">
                          <Field
                            id="email"
                            name="email"
                            className="w-100 p-2 mb-1"
                            style={{
                              border:
                                touched.email && errors.email
                                  ? "1px solid #EF4444"
                                  : "1px solid #cbd5e1",
                            }}
                            placeholder="John@email.com"
                            autoComplete="on"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.email && errors.email ? (
                            <ErrorMessage>{errors.email}</ErrorMessage>
                          ) : null}
                        </div>
                      )}
                    </div>
                    <div className="col-12">
                      <div className="mb-2 mt-3">
                        <label htmlFor="password">
                          <span>Create Your Password</span>
                          <span className={`ms-1 ${styles.asterisk}`}>*</span>
                        </label>
                      </div>
                      <div className={styles.password_field_wrapper}>
                      <div
                        className={`position-relative ${styles.password_visibility}`}
                      >
                        <Field
                          type={showPassword ? "text" : "password"}
                          id="password"
                          name="password"
                          className="w-100 p-2 mb-1"
                          autoComplete="off"
                          style={{
                            border:
                              touched.password && errors.password
                                ? "1px solid #EF4444"
                                : "1px solid #cbd5e1",
                          }}
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <i
                          className={`pi ${
                            showPassword ? "pi-eye" : "pi-eye-slash"
                          }`}
                          onClick={togglePasswordVisibility}
                        ></i>
                      </div>
                      </div>

                      {touched.password && errors.password ? (
                        <ErrorMessage>{errors.password}</ErrorMessage>
                      ) : null}
                      <div className={`${styles.password_rule} mt-3`}>
                        <p>Your password must contain at least:</p>
                        <ul>
                          <li>1 Letter</li>
                          <li>1 Number</li>
                          <li>6 Character Length (Minimum)</li>
                        </ul>
                      </div>
                    </div>
                    {signUpError && <ErrorMessage>{signUpError}</ErrorMessage>}
                    <div className={`col-12 ${styles.signup_button}`}>
                      <Button
                        type="submit"
                        className="w-100 d-flex justify-content-center"
                        disabled={buttonDisabled}
                      >
                        {loading ? (
                          <span
                            className={`${styles.loader_spinner} p-1`}
                          ></span>
                        ) : (
                          <span>{!superAdminLogin ? "Sign Up" : "Submit"}</span>
                        )}
                      </Button>
                      {!superAdminLogin ? (
                        <div className={`mt-3 ${styles.login_link}`}>
                          <p>
                            Registered User?{" "}
                            <NavLink to="/Login" className="ps-1">
                              Login
                            </NavLink>
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
