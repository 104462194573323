import { api } from "../api/api";
import { appInsights } from "../AppInsights";
const loginApi = async(credentials)=>{
    try{
        const response = await api.post(`/api/User/Login`, credentials);
        return response?.data?.result || [];
    } catch(error){
        appInsights.trackException("UI",{ exception: error });
        throw new Error(error?.response?.data?.title);
    }
}
export default loginApi