import React, { useEffect, useState } from "react";
import styles from "./about.module.scss";
import Img1 from "../../../assets/images/about1.png";
import Img2 from "../../../assets/images/about2.png";
import Img3 from "../../../assets/images/about3.png";
import { useNavigate } from "react-router-dom";
import { getQuizCount } from "../../../services/aboutUs";
import { Skeleton } from "primereact/skeleton";
import { appInsights } from "../../../AppInsights";
const About = () => {
  appInsights.trackPageView("UI",{name: 'About.Jsx component visited' });
  const navigate = useNavigate();
  const[showSkeleton,setShowSkeleton]=useState(false);
  const [quizCount, setQuizCount] = useState({
    topicsCount: 0,
    quizzesCount: 0,
    difficultyLevels: [],
  });
  const goToAbout = () => {
    navigate("/aboutUs");
  };
  useEffect(() => {
    const fetchQuizCount = async () => {
      try{
        setShowSkeleton(true);
        const response = await getQuizCount();
        setQuizCount({
          topicsCount: response?.topicsCount,
          quizzesCount: response?.quizzesCount,
          difficultyLevels: response?.difficultyLevels,
        });
      }catch(error){
        console.log(error?.message);
        setShowSkeleton(false);
      } finally{
        setShowSkeleton(false);
      }
    };
    fetchQuizCount();
  }, []);
  return (
    <div className={`${styles.about_content} pt-4`}>
      <section className="container-fluid">
        <div className="mt-3 pt-1 px-lg-3 mx-md-5 pb-3">
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-6 order-xs-1  order-lg-2">
              <div
                className={`ps-lg-5 ${styles.about_section_info} pt-lg-5 mt-lg-4`}
              >
                <h5
                  className={`${styles.about_section_info_header} font_text bold`}
                >
                  ABOUT US
                </h5>
                <p className={`my-4 ${styles.about_line}`}>
                  Organizations spend billions training and upskilling their
                  staff across the world. However, little knowledge is retained
                  and available when needed. We will help you change that.
                </p>
                <div className={`row ${styles.about_paragraph}`}>
                  <div className="col-12 col-md-3">
                    <p className="mb-1">Total Topics</p>
                    <h5 className={`${styles.total_topic} p-0 m-0`}>
                      {showSkeleton? <Skeleton width="3rem" height="2rem"/> :`${Math.floor(quizCount?.topicsCount / 10) * 10}+`}
                    </h5>
                  </div>
                  <div className="col-12 col-md-3 ps-lg-0">
                    <p className="mb-1 mt-2 mt-md-0">Total Quizzes</p>
                    <h5 className={`${styles.total_quiz} p-0 m-0`}>
                    {showSkeleton? <Skeleton width="3rem" height="2rem"/> :`${Math.floor(quizCount?.quizzesCount / 10) * 10}+`} 
                    </h5>
                  </div>
                  <div className="col-12 col-md-6">
                    <p className="mb-1 mt-2 mt-md-0">Quiz Levels</p>
                    {showSkeleton ? <Skeleton width="8rem" height="2rem"/>:(
                      <>
                      {quizCount?.difficultyLevels?.map((level, index) => (
                      <span
                        key={index}
                        className={`${styles.total_type} p-0 m-0`}
                      >
                        {level?.name}
                        {index < quizCount?.difficultyLevels?.length - 1 && ", "}
                      </span>
                    ))}
                      </>
                    )}
                    
                  </div>
                </div>
                <div className={`my-5 ${styles.about_btn}`}>
                  <button className="btn" onClick={goToAbout}>
                    <span className="me-2">More About Us</span>
                    <i className="pi pi-arrow-right ps-1"></i>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 col-xl-6 order-xs-2  order-lg-1">
              <div className="row">
                <div className="col-4 pe-0">
                  <div className="mt-4 pt-5">
                    <img src={Img1} className="img-fluid" alt="no img" />
                  </div>
                </div>
                <div className="col-4 pe-0">
                  <div>
                    <img src={Img2} className="img-fluid" alt="no img" />
                  </div>
                </div>
                <div className="col-4">
                  <div className="mt-4 pt-2">
                    <img src={Img3} className="img-fluid" alt="no img" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
