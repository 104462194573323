import React, { useEffect, useState, useRef } from "react";
import CardComponent from "../../components/Card/CardComponent";
import TagComponent from "../../components/Tag/TagComponent";
import styles from "./topicCard.module.scss";
import { Dropdown } from "primereact/dropdown";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../store/action-creators";
import SubTopicCard from "../topicCards/subTopicCard";
import DialogBox from "../../components/DialogBox/DialogBox";
import { Skeleton } from "primereact/skeleton";
import { useNavigate } from "react-router-dom";
import * as actionTypes from "../../store/actions";
import { Button } from "primereact/button";
import LevelDialogBox from "../../components/LevelDialogBox/LevelDialogBox";
import { appInsights } from "../../AppInsights";
import { Toast } from "primereact/toast";

const BundleCard = (props) => {
  const [topicId, setTopicId] = useState(props?.topicId);
  const toast = useRef(null);
  const navigate = useNavigate();
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [alaCarteItems, setAlaCarteItems] = useState(null);
  const [courseSubscriptionPrice, setCourseSubscriptionPrice] = useState(0);
  const [visible, setVisible] = useState(false);
  const [selectedQuizId, setSelectedQuizId] = useState(null);
  const [bundle, setBundle] = useState(null);
  const dispatch = useDispatch();
  const { getTopicDetailsAfterLogin } = bindActionCreators(
    actionCreators,
    dispatch
  );
  const { getTopicDetails } = bindActionCreators(actionCreators, dispatch);
  const topicDetails = useSelector((state) => {
    return state.domainReducer.topicDetails;
  });

  const topicQuizData = topicDetails?.find((item) => item?.topicId == topicId);
  const selectedQuiz = topicQuizData?.topicQuizList?.find(
    (quiz) => quiz?.quizId === selectedQuizId
  );
  const topicName = selectedQuiz?.topicName;
  const loggedInUser = JSON.parse(localStorage.getItem("userData"));
  const price = [
    { name: "One Month", code: 1 },
    { name: "Three Months", code: 2 },
    { name: "One Year", code: 3 },
  ];
  useEffect(() => {
    if (props.topicId !== topicId) {
      setTopicId(props.topicId);
    }
  }, [props.topicId]);

  useEffect(() => {
    if (topicQuizData === undefined) {
      fetchTopicDetails();
    }
  }, [topicId]);

  const fetchTopicDetails = async () => {
    const params = {
      topicId: topicId,
    };
    try {
      if (loggedInUser) {
        getTopicDetailsAfterLogin({ params });
      } else {
        getTopicDetails({ params });
      }
    } catch (error) { appInsights.trackException("UI",{ exception: error });
  }
  };

  useEffect(() => {
    if (topicDetails?.length > 0) {
      const filteredTopicDetails = topicDetails?.filter(
        (item) => item?.topicId == topicId
      );
      const alacarteItemsFilteredData =
        filteredTopicDetails[0]?.topicQuizList?.filter(
          (item) => !item?.isBundle && item?.quizTypeId !== 1
        );
      const bundleFilteredData = filteredTopicDetails[0]?.topicQuizList?.filter(
        (item) => item?.isBundle
      );
      setBundle(bundleFilteredData);
      setAlaCarteItems(alacarteItemsFilteredData);
      if (bundleFilteredData != undefined) {
        if (bundleFilteredData[0]?.addedSubscription) {
          const price =
            bundleFilteredData[0]?.addedSubscription?.code == 1
              ? bundleFilteredData[0]?.quizPricePerMonth
              : bundleFilteredData[0]?.addedSubscription?.code == 2
              ? bundleFilteredData[0]?.quizPricePerQuarter
              : bundleFilteredData[0]?.quizPricePerAnum;
          setCourseSubscriptionPrice(price);
          setSelectedPrice(bundleFilteredData[0]?.addedSubscription);
        } else {
          setCourseSubscriptionPrice(bundleFilteredData[0]?.quizPricePerMonth);
          setSelectedPrice({ name: "One Month", code: 1 });
        }
      }
    }
  }, [topicDetails, topicQuizData]);

  //Array for modal data
  const quizLevel = [
    {
      QuizLevel: "Introduction / Basics",
      QuizDesc: "Focused on terminologies / basic terms / definitions",
    },
    {
      QuizLevel: "Intermediate",
      QuizDesc:
        "Tests knowledge / understanding of various sections / clauses of standard",
    },
    {
      QuizLevel: "Advanced",
      QuizDesc:
        "Evaluate detailed understanding of sections / clauses, sub clauses and interrelationship between clauses",
    },
    {
      QuizLevel: "Professional / Practitioner",
      QuizDesc:
        "Tests the ability to apply the requirements of the standard / regulation to real life scenarios, and identification of conformance / compliance as applicable",
    },
  ];
  const handleSubscription = (e) => {
    setSelectedPrice(e?.target?.value);
    const price =
      e?.target?.value?.code == 1
        ? bundle[0]?.quizPricePerMonth
        : e?.target?.value?.code == 2
        ? bundle[0]?.quizPricePerQuarter
        : bundle[0]?.quizPricePerAnum;
    setCourseSubscriptionPrice(price);
    // bundle[0]?.selectedSubscription = 3;
    const newTopicDetails = topicDetails.map((item, index) => {
      if (item?.topicId == topicId) {
        const updatedTopicQuizList = item?.topicQuizList.map((subTopic) => {
          if (subTopic) {
            if(subTopic?.isBundle && subTopic?.addToCart){
              toast.current?.show({
                severity: "warn",
                summary: "Delete the item from Cart and add again.",
                life: 3000,
              });
              return subTopic;
            }
            return { ...subTopic, addedSubscription: (!subTopic?.isBundle && subTopic?.addToCart) ? subTopic?.addedSubscription:e?.target?.value };
          }
          return subTopic;
        });
        return { ...item, topicQuizList: updatedTopicQuizList };
      }
      return item;
    });

    dispatch({
      type: actionTypes.GET_TOPICDETAILS,
      value: newTopicDetails || [],
    });
    localStorage.setItem("updatedTopics", JSON.stringify(newTopicDetails));
  };
  const handleSubTopicSubscription = (e, id) => {
    const newTopicDetails = topicDetails.map((item, index) => {
      if (item?.topicId == topicId) {
        const updatedTopicQuizList = item?.topicQuizList.map((subTopic) => {
          if (subTopic?.isBundle == false && subTopic?.quizId == id) {
            if(subTopic?.addToCart){
              toast.current?.show({
                severity: "warn",
                summary: "Delete the item from Cart and add again.",
                life: 3000,
              });
              return subTopic;
            }
            return { ...subTopic, addedSubscription: e?.target?.value };
          }
          return subTopic;
        });
        return { ...item, topicQuizList: updatedTopicQuizList };
      }
      return item;
    });

    dispatch({
      type: actionTypes.GET_TOPICDETAILS,
      value: newTopicDetails || [],
    });
    localStorage.setItem("updatedTopics", JSON.stringify(newTopicDetails));
  };
  const handleCart = () => {
    const newTopicDetails = topicDetails.map((item, index) => {
      if (item?.topicId == topicId) {
        const updatedTopicQuizList = item?.topicQuizList.map((subTopic) => {
          if (subTopic) {
            return {
              ...subTopic,
              addToCart: true,
              addedToCartLabel: "Added to Cart",
              addedSubscription: bundle[0]?.addedSubscription
            };
          }
          return subTopic;
        });
        return { ...item, topicQuizList: updatedTopicQuizList };
      }
      return item;
    });
    dispatch({
      type: actionTypes.GET_TOPICDETAILS,
      value: newTopicDetails || [],
    });
    localStorage.setItem("updatedTopics", JSON.stringify(newTopicDetails));
    localStorage.setItem("ItemsAddedToCart", JSON.stringify(newTopicDetails));
  };
  const handleSubTopicCart = (subTopiId) => {
    const newTopicDetails = topicDetails.map((item, index) => {
      if (item?.topicId == topicId) {
        const updatedTopicQuizList = item?.topicQuizList.map((subTopic) => {
          if (subTopic?.quizId == subTopiId) {
            return {
              ...subTopic,
              addToCart: true,
              addedSubscription: subTopic?.selectedPlan,
            };
          }
          return subTopic;
        });
        return { ...item, topicQuizList: updatedTopicQuizList };
      }
      return item;
    });
    dispatch({
      type: actionTypes.GET_TOPICDETAILS,
      value: newTopicDetails || [],
    });
    localStorage.setItem("updatedTopics", JSON.stringify(newTopicDetails));
    localStorage.setItem("ItemsAddedToCart", JSON.stringify(newTopicDetails));
  };

  const toggleReadMoreContent = (subTopiId) => {
    const newTopicDetails = topicDetails.map((item, index) => {
      if (item?.topicId == topicId) {
        const updatedTopicQuizList = item?.topicQuizList.map((subTopic) => {
          if (subTopic?.quizId == subTopiId) {
            return {
              ...subTopic,
              isExpanded: subTopic?.isExpanded === true ? false : true,
            };
          }
          return subTopic;
        });
        return { ...item, topicQuizList: updatedTopicQuizList };
      }
      return item;
    });
    dispatch({
      type: actionTypes.GET_TOPICDETAILS,
      value: newTopicDetails || [],
    });
    localStorage.setItem("updatedTopics", JSON.stringify(newTopicDetails));
    // const data = topicDetails?.map((item)=> item)
  };

  const navigateToCart = () => {
    navigate("/cart");
  };

  const navigateToQuiz = (quizId) => {
    setSelectedQuizId(quizId);
    localStorage.setItem('selectedTopicId', topicId);
    setVisible(true);
  };
  const navigateToFreeQuiz = () => {
    const freeQuiz = topicDetails
    .flatMap((topic) => topic?.topicQuizList?.map((quiz) => ({ ...quiz, topicId: topic?.topicId })))
    .find((quiz) => quiz?.quizName === "Free Quiz");
    if (freeQuiz) {
      localStorage.setItem('selectedTopicId', freeQuiz.topicId);
      navigate(`/Quiz/${freeQuiz.quizId}`);
    }
  };
  const formatDate = (dateString) => {
    if (!dateString) return null;
    const date = new Date(dateString);
    const year = date.getFullYear();
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[date.getMonth()];
    const day = String(date.getDate()).padStart(2, "0");
    return `${day}-${month}-${year}`;
  };
  const formattedBundleDate = bundle?.[0]?.expiryDate
    ? formatDate(bundle[0].expiryDate)
    : "";

  return (
    <div className="mt-md-3">
         <Toast ref={toast} />
      {bundle?.length > 0 ?  bundle[0]?.hasQuestions === false ? (
      <div>
        <h4 className={styles.comingSoonName}>{(() => {
            const quizName = bundle[0]?.quizName || "";
            const indexOfParen = quizName.indexOf("(");
            return indexOfParen !== -1
              ? quizName.substring(0, indexOfParen).trim()
              : quizName.trim();
          })()}</h4>
        <div className="text-center">
          <h4 className={`font_text regular py-2 ${styles.comingSoonHeading}`}>Coming Soon!</h4>
          <p className={styles.cominSoonDesc}>Until then, explore our other exciting quizzes.</p>
        </div>
      </div>
    ) : (
        <>
          <div className={`row ${styles.bundle_title}`}>
            <div className="col-12 col-md-9">
              <h4>
                {(() => {
                  const quizName = bundle[0]?.quizName || "";
                  const indexOfParen = quizName.indexOf("(");
                  const textBeforeParen =
                    indexOfParen !== -1
                      ? quizName.substring(0, indexOfParen)
                      : quizName;
                  const textAfterParen =
                    indexOfParen !== -1 ? quizName.substring(indexOfParen) : "";

                  return (
                    <>
                      {textBeforeParen}
                      {textAfterParen && (
                        <>
                          <br />
                          {textAfterParen}
                        </>
                      )}
                    </>
                  );
                })()}
              </h4>
            </div>
            <div
              className={`col-12 col-md-3 free_trial_domain text-md-end mb-3 mb-md-0`}
            >
              <button
                className="btn px-3 bg-white"
                onClick={navigateToFreeQuiz}
              >
                Try out free quiz
              </button>
            </div>
          </div>
          <div className={`row ${styles.bundle_title}`}>
            <div className="col-12">
              <p>{bundle[0]?.quizDescription}</p>
            </div>
          </div>
          <div
            className={`${styles.bundle_heading} text-white text-center mb-2`}
          >
            <h4 className="font_text medium mb-0">
              Buy once and get unlimited use for life of subscription
            </h4>
          </div>
          <div className={`${styles.bundle_btn}`}>
            <button
              className="btn p-2 text-white ms-3"
              style={{ cursor: "text" }}
            >
              Buy the bundle and save
            </button>
          </div>
          <CardComponent>
            <div>
              <div
                className={`d-lg-flex align-items-center justify-content-between ${styles.tag_component} mb-3`}
              >
                <div className="d-lg-flex align-items-center">
                  <TagComponent
                    content={`${bundle[0]?.numberOfQuestions} Questions/Quiz`}
                    className={`${styles.question_no} d-flex justify-content-center align-items-center me-2 px-1 mt-2`}
                  />
                  <TagComponent
                    icon={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        style={{ paddingRight: "2px" }}
                      >
                        <path d="M6 1.33301H10Z" fill="#22C55E" />
                        <path
                          d="M6 1.33301H10"
                          stroke="#166534"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M8 6.66699V9.33366"
                          stroke="#166534"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M8.00008 14.6667C10.9456 14.6667 13.3334 12.2789 13.3334 9.33333C13.3334 6.38781 10.9456 4 8.00008 4C5.05456 4 2.66675 6.38781 2.66675 9.33333C2.66675 12.2789 5.05456 14.6667 8.00008 14.6667Z"
                          stroke="#166534"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    }
                    content={`${bundle[0]?.quizTimeLimit} Min`}
                    className={`${styles.time_limit} d-flex justify-content-center align-items-center px-2  mt-2`}
                  />
                </div>
                <div>
                  <TagComponent
                    content={`${bundle[0]?.totalQuestionsinPool}`}
                    className={`${styles.pool_questions} d-flex justify-content-center align-items-center me-2 px-1  mt-2 `}
                  />
                </div>
              </div>
              <div>
                <p
                  className={`${styles.topicbundle_header} font_text medium mb-1`}
                >
                  {(() => {
                    const quizName = bundle[0]?.quizName || "";
                    const indexOfParen = quizName.indexOf("(");
                    const textBeforeParen =
                      indexOfParen !== -1
                        ? quizName.substring(0, indexOfParen)
                        : quizName;
                    const textAfterParen =
                      indexOfParen !== -1
                        ? quizName.substring(indexOfParen)
                        : "";

                    return (
                      <>
                        {textBeforeParen}
                        {textAfterParen && (
                          <>
                            <br />
                            {textAfterParen}
                          </>
                        )}
                      </>
                    );
                  })()}
                </p>
              </div>
            </div>
            <p className={`${styles.topicbundle_description}`}>
              {bundle[0]?.quizDescription}
            </p>
            <div className="d-md-flex justify-content-between mt-4">
              {bundle[0]?.isPurchased === false ? (
                <>
                  <div>
                    <p
                      className={`text-dark fw-small m-0 ${styles.subscriptionLabel}`}
                    >
                      Choose Subscription
                    </p>
                    <div className="d-flex align-items-center">
                      <Dropdown
                        value={selectedPrice}
                        onChange={(e) => handleSubscription(e)}
                        options={price}
                        optionLabel="name"
                        placeholder="select"
                        className={`${styles.customDropdown}`}
                      />
                      <div
                        className={`${styles.dollar_svg} h-100 d-flex align-items-center`}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <g clipPath="url(#clip0_2056_97855)">
                            <path
                              d="M10 0.833008V19.1663"
                              stroke="#0F172A"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M14.1667 4.16699H7.91667C7.14312 4.16699 6.40125 4.47428 5.85427 5.02126C5.30729 5.56825 5 6.31011 5 7.08366C5 7.85721 5.30729 8.59907 5.85427 9.14605C6.40125 9.69303 7.14312 10.0003 7.91667 10.0003H12.0833C12.8569 10.0003 13.5987 10.3076 14.1457 10.8546C14.6927 11.4016 15 12.1434 15 12.917C15 13.6905 14.6927 14.4324 14.1457 14.9794C13.5987 15.5264 12.8569 15.8337 12.0833 15.8337H5"
                              stroke="#0F172A"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_2056_97855">
                              <rect width="20" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                      <div
                        className={`${styles.topic_price_amount} h-100 d-flex align-items-center `}
                      >
                        <h4
                          className={`${styles.topic_price} m-0 font_text semibold`}
                        >
                          {courseSubscriptionPrice}
                        </h4>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div>
                  <p>
                    <strong>Expiry date: {formattedBundleDate}</strong>
                  </p>
                </div>
              )}

              <div
                className={`${styles.domain_btn} pt-3 pt-md-0 d-flex align-items-end `}
              >
                {bundle[0]?.addToCart === true && !bundle[0]?.isPurchased && (
                  <button
                    className={`${styles?.primaryBtn} text-white primaryBtn d-flex align-items-center px-3 py-2 mt-sm-1 mt-md-0 `}
                    onClick={navigateToCart}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="15"
                      viewBox="0 0 16 15"
                      fill="none"
                    >
                      <path
                        d="M6.125 14.292C5.87777 14.292 5.6361 14.2187 5.43054 14.0813C5.22498 13.944 5.06476 13.7488 4.97015 13.5203C4.87554 13.2919 4.85079 13.0406 4.89902 12.7981C4.94725 12.5557 5.0663 12.3329 5.24112 12.1581C5.41593 11.9833 5.63866 11.8642 5.88114 11.816C6.12361 11.7678 6.37495 11.7925 6.60336 11.8871C6.83176 11.9818 7.02699 12.142 7.16434 12.3475C7.30169 12.5531 7.375 12.7948 7.375 13.042C7.375 13.3735 7.2433 13.6915 7.00888 13.9259C6.77446 14.1603 6.45652 14.292 6.125 14.292Z"
                        fill="white"
                      />

                      <path
                        d="M11.5415 14.292C11.2943 14.292 11.0526 14.2187 10.847 14.0813C10.6415 13.944 10.4813 13.7488 10.3867 13.5203C10.292 13.2919 10.2673 13.0406 10.3155 12.7981C10.3638 12.5557 10.4828 12.3329 10.6576 12.1581C10.8324 11.9833 11.0552 11.8642 11.2976 11.816C11.5401 11.7678 11.7915 11.7925 12.0199 11.8871C12.2483 11.9818 12.4435 12.142 12.5808 12.3475C12.7182 12.5531 12.7915 12.7948 12.7915 13.042C12.7915 13.3735 12.6598 13.6915 12.4254 13.9259C12.191 14.1603 11.873 14.292 11.5415 14.292Z"
                        fill="white"
                      />

                      <path
                        d="M12.9999 10.9587H4.66659C4.51986 10.9573 4.37815 10.905 4.26568 10.8108C4.15321 10.7166 4.07694 10.5862 4.04992 10.442L2.47492 1.79199H1.33325C1.16749 1.79199 1.00852 1.72614 0.89131 1.60893C0.7741 1.49172 0.708252 1.33275 0.708252 1.16699C0.708252 1.00123 0.7741 0.842261 0.89131 0.72505C1.00852 0.60784 1.16749 0.541992 1.33325 0.541992H2.99992C3.14665 0.543344 3.28835 0.595601 3.40082 0.689836C3.5133 0.78407 3.58956 0.914436 3.61659 1.05866L3.97492 3.04199H14.6666C14.7615 3.04229 14.8551 3.06421 14.9403 3.10608C15.0255 3.14796 15.1 3.20868 15.1583 3.28366C15.2157 3.35851 15.256 3.44511 15.2761 3.5373C15.2963 3.62948 15.2959 3.72498 15.2749 3.81699L13.6083 10.4837C13.5734 10.6188 13.4948 10.7386 13.3848 10.8245C13.2748 10.9104 13.1395 10.9576 12.9999 10.9587V10.9587ZM5.19159 9.70866H12.5083L13.8333 4.29199H4.19992L5.19159 9.70866Z"
                        fill="white"
                      />
                    </svg>
                    Go to Cart
                  </button>
                )}
                {!bundle[0]?.isPurchased && !bundle[0]?.addToCart && (
                  <button
                    className={`d-flex align-items-center px-3 py-2 text-white ${styles.addcart_btn} mt-sm-1 mt-md-0`}
                    onClick={handleCart}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="15"
                      viewBox="0 0 16 15"
                      fill="none"
                    >
                      <path
                        d="M6.125 14.292C5.87777 14.292 5.6361 14.2187 5.43054 14.0813C5.22498 13.944 5.06476 13.7488 4.97015 13.5203C4.87554 13.2919 4.85079 13.0406 4.89902 12.7981C4.94725 12.5557 5.0663 12.3329 5.24112 12.1581C5.41593 11.9833 5.63866 11.8642 5.88114 11.816C6.12361 11.7678 6.37495 11.7925 6.60336 11.8871C6.83176 11.9818 7.02699 12.142 7.16434 12.3475C7.30169 12.5531 7.375 12.7948 7.375 13.042C7.375 13.3735 7.2433 13.6915 7.00888 13.9259C6.77446 14.1603 6.45652 14.292 6.125 14.292Z"
                        fill="white"
                      />

                      <path
                        d="M11.5415 14.292C11.2943 14.292 11.0526 14.2187 10.847 14.0813C10.6415 13.944 10.4813 13.7488 10.3867 13.5203C10.292 13.2919 10.2673 13.0406 10.3155 12.7981C10.3638 12.5557 10.4828 12.3329 10.6576 12.1581C10.8324 11.9833 11.0552 11.8642 11.2976 11.816C11.5401 11.7678 11.7915 11.7925 12.0199 11.8871C12.2483 11.9818 12.4435 12.142 12.5808 12.3475C12.7182 12.5531 12.7915 12.7948 12.7915 13.042C12.7915 13.3735 12.6598 13.6915 12.4254 13.9259C12.191 14.1603 11.873 14.292 11.5415 14.292Z"
                        fill="white"
                      />

                      <path
                        d="M12.9999 10.9587H4.66659C4.51986 10.9573 4.37815 10.905 4.26568 10.8108C4.15321 10.7166 4.07694 10.5862 4.04992 10.442L2.47492 1.79199H1.33325C1.16749 1.79199 1.00852 1.72614 0.89131 1.60893C0.7741 1.49172 0.708252 1.33275 0.708252 1.16699C0.708252 1.00123 0.7741 0.842261 0.89131 0.72505C1.00852 0.60784 1.16749 0.541992 1.33325 0.541992H2.99992C3.14665 0.543344 3.28835 0.595601 3.40082 0.689836C3.5133 0.78407 3.58956 0.914436 3.61659 1.05866L3.97492 3.04199H14.6666C14.7615 3.04229 14.8551 3.06421 14.9403 3.10608C15.0255 3.14796 15.1 3.20868 15.1583 3.28366C15.2157 3.35851 15.256 3.44511 15.2761 3.5373C15.2963 3.62948 15.2959 3.72498 15.2749 3.81699L13.6083 10.4837C13.5734 10.6188 13.4948 10.7386 13.3848 10.8245C13.2748 10.9104 13.1395 10.9576 12.9999 10.9587V10.9587ZM5.19159 9.70866H12.5083L13.8333 4.29199H4.19992L5.19159 9.70866Z"
                        fill="white"
                      />
                    </svg>
                    Add to Cart
                  </button>
                )}
                {bundle[0]?.isPurchased && !bundle[0]?.addToCart && (
                  <button
                    className={` d-flex align-items-center px-3 py-2 text-dark ${styles.takeQuiz} mt-sm-1 mt-md-0`}
                    onClick={() => navigateToQuiz(bundle[0]?.quizId)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="15"
                      viewBox="0 0 16 15"
                      fill="none"
                    >
                      <path
                        d="M6.125 14.292C5.87777 14.292 5.6361 14.2187 5.43054 14.0813C5.22498 13.944 5.06476 13.7488 4.97015 13.5203C4.87554 13.2919 4.85079 13.0406 4.89902 12.7981C4.94725 12.5557 5.0663 12.3329 5.24112 12.1581C5.41593 11.9833 5.63866 11.8642 5.88114 11.816C6.12361 11.7678 6.37495 11.7925 6.60336 11.8871C6.83176 11.9818 7.02699 12.142 7.16434 12.3475C7.30169 12.5531 7.375 12.7948 7.375 13.042C7.375 13.3735 7.2433 13.6915 7.00888 13.9259C6.77446 14.1603 6.45652 14.292 6.125 14.292Z"
                        fill="black"
                      />

                      <path
                        d="M11.5415 14.292C11.2943 14.292 11.0526 14.2187 10.847 14.0813C10.6415 13.944 10.4813 13.7488 10.3867 13.5203C10.292 13.2919 10.2673 13.0406 10.3155 12.7981C10.3638 12.5557 10.4828 12.3329 10.6576 12.1581C10.8324 11.9833 11.0552 11.8642 11.2976 11.816C11.5401 11.7678 11.7915 11.7925 12.0199 11.8871C12.2483 11.9818 12.4435 12.142 12.5808 12.3475C12.7182 12.5531 12.7915 12.7948 12.7915 13.042C12.7915 13.3735 12.6598 13.6915 12.4254 13.9259C12.191 14.1603 11.873 14.292 11.5415 14.292Z"
                        fill="black"
                      />

                      <path
                        d="M12.9999 10.9587H4.66659C4.51986 10.9573 4.37815 10.905 4.26568 10.8108C4.15321 10.7166 4.07694 10.5862 4.04992 10.442L2.47492 1.79199H1.33325C1.16749 1.79199 1.00852 1.72614 0.89131 1.60893C0.7741 1.49172 0.708252 1.33275 0.708252 1.16699C0.708252 1.00123 0.7741 0.842261 0.89131 0.72505C1.00852 0.60784 1.16749 0.541992 1.33325 0.541992H2.99992C3.14665 0.543344 3.28835 0.595601 3.40082 0.689836C3.5133 0.78407 3.58956 0.914436 3.61659 1.05866L3.97492 3.04199H14.6666C14.7615 3.04229 14.8551 3.06421 14.9403 3.10608C15.0255 3.14796 15.1 3.20868 15.1583 3.28366C15.2157 3.35851 15.256 3.44511 15.2761 3.5373C15.2963 3.62948 15.2959 3.72498 15.2749 3.81699L13.6083 10.4837C13.5734 10.6188 13.4948 10.7386 13.3848 10.8245C13.2748 10.9104 13.1395 10.9576 12.9999 10.9587V10.9587ZM5.19159 9.70866H12.5083L13.8333 4.29199H4.19992L5.19159 9.70866Z"
                        fill="black"
                      />
                    </svg>
                    Take Quiz
                  </button>
                )}
              </div>
            </div>
          </CardComponent>
          {alaCarteItems?.length > 0 && (
            <SubTopicCard
              toggleText={toggleReadMoreContent}
              topicDetails={alaCarteItems}
              handleSubscriptionPlan={handleSubTopicSubscription}
              handleSubTopicCart={handleSubTopicCart}
            />
          )}
        </>
      ) : (
        <>
          <Skeleton height={35} className={styles.customSkeleton}></Skeleton>
          <Skeleton
            height={50}
            className={`mt-2 ${styles.customSkeleton}`}
          ></Skeleton>
          <Skeleton
            height={45}
            className={`mt-3 mb-2 ${styles.customSkeleton}`}
          ></Skeleton>
          <Skeleton
            height="15rem"
            className={`mt-4 ${styles.customSkeleton}`}
          ></Skeleton>
        </>
      )}
      <LevelDialogBox
        visible={visible}
        setVisible={setVisible}
        quizId={selectedQuizId}
        topicName={topicName}
      />
    </div>
  );
};

export default BundleCard;
