import React, { useEffect } from "react";
import styles from "./faqs.module.scss";
import CardComponent from "../../components/Card/CardComponent";
import { Accordion, AccordionTab } from "primereact/accordion";
import { appInsights } from "../../AppInsights";
const Faqs = () => {
  appInsights.trackPageView("UI",{name: 'Faqs.Jsx component visited' });
  const FaqEmail = () => {
    return (
      <span>
        <a
          href="mailto:info@quizandteach.com"
          className="text-decoration-none ps-md-1"
          style={{ color: "#3B82F6"}}
        >
          info@quizandteach.com
        </a>
      </span>
    );
  };
  const accordionData = [
    {
      header:
        "1. I notice that you are using a subscription model; can you explain more on that? How many quizzes can I take with my subscription? ",
      content: `The subscription model is used to enable you to train and practice repeatedly over time. You can choose your area / domain of interest and the duration that works for you. Then, practice unlimited times during the life of the subscription.`,
    },
    {
      header:
        "2. Will I get a certificate if I take the quiz? What will it include? What if I fail?",
      content: `Yes – you have the option to get a certificate every time you take a quiz. The certificate will indicate the topic, and your score. There is no such thing as failing; learning is a journey and you continue moving forward. The score just shows your progress along the path. `,
    },
    {
      header: "3. What is the intent behind offering such quizzes?",
      content: (
        <p className="mb-0">
          Our experience shows best learning and retention occurs when a learner
          has focused objectives, and they apply what they know. A quiz
          accomplishes precisely that by framing the question and providing
          answer choices.
          <br /> Keeping the quiz short ensures it fits within the attention
          span of most visitors and delivers bite size learning / knowledge.
        </p>
      ),
    },
    {
      header:
        "4. Why the focus on retention / memory? Why can’t we look up information when needed? ",
      content: `Ability to recall information when needed is an important aspect of learning. Knowledge is power, and when you have it, power resides with you. 
        Having to look things up is not practical nor feasible in many situations. Imagine a pilot trying to fly through rough weather. That is certainly not the best time to open the user’s manual. `,
    },
    {
      header:
        "5. Why break up a topic into subtopics? Why not offer the quiz for the whole topic? ",
      content: (
        <p className="mb-0">
          This has been done to ensure the user can pick and choose an area of
          their interest. For example, those who primarily deal with customer
          complaints need to know the appropriate requirements from the
          standards pertaining to complaints and handling of the same. They do
          not need much detailed information on the design and development
          processes for the product.<br/> NOTE: The subtopic approach is used only
          for certain areas – this is based on input from users and our subject
          matter experts.
        </p>
      ),
    },
    {
      header:
        "6. Why do you offer only a small subset of questions each time a quiz is taken? Why not present the whole question set? ",
      content: `This is done for multiple reasons – (a) to keep the quiz short and quick – thereby affording the opportunity for the user to even benefit from the small windows of time they may have (b) quick learning and feedback on a repeated basis enables you to master the topic over time instead of getting an over dose of information, then losing much of it in a matter of just days (c) you can see your learning improve by tracking your score over time – this builds excitement and confidence.`,
    },
    {
      header:
        "7. But the questions and the answers both seem to be different each time, why so? ",
      content: `This is done on purpose to enable you to look at the same information from different perspectives. Doing so ensures better anchoring of what is learned.`,
    },
    {
      header:
        "8. Are these the only domains? How will I know about new / upcoming quizzes and opportunities for learning and improving?",
      content: `Once you register with us you will be added to our subscriber mailing list and receive periodic updates. We offer over 300 training programs through our partner firms and periodic updates on our offerings, standards, and updates as well. `,
    },
    {
      header: "9. What if I want to buy a blanket license for my company? ",
      content: (
        <p>
          Please contact us at: <FaqEmail />
        </p>
      ),
    },
    {
      header: "10. Do you also design custom quizzes / programs for training?",
      content: (
        <p>
          Yes, please contact us at <FaqEmail /> and provide details together
          with a call back number. We will reach out to you.
        </p>
      ),
    },
    {
      header: "11. What is meant by a topic and what is a sub-topic?",
      content: `A topic is a higher-level body of knowledge (BOK) in each field / discipline / standard / regulation; it is like a parent. For example, ISO 9001, ISO 13485, 21 CFR 820 are all considered as topics for the purposes of these quizzes.  Within these BOKs there are sub sections; each is considered a sub-topic. A sub-topic is like a child. Thus, for ISO 13485, one of the sub-topics is Documents and Records, which is focused on Document Control.`,
    },
    {
      header:
        "12. What is a bundle? When should I buy sub-topics, and when should I buy a bundle?  ",
      content: `A bundle is a collection of quizzes of various sub-topics.  When you buy a quiz for a topic (for example ISO 13485), all quizzes for the sub-topics under that body of knowledge become available to you as a bundle.  In case you need to take quizzes on only a few sub-topics, you can buy them À la carte. For example, Document Control, Management Responsibility, etc.  In such cases, the rest of the sub-topics will not be accessible, nor the whole bundle of ISO 13485.   We did this to ensure you only pay for what you need.`,
    },
    {
      header: "13. What forms of payment do you accept? ",
      content: `We accept payment via Paypal only at this time. `,
    },
    {
      header: "14. Can I get a refund once I am done with the quiz? ",
      content: `Sorry, we do not give any refunds under any circumstances. Please purchase what is necessary, and feel free to try out the Free Trial quizzes before deciding. `,
    },
    {
      header:
        "15. Can I share my e mail and sign in with others so they can take the quizzes as well? ",
      content: `Please do not do this – it will be a violation of the Terms and Conditions for use of our quizzes. Your account may be subject to cancellation if this happens.`,
    },
    {
      header: "16. Can I copy and / or distribute the questions and answers? ",
      content: `Copying, distributing the questions and answers is a violation of the Terms and Conditions for use of our quizzes. Your account may be subject to cancellation if this happens. `,
    },
    {
      header: "17. Who should I contact? Is there a phone number / e-mail? ",
      content: (
        <p className="m-0">
          You can reach us at +1 (720) 257 9572, or by email at: <FaqEmail />
        </p>
      ),
    },
  ];
  return (
    <div className={styles.container}>
      <h5 className={`${styles.faqheading} mb-3`}>FAQS</h5>
      <div className={styles.shadowcontainer}>
        <CardComponent>
          <div className={`${styles.faq_container_block} row`}>
            <div className="col-12">
              <Accordion>
                {accordionData.map((tab, index) => (
                  <AccordionTab key={index} header={tab.header}>
                    <p className="m-0">{tab.content}</p>
                  </AccordionTab>
                ))}
              </Accordion>
            </div>
          </div>
        </CardComponent>
      </div>
    </div>
  );
};

export default Faqs;
