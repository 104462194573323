import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import RequestCertificate from "./RequestCertificate";
const showSubTopicCount = (rowData, styles, setShowDialog, setSubTopicList) => {
  const freeQuiz = rowData?.children?.filter((item) => item?.quizId === 1);
  const count = freeQuiz
    ? rowData?.children?.length - 1
    : rowData?.children?.length;
  return (
    <p style={{ margin: "0px" }}>
      {rowData?.isBundle === true ? (
        <a
          className={styles?.link}
          onClick={() => openDialog(rowData, setShowDialog, setSubTopicList)}
        >
          View ({count})
        </a>
      ) : (
        rowData?.quizName
      )}
    </p>
  );
};

const openDialog = (rowData, setShowDialog, setSubTopicList) => {
  setShowDialog(true);
  const data = rowData?.children.map((item) => ({
    quizName: item?.quizName,
    quizId: item?.quizId,
    quizTypeId: item?.quizTypeId,
  }));
  setSubTopicList(data);
};
const deleteCartItems = (rowData, deleteItem) => {
  return (
    <Button
      icon="pi pi-trash"
      severity="danger"
      aria-label="Delete"
      onClick={() => deleteItem(rowData)}
      style={{ color: "red" }}
    />
  );
};

const showOrderSubTopicCount = (
  rowData,
  styles,
  setShowDialog,
  setSubTopicList
) => {
  const subtopics = rowData?.subtopics || [];
  const count = subtopics.length;

  return (
    <p style={{ margin: "0px" }}>
      {rowData?.isBundle ? (
        <a
          className={styles?.link}
          onClick={() =>
            openOrderDialog(rowData, setShowDialog, setSubTopicList)
          }
        >
          View ({count})
        </a>
      ) : (
        subtopics
      )}
    </p>
  );
};

const openOrderDialog = (rowData, setShowDialog, setSubTopicList) => {
  setShowDialog(true);
  setSubTopicList(rowData?.subtopics || []);
};

const showQuizAttempts = (
  rowData,
  styles,
  setShowUserDetailDoalog,
  setUserDetailData
) => {
  return (
    <p style={{ margin: "0px" }}>
      <a
        className={styles?.link}
        onClick={() =>
          openuserQuizDetailsDialog(
            rowData,
            setShowUserDetailDoalog,
            setUserDetailData
          )
        }
      >
        View ({rowData?.totalQuizAttempts})
      </a>
    </p>
  );
};
const openuserQuizDetailsDialog = (
  rowData,
  setShowUserDetailDoalog,
  setUserDetailData
) => {
  setShowUserDetailDoalog(true);
  setUserDetailData(rowData);
};

export const cartDataColums = (
  styles,
  setShowDialog,
  setSubTopicList,
  deleteItem,
  id
) => {
  if (id !== undefined && id !== null) {
    return [
      { field: "domainName", header: "Domain", width: "15%" },
      {
        field: "TopicName",
        header: "Topic",
        width: "25%",
        minWidth: "",
      },
      {
        field: "quizName",
        header: "Subtopic",
        width: "25%",
        minWidth: "2rem",
        columnType: "fun",
        columnDefination: (rowData) =>
          showSubTopicCount(rowData, styles, setShowDialog, setSubTopicList),
      },
      {
        field: "addedSubscription.name",
        header: "Subscription",
        minWidth: "4rem",
      },
      { field: "expiryDate", header: "Expiry Date", minWidth: "4rem" },
    ];
  } else {
    return [
      { field: "domainName", header: "Domain", width: "20%" },
      {
        field: "TopicName",
        header: "Topic",
        width: "25%",
        minWidth: "",
      },
      {
        field: "quizName",
        header: "Subtopic",
        minWidth: "2rem",
        width: "25%",
        columnType: "fun",
        columnDefination: (rowData) =>
          showSubTopicCount(rowData, styles, setShowDialog, setSubTopicList),
      },
      {
        field: "addedSubscription.name",
        header: "Subscription",
        width: "15%",
        minWidth: "4rem",
      },
      {
        field: "expiryDate",
        header: "Expiry Date",
        width: "18%",
        minWidth: "20%",
      },
      {
        field: "selectedPlanPrice",
        header: "Price($)",
        width: "18%",
        minWidth: "2rem",
      },
      {
        field: "function",
        header: "",
        width: "1rem",
        minWidth: "2rem",
        sort: false,
        columnType: "fun",
        columnDefination: (rowData) => deleteCartItems(rowData, deleteItem),
      },
    ];
  }
};

const addLinkToOrderId = (rowData, onSelectOrderId) => {
  return (
    <a
      href="#"
      onClick={() => onSelectOrderId(rowData?.orderId)}
      className="order-id-button text-black"
    >
      {rowData?.orderId}
    </a>
  );
};

const handleDateFormate = (rowData) => {
  const date = new Date(rowData?.purchaseDate);
  const day = String(date.getDate()).padStart(2, "0");
  const month = date.toLocaleString("default", { month: "short" });
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

const getTooltipContentFailed = () => {
  return (
    <p>
      Payment has Failed. If the issue persists, please write to us at:
      <a
        href="mailto:info@quizandteach.com"
        style={{ color: "#0EA5E9", paddingLeft: "5px" }}
      >
        info@quizandteach.com
      </a>
    </p>
  );
};
const getTooltipContentInitiated = () => {
  return (
    <p>
      Payment has not been completed. If the issue persists, please write to us
      at:
      <a
        href="mailto:info@quizandteach.com"
        style={{ color: "#0EA5E9", paddingLeft: "5px" }}
      >
        info@quizandteach.com
      </a>
    </p>
  );
};

const handlePaymentStatus = (rowData) => {
  return (
    <div>
      <Tooltip
        target=".custom-initiated-icon"
        position="top"
        autoHide={false}
        style={{ width: "20rem", fontSize: "14px", fontWeight: "500" }}
      >
        {getTooltipContentInitiated()}
      </Tooltip>
      <Tooltip
        target=".custom-failed-icon"
        position="top"
        autoHide={false}
        style={{ width: "20rem", fontSize: "14px", fontWeight: "500" }}
      >
        {getTooltipContentFailed()}
      </Tooltip>
      <span className="d-flex align-items-center">
        {rowData?.paymentStatus}
        {rowData?.paymentStatus?.toLowerCase() === "initiated" ? (
          <i
            className="pi pi-info-circle p-2 custom-initiated-icon"
            style={{ cursor: "pointer", color: "#0EA5E9", fontSize: "0.8rem" }}
          ></i>
        ) : (
          rowData?.paymentStatus?.toLowerCase() === "failed" && (
            <i
              className="pi pi-info-circle p-2 custom-failed-icon"
              style={{
                cursor: "pointer",
                color: "#0EA5E9",
                fontSize: "0.8rem",
              }}
            ></i>
          )
        )}
      </span>
    </div>
  );
};

const handleTotal = (rowData) => {
  return <span>{rowData?.amount.toFixed(2)}</span>;
};

export const orderDetailsColums = (onSelectOrderId) => {
  return [
    {
      field: "orderId",
      header: "Order#",
      width: "30%",
      columnType: "fun",
      columnDefination: (rowData) => addLinkToOrderId(rowData, onSelectOrderId),
    },
    {
      field: "date",
      header: "Date",
      width: "30%",
      columnType: "fun",
      columnDefination: (rowData) => handleDateFormate(rowData),
    },
    {
      field: "paymentStatus",
      header: "Payment Status",
      width: "30%",
      columnType: "fun",
      columnDefination: (rowData) => handlePaymentStatus(rowData),
    },
    {
      field: "total",
      header: "Total($)",
      columnType: "fun",
      columnDefination: (rowData) => handleTotal(rowData),
    },
  ];
};

export const orderDetailsDisplayColumns = (
  styles,
  setShowDialog,
  setSubTopicList
) => {
  return [
    { field: "domain", header: "Domain", width: "20%" },
    { field: "topic", header: "Topic", width: "25%" },
    {
      field: "subtopics",
      header: "Subtopics",
      width: "24%",
      columnType: "fun",
      columnDefination: (rowData) =>
        showOrderSubTopicCount(rowData, styles, setShowDialog, setSubTopicList),
    },
    { field: "subscription", header: "Subscription", width: "15%" },
    { field: "expirydate", header: "Expiry Date", width: "25%" },
    { field: "price", header: "Price($)", width: "20%" },
  ];
};

export const myQuizzesColumns = (styles, setShowDialog, setSubTopicList) => {
  return [
    { field: "quizdomain", header: "Domain", width: "15%" },
    { field: "quiztopic", header: "Topic", width: "18%" },
    {
      field: "subtopics",
      header: "Subtopic",
      width: "15%",
      columnType: "fun",
      columnDefination: (rowData) =>
        showOrderSubTopicCount(rowData, styles, setShowDialog, setSubTopicList),
    },
    { field: "subscription", header: "Subscription", width: "15%" },
    { field: "subscriptiondate", header: "Subscription Date", width: "15%" },
    { field: "quizattempt", header: "Total Quiz Attempts", width: "10%" },
    { field: "takequizbtn", header: "", width: "30%" },
  ];
};
export const quizQuestionColumns = () => {
  return [
    { field: "questionId", header: "Sno", width: "10%" },
    { field: "question", header: "Question", width: "80%" },
    { field: "view", width: "10%" },
  ];
};

export const quizHistoryColumns = (
  styles,
  setShowUserDetailDoalog,
  setUserDetailData
) => {
  return [
    { field: "domainName", header: "Domain", width: "15%", sort: true },
    { field: "topicName", header: "Topic Name", width: "19%", sort: true },
    { field: "quizname", header: "Quiz Name", width: "20%", sort: true },
    {
      field: "dateOfLastAttempt",
      header: "Date of Last Attempt",
      width: "13%",
      sort: true,
    },
    {
      field: "highestScore",
      header: "Highest Scrore",
      width: "11%",
      sort: true,
    },
    {
      field: "totalQuizAttempts",
      header: "Total Quiz Attempts",
      width: "12%",
      sort: true,
    },
    {
      field: "",
      width: "15%",
      columnType: "fun",
      columnDefination: (rowData) =>
        showQuizAttempts(
          rowData,
          styles,
          setShowUserDetailDoalog,
          setUserDetailData
        ),
    },
  ];
};

export const quizHistoryDetailsColumn = (roleId) => {
  const columns = [
    {
      field: "attemptNumber",
      header: "S.No",
      width: "20%",
      sort: true,
    },
    {
      field: "attemptDate",
      header: "Attempted Date",
      width: "20%",
      sort: true,
    },
    { field: "score", header: "Score", width: "15%", sort: true },
    { field: "userQuizStatus", header: "Status", width: "15%" },
  ];
  if (roleId == 3) {
    columns.push({
      field: "download",
      header: "Request Certificate",
      width: "100%",
      columnType: "fun", // Indicating it's a custom column
      columnDefination: (rowData) => (
        <RequestCertificate
          quizId={rowData.quizId}
          userQuizId={rowData.userQuizId}
          optionalProp={false}
        />
      ),
    });
  }
  return columns;
};

export const errorColumn = () => {
  return [
    { field: "row", header: "Row", width: "20%" },
    { field: "message", header: "Description", width: "80%" },
  ];
};
