import axios from "axios";
import { useState, useEffect, useCallback, useRef } from "react";
import { ConfirmDialog } from "primereact/confirmdialog";
import { useNavigate } from "react-router-dom";
import { useLoginUserData } from "../store/login";
import { jwtDecode } from "jwt-decode";
import { Button } from "primereact/button";

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

const token = localStorage.getItem("authToken");
if (token) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}
const api = axios;

const TokenExpiredInterceptor = () => {
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const navigate = useNavigate();
  const { setUserData } = useLoginUserData();
  const isRenewingToken = useRef(false);

  useEffect(() => {
    const reqInterceptor = api.interceptors.request.use(
      async (config) => {
        // Check for the custom header to skip the token
        if (config.headers["Skip-Token"]) {
          delete config.headers["Authorization"];
          delete config.headers["Skip-Token"]; // Remove the custom header before sending the request
          return config;
        }
        const token = localStorage.getItem("authToken");
        if (token) {
          const decodedToken = decodeToken(token);
          const minutesDifference = getTimeDifference(decodedToken);
          if (minutesDifference <= 15 && minutesDifference > 0) {
            if (!isRenewingToken.current) {
              isRenewingToken.current = true;
              await renewToken();
              isRenewingToken.current = false;
            }
          }
        }

        // config.headers['Authorization'] = `Bearer ${localStorage.getItem('authToken')}`;
        return config;
      },
      (error) => Promise.reject(error)
    );

    //response interceptor to check for token expiration
    const interceptor = api.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        console.error("Axios error intercepted:", error);
        if (error.response && error.response.status === 401) {
          // Token expired, showing confirmation box
          setShowConfirmDialog(true);
          localStorage.clear();
          setUserData(null);
        }
        return Promise.reject(error);
      }
    );

    return () => {
      // Clean up by ejecting the interceptor when component unmounts
      api.interceptors.request.eject(reqInterceptor);
      api.interceptors.response.eject(interceptor);
    };
  }, []);

  const accept = () => {
    // redirecting to login
    navigate("/login");
    setShowConfirmDialog(false);
  };

  const decodeToken = useCallback((token) => {
    try {
      const decodedToken = jwtDecode(token);
      if (!decodedToken) {
        console.error("Decoded token is null or invalid.");
        return null;
      }
      return decodedToken;
    } catch (error) {
      return null;
    }
  }, []);
  const getTimeDifference = useCallback((decodedToken) => {
    if (decodedToken && decodedToken.exp) {
      const tokenExpDate = new Date(decodedToken.exp * 1000);
      const currentDate = new Date();
      let difference = tokenExpDate.getTime() - currentDate.getTime();
      const minutesDifference = Math.floor(difference / (1000 * 60)); // Convert to minutes
      return minutesDifference;
    }
  });
  const renewToken = useCallback(async () => {
    try {
      const token = localStorage.getItem("authToken");
      const response = await axios.post(`/api/Token/renew?token=${token}`);
      const newToken = response?.data?.result?.token;
      localStorage.setItem("authToken", newToken);
      axios.defaults.headers.common["Authorization"] = `Bearer ${newToken}`;
    } catch (error) {
      console.error("Token renewal failed:", error);
      localStorage.clear();
      setUserData(null);
      navigate("/login");
    }
  }, [setUserData]);
  const dialogFooter = (
    <div className="flex justify-content-center">
      <Button
        onClick={accept}
        autoFocus
        style={{
          backgroundColor: "#5eead4",
          color: "#134e4a",
          borderRadius: "4px",
        }}
      >
        Login
      </Button>
    </div>
  );
  return (
    <ConfirmDialog
      className="tokenExpireDialog"
      visible={showConfirmDialog}
      group="headless"
      onHide={() => setShowConfirmDialog(false)}
      message="Session expired. Please login again!"
      header="Session Expired"
      icon="pi pi-exclamation-triangle"
      style={{ width: "50vw", color: "red" }}
      breakpoints={{ "1100px": "75vw", "960px": "100vw" }}
      footer={dialogFooter}
    />
  );
};

export { api, TokenExpiredInterceptor };
