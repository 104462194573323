import { Card } from 'primereact/card';
import './CardComponent.css'

const CardComponent = (props) => {
    return (
        <Card className={props.className}>
           {props?.children}
        </Card>

    )
}

export default CardComponent;