import React, { useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";
import { requestDownload } from "../../services/quizQuestions";
import { appInsights } from "../../AppInsights";
import { Button } from "primereact/button";

import styles from "./RequestCertificate.module.scss";

const RequestCertificate = ({ quizId, userQuizId, optionalProp }) => {
  const toast = useRef(null);
  const [result, SetResult] = useState(null);
  const navigate = useNavigate();
  const [downloading, setDownloading] = useState(false);

  const handleRequest = async () => {
    setDownloading(true);
    try {
      let result = await requestDownload(quizId, userQuizId);
      SetResult(result);
      setDownloading(false);
      toast.current?.show({
        severity: "success",
        summary: result,
        life: 3000,
      });
    } catch (error) {
      appInsights.trackException("UI",{ exception: error });
      setDownloading(false);
      if (error?.status === 404) {
        toast.current?.show({
          severity: "error",
          summary: error?.title,
          life: 3000,
        });
      } else if (error?.status === 401 || error?.status === 403) {
        localStorage.clear();
        setTimeout(() => {
          navigate("/UnAuthorized", {
            state: { unauthorized: true },
            replace: true,
          });
        }, 1000);
      } else if (error?.status === 400) {
        toast.current?.show({
          severity: "error",
          summary: error?.title,
          life: 3000,
        });
      } else {
        toast.current?.show({
          severity: "error",
          summary: error?.title,
          life: 3000,
        });
      }
    }
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="row">
        <div className="col-12 col-md-3">
          <Button
            className={` ${styles.nextBtn} rounded d-flex justify-content-center`}
            style={{ width: "13rem" }}
            disabled={optionalProp && result}
            onClick={handleRequest}
          >
            {" "}
            {downloading ? (
              <div className={`${styles.loader_spinner}`}></div>
            ) : (
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                >
                  <path
                    d="M15.2204 17.75H2.78043C2.43365 17.7359 2.09305 17.6535 1.77814 17.5075C1.46323 17.3616 1.18018 17.155 0.945189 16.8996C0.710199 16.6442 0.527881 16.3449 0.408667 16.019C0.289453 15.693 0.235682 15.3468 0.250432 15V12C0.250432 11.8011 0.32945 11.6103 0.470102 11.4697C0.610754 11.329 0.80152 11.25 1.00043 11.25C1.19934 11.25 1.39011 11.329 1.53076 11.4697C1.67141 11.6103 1.75043 11.8011 1.75043 12V15C1.72461 15.2969 1.81407 15.5924 2.00026 15.8251C2.18645 16.0579 2.45508 16.21 2.75043 16.25H15.2204C15.5158 16.21 15.7844 16.0579 15.9706 15.8251C16.1568 15.5924 16.2463 15.2969 16.2204 15V12C16.2204 11.8011 16.2995 11.6103 16.4401 11.4697C16.5808 11.329 16.7715 11.25 16.9704 11.25C17.1693 11.25 17.3601 11.329 17.5008 11.4697C17.6414 11.6103 17.7204 11.8011 17.7204 12V15C17.7504 15.6954 17.5045 16.3744 17.0363 16.8894C16.5681 17.4045 15.9155 17.7137 15.2204 17.75Z"
                    fill="black"
                  />
                  <path
                    d="M9.00082 12.7498C8.90228 12.7503 8.80464 12.7311 8.71363 12.6933C8.62262 12.6555 8.54007 12.6 8.47082 12.5298L4.47082 8.52985C4.33834 8.38767 4.26622 8.19963 4.26965 8.00532C4.27308 7.81102 4.35179 7.62564 4.4892 7.48823C4.62661 7.35081 4.812 7.2721 5.0063 7.26867C5.2006 7.26524 5.38865 7.33737 5.53082 7.46985L9.00082 10.9398L12.4708 7.46985C12.613 7.33737 12.801 7.26524 12.9953 7.26867C13.1896 7.2721 13.375 7.35081 13.5124 7.48823C13.6499 7.62564 13.7286 7.81102 13.732 8.00532C13.7354 8.19963 13.6633 8.38767 13.5308 8.52985L9.53082 12.5298C9.46158 12.6 9.37903 12.6555 9.28802 12.6933C9.197 12.7311 9.09936 12.7503 9.00082 12.7498Z"
                    fill="black"
                  />
                  <path
                    d="M9 12.75C8.80189 12.7474 8.61263 12.6676 8.47253 12.5275C8.33244 12.3874 8.25259 12.1981 8.25 12V1C8.25 0.801088 8.32902 0.610322 8.46967 0.46967C8.61032 0.329018 8.80109 0.25 9 0.25C9.19891 0.25 9.38968 0.329018 9.53033 0.46967C9.67098 0.610322 9.75 0.801088 9.75 1V12C9.74741 12.1981 9.66756 12.3874 9.52747 12.5275C9.38737 12.6676 9.19811 12.7474 9 12.75Z"
                    fill="black"
                  />
                </svg>
                <span className="ps-1">Request Certificate</span>
              </div>
            )}
          </Button>
        </div>
      </div>
    </>
  );
};

export default RequestCertificate;
