import React, { useState, useEffect } from 'react';
import { Field, Form, Formik } from 'formik';
import * as YUP from "yup";
import styles from './password.module.scss';
import { NavLink, useNavigate } from 'react-router-dom';
import { api } from '../../api/api';
import { Button } from 'primereact/button';
import { otpVerification, passwordUpdation, sendEmail } from '../../services/forgotPassword';
import { appInsights } from '../../AppInsights';
const ForgotPassword = () => {
    appInsights.trackPageView("UI",{name: 'Forgotpassword.Jsx component visited' });
    const navigate = useNavigate();
    const [buttonClicked, setButtonClicked] = useState(false);
    const [step, setStep] = useState(1);
    const [otpEmail, setOtpEmail] = useState('');
    const [timeLeft, setTimeLeft] = useState(30);
    const [loading, setLoding] = useState(false);
    const [otp, setOtp] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showRepeatPassword, setShowRepeatPassword] = useState(false);
    // const [canResend, setCanResend] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [resend, setResend] = useState(false);

    useEffect(() => {
        let timer;
        if (timeLeft > 0 && step === 2) {
            timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
        }
        return () => clearTimeout(timer);
    }, [timeLeft, step ]);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes.toString().padStart(2, '0')}.${remainingSeconds.toString().padStart(2, '0')}`;
        //     const formattedMinutes = String(minutes).padStart(2, '0');
        //     const formattedSeconds = String(remainingSeconds).padStart(2, '0');
        //     return `${formattedMinutes}:${formattedSeconds}`;
    };

    const handleInputChange = (e, setFieldValue, fieldName, nextFieldName) => {
        const value = e.target.value;
        if (/^[0-9]$/.test(value)) {
            setFieldValue(fieldName, value);
            setErrorMessage('');
            if (nextFieldName) {
                document.getElementById(nextFieldName).focus();
            }
        } else if (value === "") {
            setFieldValue(fieldName, "");
        }
        setButtonClicked(false);
    };
    const handleKeyDown = (e, prevFieldName) => {
        if (e.key === "Backspace" && !e.target.value && prevFieldName) {
            document.getElementById(prevFieldName).focus();
        }
    };
    const initialValues = {
        otpEmail: '',
        otpFirst: '',
        otpSecond: '',
        otpThird: '',
        otpFourth: '',
        newPassword: '',
        repeatPassword: ''
    };

    const commonValidation = YUP.mixed().nullable();
    const validationSchemas = [
        YUP.object().shape({
            otpEmail: YUP.string().required("Email is required").matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Please enter a valid email address"),
        }),
        YUP.object().shape({
            otpFirst: YUP.string().required("Required"),
            otpSecond: YUP.string().required("Required"),
            otpThird: YUP.string().required("Required"),
            otpFourth: YUP.string().required("OTP Required")
        }),
        YUP.object().shape({
            newPassword: YUP.string()
                .required("Password is required")
                .min(6, "Password must be at least 6 characters")
                .matches(/[A-Za-z]/, "Password must contain at least one letter")
                .matches(/[0-9]/, "Password must contain at least one Number"),
            repeatPassword: YUP.string()
                .required("Confirm Password is required")
                .when("newPassword", {
                    is: (val) => (val && val.length > 0),
                    then: () => YUP.string().oneOf([YUP.ref('newPassword')], 'Passwords must match')
                })
        })
    ];

    const submitHandlers =
        [
            async (values) => {
                try {
                    setLoding(true)
                    const email = values.otpEmail;
                    const response = await sendEmail(email);
                    setTimeLeft(30);
                    setOtpEmail(values.otpEmail);
                    setErrorMessage('');
                    setStep(2);
                } catch (error) {
                    setLoding(false);
                    setErrorMessage(error.message)
                } finally {
                    setLoding(false)
                }
            },
            async (values) => {
                const otpValue = `${values.otpFirst}${values.otpSecond}${values.otpThird}${values.otpFourth}`;
                try {
                    setLoding(true)
                    const response = await otpVerification(otpEmail, otpValue);
                    setOtp(otpValue);
                    setErrorMessage('');
                    setStep(3);
                } catch (error) {
                    setLoding(false);
                    setErrorMessage(error.message)
                } finally {
                    setLoding(false)
                }
            },
            async (values, { setErrors }) => {
                try {
                    if (values.newPassword == values.repeatPassword) {
                        setLoding(true);
                        const response = await passwordUpdation(otp, values);
                        navigate("/Login");
                    } else {
                        setErrors({
                            repeatPassword: 'Confirm Password is required'
                        })
                    }
                } catch (error) {
                    setLoding(false)
                    setErrorMessage(error.message)
                } finally {
                    setLoding(false)
                }
            }
        ];
    const resendOtp = async (values, { setFieldValue,setTouched }) => {
        setResend(true);
        const email = values.otpEmail;
        setErrorMessage('');
        try {
            const response = await sendEmail(email);
            setErrorMessage('');
            setTimeLeft(30);
            setFieldValue('otpFirst', '');
            setFieldValue('otpSecond', '');
            setFieldValue('otpThird', '');
            setFieldValue('otpFourth', '');
            // setCanResend(false);
            setTouched({
                otpFirst: false,
                otpSecond: false,
                otpThird: false,
                otpFourth: false,
            });
        } catch (error) {
            setFieldValue('otpFirst', '');
            setFieldValue('otpSecond', '');
            setFieldValue('otpThird', '');
            setFieldValue('otpFourth', '');
            setErrorMessage('An error occurred while resending the OTP. Please try again.');
        }
        finally{
            setResend(false);
        }
    };
    const changeStep = (nextStep) => {
        setErrorMessage('');  
        setStep(nextStep); 
    };
    
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const toggleRepeatPasswordVisibility = () => {
        setShowRepeatPassword(!showRepeatPassword)
    }
    return (
        <div className='container-fluid py-lg-4'>
            <div className="row justify-content-center align-items-center">
                <div className='col-12 col-md-6 col-lg-4 my-lg-5 px-lg-0' style={{marginBottom:"250px"}}>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchemas[step - 1]}
                        onSubmit={submitHandlers[step - 1]}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue,
                            setTouched,
                        }) => (
                            <Form onSubmit={handleSubmit}>
                                <div className="container py-3 bg-white my-5">
                                    <div className="row justify-content-md-center px-1">
                                        {step === 1 && (
                                            <>
                                                <div className={`col-12 ${styles.password_title}`}>
                                                    <div className='d-flex justify-content-between pe-3'>
                                                        <h5>FORGOT PASSWORD</h5>
                                                        <NavLink to='/Login' className='text-decoration-none'>
                                                            <div className='d-flex justify-content-center align-items-center'>
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                    width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                    <path d="M15.4167 10H5H15.4167ZM5 10L10 5L5 10ZM5 10L10 15Z" fill="#0F172A" />
                                                                    <path d="M5 10L10 15M15.4167 10H5H15.4167ZM5 10L10 5L5 10Z" stroke="#0F172A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                </svg><span className='ps-2'>Back</span>
                                                            </div>
                                                        </NavLink>

                                                    </div>
                                                    <p>Please enter your registered email address to receive a verification code.</p>
                                                </div>
                                                <div className={`col-12 ${styles.password_mail} mt-2`}>
                                                    <div className='mb-2'>
                                                        <label htmlFor="otpEmail"><span>Email</span></label>
                                                    </div>
                                                    <Field
                                                        id="otpEmail"
                                                        name="otpEmail"
                                                        className="w-100 p-2 mb-1"
                                                        placeholder="John@email.com"
                                                        autoComplete="on"
                                                        value={values.otpEmail}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                            setErrorMessage('');
                                                        }}
                                                        onBlur={handleBlur}
                                                    />
                                                    <div className='text-danger mb-3'>
                                                        {errorMessage ? (
                                                            <div>{errorMessage}</div>
                                                        ) : (
                                                            errors.otpEmail && touched.otpEmail && <div>{errors.otpEmail}</div>)}
                                                        { }
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                        {step === 2 && (
                                            <>
                                                <div className={`col-12 ${styles.password_title}`}>
                                                    <div className='d-flex justify-content-between pe-3'>
                                                        <h5>VERIFY YOUR EMAIL</h5>
                                                        <NavLink className="text-decoration-none">
                                                            <div className='d-flex justify-content-center align-items-center' onClick={() => changeStep(1)}>
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                    width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                    <path d="M15.4167 10H5H15.4167ZM5 10L10 5L5 10ZM5 10L10 15Z" fill="#0F172A" />
                                                                    <path d="M5 10L10 15M15.4167 10H5H15.4167ZM5 10L10 5L5 10Z" stroke="#0F172A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                </svg><span className='ps-2'>Back</span>
                                                            </div>
                                                        </NavLink>
                                                    </div>
                                                    <p>Please enter the 4 digit code sent to {otpEmail}</p>
                                                </div>
                                                <div className={`col-12 ${styles.password_otp} mt-2`}>
                                                    <div className='d-flex justify-content-center justify-content-md-start mb-2'>
                                                        <Field
                                                            id="otpFirst"
                                                            name="otpFirst"
                                                            className="mb-3 text-center"
                                                            autoComplete="off"
                                                            value={values.otpFirst}
                                                            onChange={(e) => {handleInputChange(e, setFieldValue, 'otpFirst', 'otpSecond');setErrorMessage("")}}
                                                            onKeyDown={(e) => handleKeyDown(e, null)}
                                                        />
                                                        <Field
                                                            id="otpSecond"
                                                            name="otpSecond"
                                                            className="mb-3 ms-3 text-center"
                                                            autoComplete="off"
                                                            value={values.otpSecond}
                                                            onChange={(e) => {handleInputChange(e, setFieldValue, 'otpSecond', 'otpThird', 'otpFirst');setErrorMessage("")}}
                                                            onKeyDown={(e) => handleKeyDown(e, 'otpFirst')}
                                                        />
                                                        <Field
                                                            id="otpThird"
                                                            name="otpThird"
                                                            className="mb-3 ms-3 text-center"
                                                            autoComplete="off"
                                                            value={values.otpThird}
                                                            onChange={(e) => {handleInputChange(e, setFieldValue, 'otpThird', 'otpFourth', 'otpSecond');setErrorMessage("")}}
                                                            onKeyDown={(e) => handleKeyDown(e, 'otpSecond')}
                                                        />
                                                        <Field
                                                            id="otpFourth"
                                                            name="otpFourth"
                                                            className="mb-3 ms-3 text-center"
                                                            autoComplete="off"
                                                            value={values.otpFourth}
                                                            onChange={(e) => {handleInputChange(e, setFieldValue, 'otpFourth', null, 'otpThird');setErrorMessage("")}}
                                                            onKeyDown={(e) => handleKeyDown(e, 'otpThird')}
                                                        />
                                                    </div>
                                                    <div className='text-danger text-center text-md-start mb-2'>
                                                        {errorMessage ? (
                                                            <div>{errorMessage}</div>
                                                        ) : (
                                                            errors.otpFourth && touched.otpFourth && !resend && <div>{errors.otpFourth}</div>
                                                        )}
                                                    </div>
                                                    <div className={`${styles.resend_timer} mt-3`}>
                                                        <p>Didn't receive the OTP? {timeLeft > 0 ? (
                                                            <> Resend after <span>{formatTime(timeLeft)}</span></>
                                                        ) : (
                                                            <span><a href='#' onClick={() => { resendOtp(values, { setFieldValue,setTouched }) }}>Resend OTP</a></span>
                                                        )}</p>
                                                    </div>
                                                    {/* {timeLeft > 0 && (
                                                        <div className={`mb-3 ${styles.timer_text}`}>Code Expires in:<strong className='ps-1'>{formatTime(timeLeft)}</strong></div>
                                                    )} */}
                                                </div>
                                            </>
                                        )}

                                        {step === 3 && (
                                            <>
                                                <div className={`col-12 ${styles.password_title}`}>
                                                    <h5>CREATE NEW PASSWORD</h5>
                                                    <div className={`${styles.required_pattern}`}>
                                                        <p className='mb-1'>Your password must contain at least</p>
                                                        <ul className='ps-4'>
                                                            <li>1 Letter</li>
                                                            <li>1 Number</li>
                                                            <li>6 Character Length (Minimum)</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className={`col-12 ${styles.password_mail} mt-2`}>
                                                    <div className='mb-2'>
                                                        <label htmlFor="newPassword"><span>New Password</span></label>
                                                    </div>
                                                    <div className='position-relative'>
                                                        <Field
                                                            type={showPassword ? 'text' : 'password'}
                                                            id="newPassword"
                                                            name="newPassword"
                                                            className="w-100 p-2 mb-1"
                                                            autoComplete="off"
                                                            value={values.newPassword}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                        <i className={`pi ${showPassword ? 'pi-eye' : 'pi-eye-slash'} position-absolute`} onClick={togglePasswordVisibility}></i>                                                    </div>

                                                    <div className='text-danger mb-3'>
                                                        {errors.newPassword && touched.newPassword && <div>{errors.newPassword}</div>}
                                                    </div>
                                                </div>
                                                <div className={`col-12 ${styles.password_mail} mt-1`}>
                                                    <div className='mb-2'>
                                                        <label htmlFor="repeatPassword"><span>Confirm New Password</span></label>
                                                    </div>
                                                    <div className='position-relative'>
                                                        <Field
                                                            type={showRepeatPassword ? "text" : "password"}
                                                            id="repeatPassword"
                                                            name="repeatPassword"
                                                            className="w-100 p-2 mb-1"
                                                            autoComplete="off"
                                                            value={values.repeatPassword}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                        <i className={`pi ${showRepeatPassword ? 'pi-eye' : 'pi-eye-slash'} position-absolute`} onClick={toggleRepeatPasswordVisibility}></i>
                                                    </div>

                                                    <div className='text-danger mb-3'>
                                                        {errorMessage ? <div>{errorMessage}</div> : (errors.repeatPassword && touched.repeatPassword && <div>{errors.repeatPassword}</div>)}
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        <div className={`col-12 ${styles.otp_button}`}>
                                            <button type="submit" className='btn w-100 mb-3 d-flex justify-content-center'>
                                                {/* onClick={() => {
                                                if (step === 2 && canResend) {
                                                    resendOtp(values);
                                                }
                                            }
                                            } */}
                                                {loading ? (
                                                    <span className={`${styles.loader} p-1`}></span>) : (
                                                    <>
                                                        {step === 1 && 'Send OTP'}
                                                        {step === 2 && 'Verify'}
                                                        {step === 3 && 'Reset Password'}
                                                    </>
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;
