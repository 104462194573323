// import React from 'react'
// import { useLoginUserData } from '../store/login';
// import { Navigate, Outlet, useLocation } from 'react-router-dom';

// export default function RequireAuth({allowedRoles}){
    
//     const { setUserData } = useLoginUserData(); //here  setUserData shows whether user is logged in or not
    
//     let  userData ;
   
//     JSON.parse(localStorage.getItem('user'))? userData = JSON.parse(localStorage.getItem('user')):userData =  JSON.parse(localStorage.getItem('userData'));
   
 
//     const location = useLocation();
   
//     const isAuthorized = setUserData && allowedRoles.includes(userData?.roleId )? true:false; //here  isAuthorized shows whether user is Authorized in or not
//     const isUnauthorized = setUserData && !isAuthorized;
//     console.log("auth",isAuthorized,userData )
//     if (isUnauthorized) {
//         return (
//             <Navigate 
//                 to="/Unauthorized" 
//                 state={{ unauthorized: true }} 
//                 replace 
//             />
//         );
//     }
//     else{
//         return(
//             isAuthorized? <Outlet/>
//             : <Navigate to="/Login" state={{from:location}} replace />
//         );

//     }

// }

import React from 'react';
import { useLoginUserData } from '../store/login';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

export default function RequireAuth({ allowedRoles }) {
    const { setUserData } = useLoginUserData();
    const location = useLocation();

    const userData = JSON.parse(localStorage.getItem('user')) || JSON.parse(localStorage.getItem('userData'));
    const isAuthorized = userData && allowedRoles.includes(userData.roleId);
    const sign_Login = setUserData?true:false;
    if(allowedRoles[0]!==null){
        if (!setUserData || !userData) {
            localStorage.clear();
            setUserData(null);
            return <Navigate to="/Login" state={{ from: location }} replace />;
        }
        if (!isAuthorized) {
            return <Navigate to="/Unauthorized" state={{ unauthorized: true }} replace />;
        }
        return <Outlet />;
    }
    else{
        if (userData&&sign_Login) {
            return <Navigate to="/Unauthorized" state={{ unauthorized: true }} replace />;
        }
        return <Outlet />;
    }

}

