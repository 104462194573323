import React, { createContext, useContext, useEffect, useState } from 'react';

const LoginUserDataContext = createContext();
export const useLoginUserData = () => useContext(LoginUserDataContext);

const LoginUserDataProvider = ({ children }) => {
    const [loginUserData, setLoginUserData] = useState(null);
    useEffect(() => {
        const storedUserData = localStorage.getItem('userData');
        if (storedUserData) {
            setLoginUserData(JSON.parse(storedUserData));
        }
    }, []);
    const setUserData = (data) => {
        setLoginUserData(data);
        localStorage.setItem('userData', JSON.stringify(data)); 
    };
    // const setUserData = (data) => {
    //     if (data && data.result) {
    //         console.log('Setting user data:', data);
    //         setLoginUserData(data.result);
    //         localStorage.setItem('userData', JSON.stringify(data.result))
    //     } else {
    //         setLoginUserData(null);
    //     }
    // };
    const contextValue = {
        loginUserData,
        setUserData,
    };
    return (
        <LoginUserDataContext.Provider value={contextValue}>
            {children}
        </LoginUserDataContext.Provider>
    );
};

export default LoginUserDataContext;
 export { LoginUserDataProvider }