import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import history from './History';

 
const reactPlugin = new ReactPlugin();
const instrumentationKey = process.env.REACT_APP_APP_INSIGHTS_KEY;
 
if (!instrumentationKey) {
  throw new Error("Please provide instrumentation key");
}
 
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey:instrumentationKey, // Replace with your Application Insights instrumentation key
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: history }
    }
  }
});
 
appInsights.loadAppInsights();
 
export { reactPlugin, appInsights };