import { ProgressBar } from "primereact/progressbar";
import { Button } from "primereact/button";
import { useState, useEffect, useRef } from "react";
import styles from "./Quiz.module.scss";

const QuizBar = (props) => {
  const {
    quizDetails,
    questionNo,
    answeredQuestionCount,
    onTimerEnd,
    isTimerPaused,
    setTotalTimeTakenRef,
    buttonDisabled,
    showDialog, 
  } = props;
  const [timer, setTimer] = useState(quizDetails?.quizDuration * 60); // convert duration to seconds
  const totalTimeTakenRef = useRef(0);
  const hasTimerEnded = useRef(false);
  const navigateBackHome = () => {
    props?.navigateBackHome();
  }
   
  useEffect(() => {
    if (!quizDetails?.quizDuration) return;

    const interval = setInterval(() => {
      if (!isTimerPaused && !showDialog) {
        setTimer((prevTimer) => {
          const newTime = prevTimer - 1;
          totalTimeTakenRef.current = quizDetails?.quizDuration * 60 - newTime;
          if (newTime <= 0) {
            clearInterval(interval);
            if (!hasTimerEnded.current) {
              hasTimerEnded.current = true;
              onTimerEnd();
            }
            return 0;
          }
          return newTime; 
        });
      }
      
      // if (!isTimerPaused) {
      //   setTimer((prevTimer) => {
      //     if (prevTimer <= 1) {
      //       clearInterval(interval);
      //       onTimerEnd(); 
      //       // alert("Time's up! The test will end now.");
      //       // Need to Add timer expiry alert logic to end the test here based on the design update
      //       // navigateBackHome();
      //       return 0;
      //     }
      //     return prevTimer - 1;
      //   });
      // }
    }, 1000);

    return () => clearInterval(interval);
  }, [quizDetails?.quizDuration, navigateBackHome, isTimerPaused]);

  useEffect(() => {
    if (setTotalTimeTakenRef) {
      setTotalTimeTakenRef(totalTimeTakenRef.current);
    }
  }, [timer, setTotalTimeTakenRef]);
  
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;

    const timerStyle = {
      color: time <= 120 ? "#CB1307" : "inherit", 
    };
    return (
      <span style={timerStyle}>
        {String(minutes).padStart(2, "0")}:{String(seconds).padStart(2, "0")}
      </span>
    ); // const minutes = Math.floor(time / 60);
    // const seconds = Math.floor(time % 60);
    // const fractionalSeconds = time % 1;
    // const formattedFractionalSeconds = Math.floor(fractionalSeconds * 100);

    // return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
    //   2,
    //   "0"
    // )}.${String(formattedFractionalSeconds).padStart(2, "0")}`;
  };

  return (
    <>
      <div className="d-md-flex align-items-center justify-content-between flex-row-reverse">
        <div className={`${styles.exitBtn} text-end`}>
          <Button
            label="Exit Quiz"
            size="small"
            className="bg-danger text-white rounded"
            onClick={navigateBackHome}
            disabled={buttonDisabled}
          />
        </div>
        <div className={`${styles.titleBlock}`}>
          <p className={`${styles.title} font_text`}>{quizDetails?.quizName}</p>
        </div>
      </div>
      <div
        className={`${styles.barBg} d-sm-flex align-items-center justify-content-between text-white p-3 mt-3 rounded text-center`}
      >
        <div className={styles.barText}>
          Question: {questionNo}/{quizDetails?.numberOfQuestions}
        </div>
       
        <div className="col-12 col-sm-4 mt-3 mb-3 mt-sm-0 mb-sm-0">
          <ProgressBar className="w-100"
            value={Math.round(
              (answeredQuestionCount / quizDetails?.numberOfQuestions) * 100
            )}
          ></ProgressBar>
        </div>
       
        
        <div className={styles.barText}>
          <svg
            xmlns="
http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <path
              d="M9 18C7.21997 18 5.47991 17.4722 3.99987 16.4832C2.51983 15.4943 1.36628 14.0887 0.685088 12.4442C0.00389951 10.7996 -0.17433 8.99002 0.172937 7.24419C0.520203 5.49836 1.37737 3.89472 2.63604 2.63604C3.89472 1.37737 5.49836 0.520203 7.24419 0.172937C8.99002 -0.17433 10.7996 0.00389951 12.4442 0.685088C14.0887 1.36628 15.4943 2.51983 16.4832 3.99987C17.4722 5.47991 18 7.21997 18 9C18 11.387 17.0518 13.6761 15.364 15.364C13.6761 17.0518 11.387 18 9 18ZM9 1.5C7.51664 1.5 6.0666 1.93987 4.83323 2.76398C3.59986 3.58809 2.63856 4.75943 2.07091 6.12988C1.50325 7.50032 1.35473 9.00832 1.64411 10.4632C1.9335 11.918 2.64781 13.2544 3.6967 14.3033C4.7456 15.3522 6.08197 16.0665 7.53683 16.3559C8.99168 16.6453 10.4997 16.4968 11.8701 15.9291C13.2406 15.3614 14.4119 14.4001 15.236 13.1668C16.0601 11.9334 16.5 10.4834 16.5 9C16.5 7.01088 15.7098 5.10323 14.3033 3.6967C12.8968 2.29018 10.9891 1.5 9 1.5Z"
              fill="white"
            />
            <path
              d="M12 9.75H9C8.80189 9.74741 8.61263 9.66756 8.47253 9.52747C8.33244 9.38737 8.25259 9.19811 8.25 9V4C8.25 3.80109 8.32902 3.61032 8.46967 3.46967C8.61032 3.32902 8.80109 3.25 9 3.25C9.19891 3.25 9.38968 3.32902 9.53033 3.46967C9.67098 3.61032 9.75 3.80109 9.75 4V8.25H12C12.1989 8.25 12.3897 8.32902 12.5303 8.46967C12.671 8.61032 12.75 8.80109 12.75 9C12.75 9.19891 12.671 9.38968 12.5303 9.53033C12.3897 9.67098 12.1989 9.75 12 9.75Z"
              fill="white"
            />
          </svg>
          <span style={{ paddingLeft: "10px" }}>
            Remaining Time: {formatTime(timer)}
          </span>
        </div>
      </div>
    </>
  );
};

export default QuizBar;