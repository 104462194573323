import { api } from "../api/api";
import { appInsights } from "../AppInsights";
export const getProfileData = async () => {
  try {
    const response = await api.get(`/api/User`);
    return response?.data?.result || [];
  } catch (error) {
    appInsights.trackException("UI",{ exception: error });
    throw new Error(error?.response?.data?.title);
  }
};

export const updateProfileData = async (userData) => {
  try {
    const response = await api.put(`/api/User`, userData);
    return response?.data?.result || [];
  } catch (error) {
    appInsights.trackException("UI",{ exception: error });
    throw new Error(error?.response?.data?.title);
  }
};
