import { useEffect, useRef, useState } from "react";
import styles from "./Quiz.module.scss";
import { Button } from "primereact/button";
import CardComponent from "../../components/Card/CardComponent";
import { Checkbox } from "primereact/checkbox";
import { Skeleton } from "primereact/skeleton";
import { useNavigate, useParams } from "react-router-dom";
import { getAnswers } from "../../services/quizQuestions";
import { api } from "../../api/api";
import { useLoginUserData } from "../../store/login";
import { Toast } from "primereact/toast";
import { RadioButton } from "primereact/radiobutton";
import { ConfirmDialog } from "primereact/confirmdialog";
import { requestDownload } from "../../services/quizQuestions";
import { appInsights } from "../../AppInsights";
import RequestCertificate from "../../components/Table-Columns/RequestCertificate";

const Result = () => {
  appInsights.trackPageView("UI", { name: "Result.Jsx component visited" });
  const title = "Quiz";
  const toast = useRef(null);
  const navigate = useNavigate();
  const { userQuizId, quizId } = useParams();
  const [resultDownload, setResultDownload] = useState(null);
  const [resultData, setResultData] = useState(null);
  const [answers, setAnswers] = useState([]);
  const { loginUserData } = useLoginUserData();
  const [downloading, setDownloading] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  // const answers = [
  //   {
  //     Question:
  //       "Per the standard, an advisory notice is issued when – select the best answer from below",
  //     questionImag: "",
  //     QuestionAltText: "",
  //     controlType: "checkbox",
  //     options: [
  //       {
  //         option:
  //           "To provide additional information after a product has been launched",
  //         isCorrect: true,
  //         isSelected: true,
  //       },
  //       {
  //         option:
  //           "There is a change in the equipment used to manufacture the device",
  //         isCorrect: false,
  //         isSelected: false,
  //       },
  //       {
  //         option: "A new product is about to be introduced in the market",
  //         isCorrect: false,
  //         isSelected: false,
  //       },
  //       {
  //         option: "Competitor product is going through a recall",
  //         isCorrect: false,
  //         isSelected: false,
  //       },
  //     ],
  //     isAnswered: true,
  //   },
  //   {
  //     Question:
  //       "Per the standard, an advisory notice is issued when – select the best answer from below",
  //     questionImag: "",
  //     QuestionAltText: "",
  //     controlType: "checkbox",
  //     options: [
  //       {
  //         option:
  //           "To provide additional information after a product has been launched",
  //         isCorrect: true,
  //         isSelected: false,
  //       },
  //       {
  //         option:
  //           "There is a change in the equipment used to manufacture the device",
  //         isCorrect: false,
  //         isSelected: false,
  //       },
  //       {
  //         option: "A new product is about to be introduced in the market",
  //         isCorrect: false,
  //         isSelected: true,
  //       },
  //       {
  //         option: "Competitor product is going through a recall",
  //         isCorrect: false,
  //         isSelected: false,
  //       },
  //     ],
  //     isAnswered: true,
  //   },
  //   {
  //     Question:
  //       "Per the standard, an advisory notice is issued when – select the best answer from below",
  //     questionImag: "",
  //     QuestionAltText: "",
  //     controlType: "radio",
  //     options: [
  //       {
  //         option:
  //           "To provide additional information after a product has been launched",
  //         isCorrect: true,
  //         isSelected: false,
  //       },
  //       {
  //         option:
  //           "There is a change in the equipment used to manufacture the device",
  //         isCorrect: false,
  //         isSelected: false,
  //       },
  //       {
  //         option: "A new product is about to be introduced in the market",
  //         isCorrect: false,
  //         isSelected: false,
  //       },
  //       {
  //         option: "Competitor product is going through a recall ",
  //         isCorrect: false,
  //         isSelected: false,
  //       },
  //     ],
  //     isAnswered: false,
  //   },
  // ];
  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("result"));
    if (data) {
      setResultData(data);
      fetchResult();
    }
  }, []);

  const fetchResult = async () => {
    try {
      let result = await getAnswers(quizId, userQuizId);
      setAnswers(result);
    } catch (error) {
      appInsights.trackException("UI", { exception: error });
      showError(error?.message);
    }
  };
  const parentTopicId = localStorage.getItem("selectedTopicId");
  const navigateBack = () => {
    navigate(`/domainpage/${parentTopicId}`);
  };

  const formatTime = (time) => {
    const timeParts = time.split(":");
    const hours = timeParts[0];
    const minutes = timeParts[1];
    const seconds = timeParts[2].split(".")[0];
    // const formattedTime = `${hours}:${minutes}`;
    const formattedTime = `${hours}.${minutes}.${seconds}`;
    return formattedTime;
  };

  const accept = () => {
    navigate("/Login");
    setShowConfirmDialog(false);
    localStorage.clear();
  };
  const dialogFooter = (
    <div className="flex justify-content-center">
      <Button
        onClick={accept}
        autoFocus
        style={{
          backgroundColor: "#5eead4",
          color: "#134e4a",
          borderRadius: "4px",
        }}
      >
        Login
      </Button>
    </div>
  );

  const showError = (msg) => {
    toast?.current?.show({
      severity: "error",
      summary: "Error",
      detail: msg,
      life: 3000,
    });
  };
  const getUserToken = localStorage.getItem("authToken");
  return (
    <div className={styles.container}>
      <Toast ref={toast} />
      <ConfirmDialog
        className="tokenExpireDialog"
        visible={showConfirmDialog}
        group="headless"
        onHide={() => setShowConfirmDialog(false)}
        message="Session expired. Please login again!"
        header="Session Expired"
        icon="pi pi-exclamation-triangle"
        style={{ width: "50vw", color: "red" }}
        breakpoints={{ "1100px": "75vw", "960px": "100vw" }}
        footer={dialogFooter}
      />
      <div>
        <p className={styles.heading}>{title}</p>
      </div>
      {resultData && answers?.length > 0 ? (
        <>
          <div className="d-lg-flex align-items-center justify-content-between flex-row-reverse">
            <div className={` text-end`}>
              <Button
                icon="pi pi-arrow-left"
                label="Back to Medical Devices"
                size="small"
                onClick={navigateBack}
                className={` ${styles.backBtn_Result} rounded`}
              />
            </div>
            <div className={`${styles.titleBlock}`}>
              <p className={`${styles.title} font_text`}>
                {resultData?.quizName}
              </p>
            </div>
          </div>
          <div className={styles?.card_container}>
            <CardComponent>
              <div>
                <p className={` ${styles?.heading_text} m-0`}>
                  Thank you for completing this quiz.
                </p>
                <p className={styles?.message}>{resultData?.message}</p>
                <p
                  className={` ${styles?.heading_text} m-0 ${styles?.sub_heading}`}
                >
                  Do take the quiz again to make sure you can consistently
                  attain such scores.{" "}
                </p>
                <p
                  className={` ${styles?.heading_text} m-0 ${styles?.sub_heading}`}
                >
                  Once you are satisified with your performance - you can work
                  on quizzes at the next level.
                </p>
              </div>
              <div className="row" style={{ marginTop: "42px" }}>
                {getUserToken && (
                  <div
                    className={` ${styles.user_Result_text} col-12 col-md-3`}
                  >
                    Name: {resultData?.name}
                  </div>
                )}
                <div className={` ${styles.user_Result_text} col-12 col-md-3`}>
                  {" "}
                  Score: {resultData?.score}
                </div>{" "}
                <div className={` ${styles.user_Result_text} col-12 col-md-3`}>
                  Correct Answers: {resultData?.numberOfCorrectAnswers}
                </div>{" "}
                <div className={` ${styles.user_Result_text} col-12 col-md-3`}>
                  Incorrect Answers: {resultData?.numberOfIncorrectAnswers}{" "}
                </div>
                {!getUserToken && (
                  <div
                    className={` ${styles.user_Result_text} col-12 col-md-3`}
                  >
                    Unanswered Questions:{" "}
                    {resultData?.numberOfUnselectedAnswers}{" "}
                  </div>
                )}
              </div>
              {!getUserToken && <hr />}

              {!getUserToken && (
                <div className="row">
                  <div
                    className={` ${styles.user_Result_text} col-12 col-md-3`}
                  >
                    Time Taken: {formatTime(resultData?.totalTimeTaken)}
                  </div>
                </div>
              )}

              <hr />
              {getUserToken && (
                <div>
                  <div className="row">
                    <div
                      className={` ${styles.user_Result_text} col-12 col-md-3`}
                    >
                      Unanswered Questions:{" "}
                      {resultData?.numberOfUnselectedAnswers}{" "}
                    </div>
                    <div
                      className={` ${styles.user_Result_text} col-12 col-md-3`}
                    >
                      Time Taken: {formatTime(resultData?.totalTimeTaken)}
                    </div>
                  </div>
                  <hr />
                </div>
              )}

              {getUserToken && (
                <div>
                  <RequestCertificate
                    quizId={quizId}
                    userQuizId={userQuizId}
                    optionalProp={true}
                  />{" "}
                  <hr />
                </div>
              )}

              <div>
                <p className={`${styles.question_number} font-text`}>
                  Your Answers
                </p>
              </div>
              {answers?.length > 0 && (
                <>
                  {answers?.map((item, index) => {
                    return (
                      <div>
                        <span
                          className={`${styles.question_number} font-text me-2`}
                        >
                          Question {index + 1}:
                        </span>
                        <span className={`${styles.quention} font-text`}>
                          {item?.question}
                        </span>
                        {item?.answers?.length > 0 && (
                          <>
                            {item?.answers?.map((option, index) => {
                              const isChecked = option?.isSelected || false;
                              const isCorrect = option?.isCorrect || false;
                              if (item.questionTypeId === 2) {
                                return (
                                  <div
                                    className="d-flex align-items-center justify-content-start p-2"
                                    key={index}
                                  >
                                    <Checkbox
                                      name="resultCheck"
                                      className={`${
                                        option?.isCorrect && option?.isSelected
                                          ? styles?.correctGreen
                                          : ""
                                      } ${
                                        option?.isCorrect &&
                                        option?.isSelected === false
                                          ? styles?.checkGreen
                                          : ""
                                      } ${
                                        !option?.isCorrect && option?.isSelected
                                          ? styles?.checkRed
                                          : ""
                                      }`}
                                    ></Checkbox>
                                    <p className={` ${styles.optionText} mb-0`}>
                                      {option?.answer}{" "}
                                      {option?.isCorrect && (
                                        <span className="text-success">
                                          (Correct)
                                        </span>
                                      )}{" "}
                                      {!option?.isCorrect &&
                                        option?.isSelected && (
                                          <span className="text-danger">
                                            (Incorrect)
                                          </span>
                                        )}
                                    </p>
                                  </div>
                                );
                              } else if (item.questionTypeId === 1) {
                                return (
                                  <div
                                    className="d-flex align-items-center justify-content-start p-2"
                                    key={index}
                                  >
                                    <RadioButton
                                      name="resultRadio"
                                      checked={isChecked}
                                      className={`${
                                        option?.isCorrect && option?.isSelected
                                          ? styles?.correctGreen
                                          : ""
                                      } ${
                                        isCorrect ? styles?.checkGreen : ""
                                      } ${
                                        !isCorrect && isChecked
                                          ? styles?.checkRed
                                          : ""
                                      }`}
                                    ></RadioButton>
                                    <p className={` ${styles.optionText} mb-0`}>
                                      {option?.answer}{" "}
                                      {isCorrect && (
                                        <span className="text-success">
                                          (Correct)
                                        </span>
                                      )}{" "}
                                      {!isCorrect && isChecked && (
                                        <span className="text-danger">
                                          (Incorrect)
                                        </span>
                                      )}
                                    </p>
                                  </div>
                                );
                              }
                            })}
                            {!item?.isAnswered && (
                              <p className={` ${styles?.notAnswered} p-2`}>
                                Question was not answered
                              </p>
                            )}
                          </>
                        )}
                      </div>
                    );
                  })}
                </>
              )}
            </CardComponent>
          </div>
        </>
      ) : (
        <>
          <Skeleton
            width="100%"
            height={30}
            className={`my-2 mx-1 ${styles.customSkeleton}`}
          />
          <CardComponent>
            <div>
              <p className="m-0">Thank you for completing this quiz.</p>
              <Skeleton
                width="100%"
                height={10}
                className={`my-2 mx-1 ${styles.customSkeleton}`}
              />
              <p className="m-0">
                Do take the quiz again to make sure you can consistently attain
                such scores.{" "}
              </p>
              <p>
                Once you are satisified with yourperformance - you can work on
                quizzes at the next level.
              </p>
            </div>
            <div className="row">
              {getUserToken && getUserToken !== null && (
                <div className="col-12 col-md-3 d-flex align-items-center justify-content-center">
                  Name:{" "}
                  <Skeleton
                    width="100%"
                    height={30}
                    className={`my-2 mx-1 ${styles.customSkeleton}`}
                  />
                </div>
              )}
              <div className="col-12 col-md-3 d-flex align-items-center justify-content-center">
                {" "}
                Score:{" "}
                <Skeleton
                  width="100%"
                  height={30}
                  className={`my-2 mx-1 ${styles.customSkeleton}`}
                />
              </div>{" "}
              <div className="col-12 col-md-3 d-flex align-items-center justify-content-center">
                Correct Answers:{" "}
                <Skeleton
                  width="100%"
                  height={30}
                  className={`my-2 mx-1 ${styles.customSkeleton}`}
                />
              </div>{" "}
              <div className="col-12 col-md-3 d-flex align-items-center justify-content-center">
                Incorrect Answers:{" "}
                <Skeleton
                  width="100%"
                  height={30}
                  className={`my-2 mx-1 ${styles.customSkeleton}`}
                />{" "}
              </div>
              {!getUserToken && (
                <>
                <div className="col-12 col-md-3 d-flex align-items-center justify-content-center">
                  Unanswered Questions:{" "}
                  <Skeleton
                    width="100%"
                    height={30}
                    className={`my-2 mx-1 ${styles.customSkeleton}`}
                  />
                </div>
                <div className="row">
                <div className="col-12 col-md-3 d-flex align-items-center justify-content-center">
                  Time Taken:{" "}
                  <Skeleton
                    width="100%"
                    height={30}
                    className={`my-2 mx-1 ${styles.customSkeleton}`}
                  />
                </div>
                </div>
                </>
              )}
            </div>
            <hr />
            {/* <div className="row">
              <div className="col-12 col-md-3 d-flex align-items-center justify-content-center">
                Time Taken:{" "}
                <Skeleton
                  width="100%"
                  height={30}
                  className={`my-2 mx-1 ${styles.customSkeleton}`}
                />
              </div>
            </div>
            <hr /> */}
            {getUserToken && getUserToken !== null && (
              <div>
                <div className="row">
                <div className="col-12 col-md-3 d-flex align-items-center justify-content-center">
                   Unanswered Questions:
                    <Skeleton
                      width="100%"
                      height={30}
                      className={`my-2 mx-1 ${styles.customSkeleton}`}
                    />
                  </div>
                  <div className="col-12 col-md-3 d-flex align-items-center justify-content-center">
                    Time Taken:
                    <Skeleton
                      width="100%"
                      height={30}
                      className={`my-2 mx-1 ${styles.customSkeleton}`}
                    />
                  </div>
                </div>
                <hr />
              </div>
            )}
            <div>
              <p>Your Answers</p>
            </div>
            <Skeleton
              width="100%"
              height={30}
              className={`my-2 mx-1 ${styles.customSkeleton}`}
            />
            <Skeleton
              width="80%"
              height={20}
              className={`my-2 mx-1 ${styles.customSkeleton}`}
            />
            <Skeleton
              width="70%"
              height={20}
              className={`my-2 mx-1 ${styles.customSkeleton}`}
            />
            <Skeleton
              width="60%"
              height={20}
              className={`my-2 mx-1 ${styles.customSkeleton}`}
            />{" "}
            <Skeleton
              width="80%"
              height={20}
              className={`my-2 mx-1 ${styles.customSkeleton}`}
            />
          </CardComponent>
        </>
      )}
    </div>
  );
};

export default Result;
