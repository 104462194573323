import { api } from "../api/api";
import { appInsights } from "../AppInsights";
export const imageUpload = async(quizId,questionId,formData)=>{
    try {
        const response = await api.post(
          `/api/Quizzes/${quizId}/Question/${questionId}/Upload/Image`,
          formData,
          {
            headers: {
            //   Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        return response?.data?.result || [];
      } catch (error) {
        appInsights.trackException("UI",{ exception: error });
        throw new Error(error?.response?.data?.title);
      }
}