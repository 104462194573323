export const privacyData = [
  {
    header: "Introduction",
    desc: "Welcome to www.quizandteach.com, It is a platform owned and operated by KPI System, LLC. We value your privacy and are committed to protecting your personal data. This Privacy Policy describes how KPI System LLC and its affiliates (“we,” “us,” or “KPI”), process personal information in relation to your use of this platform.",
    subdesc: null,
  },
  {
    header: "Information we collect ",
    desc: "We collect personal information about you when you use the quizandteach platform. Without it, we may not be able to provide all the services requested. This information includes:",
    subdesc: [
      "Personal information such as your first name, last name, phone number, postal address, email address, etc. some of which will depend on the features you use. If appropriate we may ask you for a government issued picture ID. ",
      "Quizzes / training / other services used, scores, and responses you provide during the use of the platform.",
      "Payment Transaction Information. Such as payment instrument used, date and time, payment amount, payment instrument expiration date and billing postcode, PayPal email address, IBAN information, your address, and other related transaction details.",
      "Non personal information such as your IP address, the pages you visit, mobile or other device’s GPS, or other information you share with us, depending on your device settings. ",
    ],
  },
  {
    header: "How we use your information ",
    desc: "We may process this information to:",
    subdesc: [
      "Enable you to access the quizandteach platform and make payments",
      "Enable you to communicate with others",
      "Perform analytics, debug, and conduct research",
      "Develop and improve our products and services,",
      "Provide customer service training",
      "Send you messages, updates, security alerts, and account notifications",
      "Process, handle, or assess claims",
      "To communicate with you about our and our affiliates’ products and services, and special offers as and when available.",
    ],
  },
  {
    header: "Sharing your information",
    desc: "We do not share, transfer, sell, or trade your personal information with other parties except as described below:",
    subdesc: [
      "Trusted third parties and affiliates of other companies operating under KPI System LLC so long as such parties agree to keep it confidential. ",
      "Legal and / or tax related requirements require us to release such information to the government and other such authorities. Since such requests can be received even after your account is closed, some of your personal information will be kept on file after you terminate your relationship with us. ",
      "Enable you to access the quizandteach platform and make payments",
      "Enable you to communicate with others ",
      "Perform analytics, debug, and conduct research",
      "Develop and improve our products and services",
      "Provide customer service training",
      "Send you messages, updates, security alerts, and account notifications ",
      "Process, handle, or assess ",
    ],
  },
  {
    header: "Data security ",
    desc: "While we use a variety of security measures to keep your information safe and behind secured networks, there is no assurance of “perfect security.”  Accessibility to your personal information will be limited to appropriate KPI System LLC’s authorized staff only.  ",
    subdesc: null,
  },
  {
    header: "Cookies",
    desc: (
      <p>
        When you visit the quizandteach platform we may place cookies on your
        device. Cookies are small text files that websites send to your computer
        or other Internet-connected device to uniquely identify your browser or
        to store information or settings in your browser. Cookies allow us to
        recognize you when you return. They also help us provide a customized
        experience and can enable us to detect certain kinds of fraud, and
        better understand website traffic patterns and to optimize our
        experience. In some cases, we associate the information we collect using
        cookies and other technology with your personal information. Our
        business partners may also use these tracking technologies on the Airbnb
        Platform or engage others to track your behavior on our behalf. <br />
        <br />
        You can choose to turn off cookies via your browser settings, however,
        please understand this will compromise your experience when using our
        platform.
      </p>
    ),
    subdesc: null,
  },
  {
    header: "Third party links",
    desc: "Occasionally we may include or offer third party products or services on our website. These third-party sites have separate and independent policies. We have no responsibility or liability for the content and performance of such sites. Please make sure you check and understand their terms of service and privacy policies.",
    subdesc: null,
  },
  {
    header: "Changes to our privacy policy",
    desc: (
      <p>
        We reserve the right to modify this Privacy Policy at any time in
        accordance with applicable law. We will post the revised Privacy Policy
        and its effective date at the top. In case there is a material change,
        we will also provide you with notice of the modification by email before
        the effective date. If you disagree with the revised Privacy Policy, you
        can cancel your Account, however, please understand no refund of any
        balance will be made.
        <br />
        <br />
        If you do not cancel your account before the revised Privacy Policy
        becomes effective, your continued access to or use of the quizandteach
        platform will be subject to the updated policy.
      </p>
    ),
    subdesc: null,
  },
  {
    header: "Contact us",
    desc: (
      <div>
        <p className="mb-0">
          If you have questions about the privacy policy, you can contact us at:{" "}
        </p>
        <p className="mb-0">KPI System, LLC. </p>
        <p className="mb-0">
          3465 W. 111th. Loop, Unit A, Westminster, CO 80031{" "}
        </p>
        <p className="mb-0">Phone: +1 (720) 257 9572 </p>
        <p className="mb-0">
          E Mail:
          <a
            href="mailto:info@quizandteach.com"
            className="text-decoration-none ps-1"
          >
            info@quizandteach.com
          </a>{" "}
        </p>
      </div>
    ),
    subdesc: null,
  },
];

export const termsdata = [
  {
    header: "Use of Services",
    desc: [
      {
        text: "Eligibility: You must be at least 18 years old to use our website and any services offered. By using the website and / or our services, you represent and warrant that you are of legal age to form a binding contract.",
      },
      {
        text: "Registration and Managing your Account: Certain features of the website and services may require you to register by providing personal information. You agree that all information you provide is accurate, current, complete, and maintain the accuracy and confidentiality of your account on an ongoing basis.",
        subdesc: [
          "You will be responsible for all activities that occur in your account when used with your sign in and password.",
          "We cannot guarantee you will always have accessibility to your account since other factors such as internet connections, and network availability are beyond our control. The company will not be held accountable or liable for any damages arising from your inability to log into your account at any time.",
          "You acknowledge that your participation on or in the website in any manner whatsoever does not make you an employee or agency or partnership or joint venture or franchise of the Company. Neither does it confer any rights whatsoever to you.",
        ],
      },
      {
        text: "Prohibited activities: You agree that you will not do the following:",
        subdesc: [
          "Use the website and associated services for any unlawful purpose ",
          "Post or transmit any content that is offensive, defamatory, or infringes on other’s rights. ",
          "Download any content without express permission, or reverse engineer, decompile, or disassemble any of the content from the website. ",
          "Sell and / or use the contents and services provided through the website for any commercial purpose unless expressly permitted. Please contact us if this is an area of interest to you. ",
          "Interfere with, stalk, intimidate, harass, or incite other users of the website to commit acts of violence, or any such illegal acts. ",
          "Impersonate any person or entity, or falsely state or otherwise misrepresent your affiliation with a person or entity. ",
          "Access or use the website/services/products in any manner that could damage, disable, overburden or impair any of the website’s servers or the networks connected to any of the servers on which the website is hosted.",
          "Allow more than one person, or anyone other than yourself to operate your account and access the website/services available.  Company and multi-party access can be arranged to suit your need – please contact us to make such arrangements. ",
        ],
      },
    ],
  },
  {
    header: "Intellectual Property",
    desc: [
      {
        text: "Ownership: Most content, materials, trademarks, logos, and other intellectual property on the website and our services are owned or licensed by us and are protected by copyright, trademark, and other laws. Items of intellectual property owned by others may also show up on our website, however the respective parties would have the rights to such items. Having an account on our website and using it to access any materials does not grant you ownership rights of any kind whatsoever to any such intellectual property",
      },
      {
        text: "License: Subject to these T&Cs, we grant you a limited, non-exclusive, non-transferable, and revocable individual license one per e mail to access and use the website, services and intellectual property for your personal purpose. Arrangements can be made to provide your firm companywide access, to such intellectual property, please contact us with your needs.",
      },
    ],
  },
  {
    header: "Payment and Fees",
    desc: [
      {
        text: "Fees: The fees for access to our website and services are outlined on the website or as otherwise agreed upon. You agree to pay all fees associated with your use of the website and services as they become due.",
      },
      {
        text: "Payment Method: Payments for the use of our website and services may be processed through third-party payment processors such as PayPal. You agree to provide accurate and complete payment information and authorize us to charge the provided payment method for all applicable fees. Please note that any fees charged are nonrefundable under any circumstances.",
      },
    ],
  },
  {
    header: "Confidentiality",
    desc: [
      {
        text: "Non-Disclosure: Any confidential or proprietary information provided to you in the course of our interactions with you must be kept confidential and not disclosed to any third party without our prior written consent.",
      },
    ],
  },
  {
    header: "Limitation of Liability",
    desc: [
      {
        text: "Disclaimer: Use of our website and services are provided on an “as is” and “as available” basis. We always strive to ensure information and services provided are accurate, complete, and aim at total customer delight. However, errors can happen. As such our liability will be limited as described below. ",
      },
      {
        text: "Limitation of Liability: In no event shall we be liable for any direct or indirect, consequential, incidental, special, or punitive damages, including but not limited to loss of profits, arising out of or in connection with the use of our website/services. ",
      },
      {
        text: "This limitation of liability covers the Company, its agents, directors, owners, associates, and employees. ",
      },
    ],
  },
  {
    header: "Indemnification",
    desc: [
      {
        text: "You agree to indemnify the Company, and all its associates as mentioned in Clause 5.3 above from any claims, damages, liabilities, expenses and other such monetary obligations arising out of the use of our website and services or from the violation of any of the T&Cs. ",
      },
    ],
  },
  {
    header: "Termination",
    desc: [
      {
        text: "Termination by us: We reserve the right to terminate or suspend your access to the website and services at any time and for any reason without notice. ",
      },
      {
        text: "Termination by you: Should you choose to terminate your relationship with us please understand that no refunds of any kind will be made.",
      },
    ],
  },
  {
    header: "Changes to T&Cs",
    desc: [
      {
        text: "We reserve the right to make changes to these T&Cs at any time. The new T&Cs together with the effectiveness date will be posted on the website. We will do our best to inform you of material changes, however, this is not an assurance or a guarantee of any kind. ",
      },
    ],
  },
  {
    header: "Governing Law",
    desc: [
      {
        text: "These Terms shall be governed by and construed in accordance with the laws of Colorado, without regard to its conflict of law principles.",
      },
    ],
  },
  {
    header: "Contact Us",
    desc: [
      {
        text:  (
          <span>
            If you have any questions about these T&Cs, our company, or about
            the website, products, and services - please contact us at:
            <a href="mailto:info@quizandteach.com" className="text-decoration-none ps-1">info@quizandteach.com</a>, or
            by phone at +1 (720) 257 9572.
          </span>
        )
      }
    ],
  },
];
