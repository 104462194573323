import React from "react";
import GridImg from "../../../assets/images/homeScreenTile1.png";
import styles from './brief.module.scss'

const Brief = () => {
  const briefArray = [
    "Short and simple quizzes enable easy learning in minutes via rapid feedback",
    "Different questions every time - expand your knowledge, reinforce, and retain better",
    "Internalize your learning and recall on demand - save time, no searching",
    "Quizzes cover complete standards and regulations or sections / sub-topics",
    "Unlimited use and practice for life of subscription - save on training costs"]
  return (
    <div className={`${styles.brief_banner} text-white py-4`}>
      <section className="container-fluid">
        <div className="py-3 px-lg-3 mx-md-5">
          <div className="row">
            <div className="col-12 col-lg-7 col-xl-7">
              <div className="h-100 d-flex align-items-center justify-content-center">
              <div className="">
                <h2 className="font_text bold">
                  Welcome to quizandteach.com!
                </h2>
                <p>
                  Assess your knowledge, learn, grow, achieve mastery.
                </p>
                {briefArray.map((briefList,index) => {
                  return <div className={`d-flex align-content-center ${styles.brief_list}`} key={index}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M7 22H4C3.46957 22 2.96086 21.7893 2.58579 21.4142C2.21071 21.0391 2 20.5304 2 20V13C2 12.4696 2.21071 11.9609 2.58579 11.5858C2.96086 11.2107 3.46957 11 4 11H7M14 9V5C14 4.20435 13.6839 3.44129 13.1213 2.87868C12.5587 2.31607 11.7956 2 11 2L7 11V22H18.28C18.7623 22.0055 19.2304 21.8364 19.5979 21.524C19.9654 21.2116 20.2077 20.7769 20.28 20.3L21.66 11.3C21.7035 11.0134 21.6842 10.7207 21.6033 10.4423C21.5225 10.1638 21.3821 9.90629 21.1919 9.68751C21.0016 9.46873 20.7661 9.29393 20.5016 9.17522C20.2371 9.0565 19.9499 8.99672 19.66 9H14Z" stroke="#FDE047" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    <ul className="px-2 mb-3">
                      <li>{briefList}</li>
                    </ul>
                  </div>
                })}
                <p>Select your topic of interest from below and start your journey today!</p>
              </div>
              </div>
             
            </div>
            <div className="col-12 col-lg-5 col-xl-5">
              <div className="h-100 d-flex justify-content-center align-items-center">
              <div className="w-100">
                <img src={GridImg} className="w-100" alt="no img" />
              </div>
              </div>
             
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Brief;
