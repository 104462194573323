import * as actionTypes from "./actions";

const initialState = {
  domains:[],
  topicDetails:[]
};

const domainReducer = (state = initialState, action) => {
  switch (action.type) {
      case actionTypes.GET_DOMAINS_ALL:
      return {
        ...state,
        alldomains: [
          ...action.value 
        ],
      };
      case actionTypes.GET_DOMAINS_ALL_HOME:
        return {
          ...state,
          alldomainsHome:[
            ...action.value
          ]
        }
      case actionTypes.GET_TOPICDETAILS:
        return{
          ...state,
          topicDetails: [
            ...action.value
          ]
        }
      
    default:
      return state;
  }
};

export default domainReducer;
