import React from "react";
import styles from "./footer.module.scss";
import { NavLink, useLocation } from "react-router-dom";

const Footer = () => {
  const location = useLocation();
  const excludedPaths = [
    "/Login",
    "/ForgotPassword",
    "/verifyOtp",
    "/newPassword",
    "/Signup",
  ];
  const shouldHideButtons = excludedPaths.includes(location.pathname);

  return (
    <div>
      <div className="container-fluid">
        <div className={`px-lg-3 ${styles.footer_content}`}>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
              <div className={`${styles.reserved} text-center`}>
                <p className="mb-0 d-flex align-items-center justify-content-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="14"
                    width="14"
                    viewBox="0 0 512 512"
                  >
                    <path
                      fill="#ff0000"
                      d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM199.4 312.6c-31.2-31.2-31.2-81.9 0-113.1s81.9-31.2 113.1 0c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9c-50-50-131-50-181 0s-50 131 0 181s131 50 181 0c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0c-31.2 31.2-81.9 31.2-113.1 0z"
                    />
                  </svg>
                  <span className="ps-1">2024 <NavLink to='/' className='text-decoration-none' style={{color:"#374151"}}>quizandteach.com</NavLink>. All rights reserved.</span>
                </p>
              </div>
            </div>

            {!shouldHideButtons ? (
              <div className="col-12 col-lg-8">
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 px-0">
                    <div className={`${styles.footer_list}`}>
                      <ul className="d-flex justify-content-lg-end justify-content-center mb-0 p-0">
                        <li>
                          <NavLink to="/aboutUs">About</NavLink>
                        </li>
                        <li>
                          <NavLink to="/faqs">FAQs</NavLink>
                        </li>
                        <li>
                          <NavLink to="/testimonials">Testimonials</NavLink>
                        </li>
                        <li>
                          <NavLink to="/terms">Terms</NavLink>
                        </li>
                        <li>
                          <NavLink to="/privacy">Privacy Policy</NavLink>
                        </li>
                        <li>
                          <NavLink to="/contact">Contact</NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                    <div
                      className={`d-flex justify-content-center ${styles.footer_svg}`}
                    >
                      <div className="pe-3">
                        <NavLink className={styles.youtubeIcon}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            {" "}
                            <path
                              d="M17.812 5.01663H6.145C3.855 5.01663 2 6.85163 2 9.11563V14.8836C2 17.1476 3.856 18.9836 6.145 18.9836H17.812C20.102 18.9836 21.957 17.1476 21.957 14.8836V9.11563C21.957 6.85163 20.101 5.01562 17.812 5.01562V5.01663ZM15.009 12.2796L9.552 14.8546C9.51872 14.8707 9.48192 14.878 9.44503 14.876C9.40815 14.874 9.37237 14.8626 9.34103 14.8431C9.3097 14.8235 9.28382 14.7964 9.2658 14.7641C9.24779 14.7318 9.23822 14.6956 9.238 14.6586V9.34963C9.23867 9.3125 9.24872 9.27614 9.26722 9.24395C9.28573 9.21176 9.31208 9.18477 9.34382 9.1655C9.37556 9.14624 9.41167 9.13532 9.44877 9.13377C9.48587 9.13221 9.52276 9.14008 9.556 9.15663L15.014 11.8916C15.0504 11.9098 15.0809 11.9378 15.102 11.9725C15.1232 12.0071 15.1341 12.0471 15.1336 12.0877C15.1331 12.1283 15.1211 12.168 15.0991 12.2021C15.077 12.2362 15.0458 12.2634 15.009 12.2806V12.2796Z"
                              fill="#475569"
                            />{" "}
                          </svg>
                        </NavLink>
                      </div>
                      <div className="pe-3">
                        <NavLink className={styles.facebookIcon}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            {" "}
                            <path
                              d="M9.04598 5.865V8.613H7.03198V11.973H9.04598V21.959H13.18V11.974H15.955C15.955 11.974 16.215 10.363 16.341 8.601H13.197V6.303C13.197 5.96 13.647 5.498 14.093 5.498H16.347V2H13.283C8.94298 2 9.04598 5.363 9.04598 5.865Z"
                              fill="#475569"
                            />{" "}
                          </svg>
                        </NavLink>
                      </div>
                      <div className="pe-3">
                        <NavLink className={styles.twitterIcon}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            {" "}
                            <path
                              d="M22 5.90668C21.2504 6.23405 20.4565 6.44871 19.644 6.54368C20.4968 6.04291 21.138 5.24878 21.448 4.30968C20.64 4.78 19.7587 5.11128 18.841 5.28968C18.4545 4.88488 17.9897 4.56306 17.4748 4.34385C16.9598 4.12464 16.4056 4.01264 15.846 4.01468C13.58 4.01468 11.743 5.82468 11.743 8.05468C11.743 8.37068 11.779 8.67968 11.849 8.97468C10.2236 8.89737 8.63212 8.48208 7.17617 7.75531C5.72022 7.02855 4.43176 6.00626 3.393 4.75368C3.02883 5.36808 2.83742 6.06946 2.839 6.78368C2.8397 7.45164 3.00683 8.10891 3.32529 8.69607C3.64375 9.28323 4.1035 9.78179 4.663 10.1467C4.01248 10.1257 3.37602 9.952 2.805 9.63968V9.68968C2.805 11.6477 4.22 13.2807 6.095 13.6527C5.74261 13.7462 5.37958 13.7936 5.015 13.7937C4.75 13.7937 4.493 13.7687 4.242 13.7187C4.51008 14.5266 5.02311 15.231 5.70982 15.734C6.39653 16.237 7.22284 16.5137 8.074 16.5257C6.61407 17.6502 4.82182 18.2577 2.979 18.2527C2.647 18.2527 2.321 18.2327 2 18.1967C3.88125 19.3874 6.06259 20.018 8.289 20.0147C15.836 20.0147 19.962 13.8577 19.962 8.51868L19.948 7.99568C20.7529 7.42935 21.4481 6.72152 22 5.90668Z"
                              fill="#475569"
                            />{" "}
                          </svg>
                        </NavLink>
                      </div>
                      <div className="pe-3">
                        <NavLink className={styles.instaIcon}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            {" "}
                            <path
                              d="M16.017 2H7.947C6.37015 2.00185 4.85844 2.62914 3.74353 3.74424C2.62862 4.85933 2.00159 6.37115 2 7.948L2 16.018C2.00185 17.5948 2.62914 19.1066 3.74424 20.2215C4.85933 21.3364 6.37115 21.9634 7.948 21.965H16.018C17.5948 21.9631 19.1066 21.3359 20.2215 20.2208C21.3364 19.1057 21.9634 17.5938 21.965 16.017V7.947C21.9631 6.37015 21.3359 4.85844 20.2208 3.74353C19.1057 2.62862 17.5938 2.00159 16.017 2V2ZM19.957 16.017C19.957 16.5344 19.8551 17.0468 19.6571 17.5248C19.4591 18.0028 19.1689 18.4371 18.803 18.803C18.4371 19.1689 18.0028 19.4591 17.5248 19.6571C17.0468 19.8551 16.5344 19.957 16.017 19.957H7.947C6.90222 19.9567 5.90032 19.5415 5.16165 18.8026C4.42297 18.0638 4.008 17.0618 4.008 16.017V7.947C4.00827 6.90222 4.42349 5.90032 5.16235 5.16165C5.90122 4.42297 6.90322 4.008 7.948 4.008H16.018C17.0628 4.00827 18.0647 4.42349 18.8034 5.16235C19.542 5.90122 19.957 6.90322 19.957 7.948V16.018V16.017Z"
                              fill="#475569"
                            />{" "}
                            <path
                              d="M11.9821 6.81934C10.6135 6.82145 9.3016 7.36612 8.33397 8.33394C7.36633 9.30176 6.82192 10.6138 6.82007 11.9823C6.82166 13.3513 7.36609 14.6637 8.33397 15.6317C9.30185 16.5998 10.6142 17.1445 11.9831 17.1463C13.3522 17.1447 14.6647 16.6002 15.6328 15.6321C16.6009 14.664 17.1455 13.3514 17.1471 11.9823C17.145 10.6134 16.6 9.30122 15.6318 8.33353C14.6635 7.36584 13.351 6.82166 11.9821 6.82034V6.81934ZM11.9821 15.1383C11.1453 15.1383 10.3428 14.8059 9.75115 14.2143C9.15947 13.6226 8.82707 12.8201 8.82707 11.9833C8.82707 11.1466 9.15947 10.3441 9.75115 9.75241C10.3428 9.16074 11.1453 8.82834 11.9821 8.82834C12.8188 8.82834 13.6213 9.16074 14.213 9.75241C14.8047 10.3441 15.1371 11.1466 15.1371 11.9833C15.1371 12.8201 14.8047 13.6226 14.213 14.2143C13.6213 14.8059 12.8188 15.1383 11.9821 15.1383Z"
                              fill="#475569"
                            />{" "}
                            <path
                              d="M17.1559 8.09509C17.8391 8.09509 18.3929 7.54127 18.3929 6.85809C18.3929 6.17492 17.8391 5.62109 17.1559 5.62109C16.4728 5.62109 15.9189 6.17492 15.9189 6.85809C15.9189 7.54127 16.4728 8.09509 17.1559 8.09509Z"
                              fill="#475569"
                            />{" "}
                          </svg>
                        </NavLink>
                      </div>
                      <div>
                        <NavLink className={styles.linkedinIcon}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            {" "}
                            <path
                              d="M21.959 13.7194V21.0984H17.681V14.2134C17.681 12.4834 17.062 11.3034 15.514 11.3034C14.332 11.3034 13.628 12.0994 13.319 12.8684C13.206 13.1434 13.177 13.5264 13.177 13.9114V21.0984H8.897C8.897 21.0984 8.955 9.43837 8.897 8.22937H13.177V10.0534L13.149 10.0954H13.177V10.0534C13.745 9.17837 14.76 7.92737 17.033 7.92737C19.848 7.92737 21.959 9.76737 21.959 13.7194ZM4.421 2.02637C2.958 2.02637 2 2.98637 2 4.24937C2 5.48437 2.93 6.47337 4.365 6.47337H4.393C5.886 6.47337 6.813 5.48437 6.813 4.24937C6.787 2.98637 5.887 2.02637 4.422 2.02637H4.421ZM2.254 21.0984H6.532V8.22937H2.254V21.0984Z"
                              fill="#475569"
                            />{" "}
                          </svg>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="col-12 col-lg-8">
               <div className="row">
               <div className="col-12 col-md-1 col-lg-3 col-xl-5"></div>
               <div className="col-12 col-md-11 col-lg-9 col-xl-7">
                    <div className={`${styles.footer_list} me-md-5 me-lg-0`}>
                    <ul className="d-flex justify-content-center mb-0 p-0">
                    <li>
                          <NavLink to="/aboutUs">About</NavLink>
                        </li>
                        <li>
                          <NavLink to="/faqs">FAQs</NavLink>
                        </li>
                        <li>
                          <NavLink to="/testimonials">Testimonials</NavLink>
                        </li>
                        <li>
                          <NavLink to="/terms">Terms</NavLink>
                        </li>
                        <li>
                          <NavLink to="/privacy">Privacy Policy</NavLink>
                        </li>
                        <li>
                          <NavLink to="/contact">Contact</NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                  </div>
              </div>
            )}
          
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
