export const testimonialData = [
  {
    testimonyDesc:
      "This quiz website is fantastic! The variety of quizzes available is amazing, and it's a great way to challenge myself and learn new things. The interface is user-friendly and makes it easy to jump right in. Highly recommend it to quiz lovers!",
    testimonyName: "Alex M",
    testimonyDesignation: "High School Teacher",
  },
  {
    testimonyDesc:
      "This website has been a lifesaver for my exam preparations. The quizzes are well-structured and cover all the key concepts. It's a great way to test my knowledge and identify areas I need to work on.",
    testimonyName: "Brian Evans",
    testimonyDesignation: "College Student",
  },
  {
    testimonyDesc:
      "As a corporate trainer, I use this quiz website to create fun and engaging training sessions for my team. The customizable quizzes and instant feedback help reinforce learning and make our sessions more interactive.",
    testimonyName: "Daniel Kim",
    testimonyDesignation: "Corporate Trainer",
  },
  {
    testimonyDesc:
      "I participate in quiz competitions, and this site is an excellent resource for practice. The timed quizzes and leaderboards add an element of challenge and competition that keeps me motivated.",
    testimonyName: "Franklin Harris",
    testimonyDesignation: "Competitive Quizzer",
  },
  {
    testimonyDesc:
      "I use this site to brush up on my programming knowledge. The tech quizzes are comprehensive and up-to-date with the latest industry trends. It's a convenient and efficient way to keep my skills sharp.",
    testimonyName: "Jack Brown",
    testimonyDesignation: "Software Developer",
  },
];
