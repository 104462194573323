import { api } from "../api/api";
import { appInsights } from "../AppInsights";
export const orderDetails = async () => {
  try {
    const response = await api.get(`/api/Orders`);
    return response?.data?.result || [];
  } catch (error) {
    appInsights.trackException("UI",{ exception: error });
    throw new Error(error?.response?.data?.title);
  }
};

export const getOrderIdDetails = async (orderId) => {
  try {
    const response = await api.get(`/api/Orders/${orderId}`);
    return response?.data?.result || [];
  } catch (error) {
    appInsights.trackException("UI",{ exception: error });
    throw new Error(error?.response?.data?.title);
  }
};

export const getMyQuizDetails = async (userId) => {
  try {
    const response = await api.get(`/api/User/MyQuizzes?userId=${userId}`);
    return response?.data?.result || [];
  } catch (error) {
    appInsights.trackException("UI",{ exception: error });
    throw new Error(error?.response?.data?.title);
  }
};

export const getQuizHistory = async (userId) => {
  try {
    const response = await api.get(`/api/User/UserQuizzes?userId=${userId}`);
    return response?.data?.result || [];
  } catch (error) {
    appInsights.trackException("UI",{ exception: error });
    throw new Error(error?.response?.data?.title);
  }
};

export const getStudentsList = async()=>{
  try{
    const response = await api.get(`/api/User/UserDetails`);
    return response?.data?.result || [];
  }catch(error){
    appInsights.trackException("UI",{ exception: error });
    throw new Error(error?.response?.data?.title);
  }
}