import React from "react";
import styles from "./domainHead.module.scss";
import { useNavigate } from "react-router-dom";

const DomainHead = (props) => {
  const navigate = useNavigate();
  const {heading} = props;
  const navigateToQuiz = () =>{
    navigate('/Quiz');
  }
  return (
    <div>
      <div className={`${styles.domain_heading}`}>
        <div className="d-flex align-items-start w-100">
          <h5 className="py-md-2 text-uppercase">{heading}</h5>
          {/* <div className={`ms-4 ${styles.free_trial_domain} d-flex justify-content-center align-items-center flex-shrink-0`}>
            <button className="btn px-3 bg-white">Try out free quiz</button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default DomainHead;
