import { Button } from "primereact/button";

export const cartFooterTemplate = (data,styles,referralEmailError,id,validateEmail,navigateBack,navigateToPayment,navigateToQuizzes) => {
  return (
    <>
      {(id === undefined || id === null) &&<div className="d-flex justify-content-between align-items-center p-3" style={{background:'#F8FAFC',borderTop:"1px solid #E2E8F0",borderBottom:"1px solid #E2E8F0"}}>
        <h4 className="fw-semibold mb-0" style={{fontSize:"20px"}}>Total</h4>
        <h4 className="fw-semibold mb-0" style={{paddingRight:'5rem'}}>{data.toFixed(2)}</h4>
      </div>}
      {(id === undefined || id === null) &&<div className="bg-white d-md-flex align-items-center" style={{paddingTop:'1.25rem'}}>
        <div className="p-2">
        <h5 className={`${styles.referralMail}`}>Referral Email: </h5>
        </div>
        <div className="w-60">
        <input type="text" onChange={(e)=>validateEmail(e.target.value)} className={`form-control ${styles.referralInput}`}/>
        </div>
      </div>}
      {(id === undefined || id === null) && <div className={`bg-white d-flex align-items-center  ${styles.errorMsg}`}>
      <span className={`text-danger`} style={{display:!referralEmailError && 'none'}}>Please enter a valid email address</span>
      </div>}
      <div className="row bg-white" style={{paddingTop:'1.25rem'}}>
        {(id === undefined || id === null) &&<div className="col-12 col-md-8 offset-md-4 text-end p-0">
          <Button aria-label="CONTINUE SHOPPING" severity="secondary"  outlined className={`${styles?.continueBtn}`} onClick={navigateBack}>CONTINUE SHOPPING</Button>
          <Button aria-label="PROCEED TO CHECKOUT" severity="secondary"  disabled={data ===0 } className={`${styles?.proceedBtn}`} onClick={navigateToPayment}>PROCEED TO CHECKOUT</Button>
        </div>}
        {(id !== undefined && id !== null) &&<div className="col-12 col-md-8 offset-md-4 text-end p-0">
          <Button aria-label="GO TO QUIZZES" severity="secondary"  outlined className={`${styles?.continueBtn}`} onClick={navigateToQuizzes}>GO TO QUIZZES</Button>
          <Button aria-label="BACK TO HOME" severity="secondary"  disabled={data ===0 } className={`${styles?.proceedBtn}`} onClick={navigateBack}>BACK TO HOME</Button>
        </div>}
        <div className="col-4 text-end p-0">
        
        </div>
      </div>
    </>
  );
};


export const orderDetailsFooter = (data,styles,navigateBackToHome) => {
  return (
    <div>
      <div
        className="d-flex justify-content-between align-items-center p-3"
        style={{background:'#F8FAFC',borderTop: "1px solid #E2E8F0",borderBottom: "1px solid #E2E8F0"}}
      >
        <h4 className="fw-semibold mb-0">Total</h4>
        <h4 className="fw-semibold mb-0">
          {data.toFixed(2)}
        </h4>
      </div>
      <div className="col-12 col-md-8 offset-md-4 text-end p-0">
        <Button
          aria-label="GO TO QUIZZES"
          severity="secondary"
          outlined
          className={`${styles?.goToQuizbtn}`}
          onClick={navigateBackToHome}
        >
          GO TO HOME
        </Button>
       
      </div>
    </div>
  );
};
