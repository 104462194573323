import React, { useState } from "react";
import DomainImg from "../../../assets/images/homeScreenTile2.png";
import styles from "./domain.module.scss";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../../store/action-creators";
import { useEffect } from "react";
import { Skeleton } from "primereact/skeleton";
import { Tooltip } from 'primereact/tooltip';
import { appInsights } from "../../../AppInsights";
const Domain = () => {
  appInsights.trackPageView("UI",{name: 'Domain.Jsx component visited' });
 
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [domains, setDomains] = useState([]);
  const [firstSubTopicId, setFirstSubTopicId] = useState(0)
  const [showSkeleton, setShowSkeleton] = useState(true)
  const [ellipses, setEllipses] = useState({});
  const { getAllDomainsHome } = bindActionCreators(actionCreators, dispatch);
  const domainList = useSelector((state) => {
    return state.domainReducer.alldomainsHome;
  });
  const viewDomain = () => {
    navigate(`/domainpage/${firstSubTopicId}`);
  };

  useEffect(() => {
    if (!domainList) {
      fetchDomains();
    }
  }, []);
  useEffect(() => {
    setDomains(domainList);
  }, [domainList]);
  useEffect(() => {
    if (domainList?.length > 0) {
      setShowSkeleton(false)
      setFirstSubTopicId(domainList[0]?.id)
    }
  }, [domainList])
  const fetchDomains = async () => {
    try {
      getAllDomainsHome();
    } catch (error) { }
  };
  useEffect(() => {
    if (domains && domains.length > 0) {
      const newEllipses = {};
      domains.forEach((topic) => {
        const element = document.getElementById(`navlink-${topic.id}`);
        if (element) {
          newEllipses[topic.id] = element.scrollWidth > element.clientWidth;
        }
      });
      setEllipses(newEllipses);
    }
  }, [domains]);
  const hideHeading = '/quizzes';
  return (
    <div className="bg-white mt-0">
      <section className="container-fluid">
        <div className="px-lg-3 mx-md-5">
          {showSkeleton && <div className="py-5 d-flex justify-content-between align-items-center">
            <div className="row">
              <div className="col-12 col-md-6">
                <Skeleton className="mb-2" width="5rem"></Skeleton>
                <Skeleton width="10rem" className="mb-2"></Skeleton>
                <div className="row mt-3">
                  <div className="col-12 col-md-6">
                    <Skeleton width="15rem" className="mb-2"></Skeleton>
                  </div>
                  <div className="col-12 col-md-6">
                    <Skeleton width="15rem" className="mb-2"></Skeleton>
                  </div>
                  <div className="col-12 col-md-6">
                    <Skeleton width="15rem" className="mb-2"></Skeleton>
                  </div>
                  <div className="col-12 col-md-6">
                    <Skeleton width="15rem" className="mb-2"></Skeleton>
                  </div>
                  <div className="col-12 col-md-6">
                    <Skeleton width="15rem" className="mb-2"></Skeleton>
                  </div>
                  <div className="col-12 col-md-6">
                    <Skeleton width="15rem" className="mb-2"></Skeleton>
                  </div>
                  <div className="col-12">
                    <Skeleton width="10rem" height="2rem"></Skeleton>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <Skeleton width="100%" height="8rem" borderRadius="16px" className="mt-3"></Skeleton>
              </div>
            </div>
          </div>}
          {domains?.length > 0 && !showSkeleton && (
            <div className={`py-4 ${styles.domain_content}`}>
              {location.pathname !== hideHeading && (
                <div className="d-flex justify-content-between mt-3">
                <h5 className={`${styles.domain_head}`}>DOMAINS</h5>
              </div>
              )}
              <div className="row mt-3">
                <div className="col-12 col-lg-6 col-xl-6">
                  <div className="row">
                    <h5 className="mb-3 font_text medium">Medical Devices</h5>
                    {domains
                      ?.map((medicaltopics) => {
                        return (
                          <div
                            className="col-12 col-md-6"
                            key={medicaltopics?.id}
                          >
                            <div
                              className={`mb-2 ${styles.domain_name} d-flex align-items-start mb-2`}
                            >
                              <i className="pi pi-angle-right me-2 pt-1"></i>
                              <NavLink to={`/domainpage/${medicaltopics?.id}`} id={`navlink-${medicaltopics?.id}`} className={`${styles.ellipsis}`}>{medicaltopics?.name}</NavLink>
                              {ellipses[medicaltopics.id] && (
                                <Tooltip
                                  target={`#navlink-${medicaltopics.id}`}
                                  content={medicaltopics.name}
                                />
                              )}
                            </div>
                          </div>
                        );
                      })}
                    <div className={`my-3 ${styles.view_all_btn}`}>
                      <button
                        className="btn d-flex justify-content-center align-items-center text-white"
                        onClick={viewDomain}
                      >
                        <span className="pe-2">View All</span>{" "}
                        <i className="pi pi-arrow-right"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-6 col-xl-6">
                  <div className="text-center">
                    <img src={DomainImg} className="img-fluid" alt="no img" />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default Domain;
