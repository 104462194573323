import React, { useState } from "react";
import styles from "./topicList.module.scss";


const TopicList = (props) => {
  const {topics, selectedTopicId}= props
  const handleTopicClick = (topic) => {
    props?.onTopicSelect(topic);
  };

  return (
    <div className="h-100">
      <div className={`${styles.course_list_content} h-100 bg-white`}>
        <ul className="p-0">
          {topics?.map((topic) => (
             <li key={topic.id} onClick={() => handleTopicClick(topic)} className={`d-flex align-items-center`} style={String(selectedTopicId) === String(topic.id) ? { backgroundColor: '#5eead4', color: '#134e4a', fontWeight:'bold', fontSize:'15px' }:{}}>
             {topic.name}
           </li>           
            ))}
        </ul>
      </div>
    </div>
  );
};

export default TopicList;
