import React, { useEffect } from "react";
import styles from "./aboutUs.module.scss";
import CardComponent from "../../components/Card/CardComponent";
import AboutUsImg from "../../assets/images/aboutUsImg.png";
import { NavLink } from "react-router-dom";
import { appInsights } from "../../AppInsights";
const AboutUs = () => {
  appInsights.trackPageView("UI",{name: 'AboutUs.Jsx component visited' });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const serviceList = [
    "Quality Systems, Standards, and Regulations (e.g. ISO 13485, 14971, 21 CFR 820, EU MDR, MDSAP…)",
    "Product Design and Development for various specialties – orthopedics, cardiovascular, ophthalmic, diagnostics / instruments, radiology applications, and more…",
    "Manufacturing – coatings and surface modification technologies, machining, molding, casting, forging, additive manufacturing, composites, ceramics, etc. ",
    "Packaging, labeling, and shipping – design, evaluation, validations, materials, technologies, and sterilization ",
    "Complaints – tracking, handling, reporting, and resolution.",
    "Regulatory filings – 510(k), DeNovo, Premarket Authorizations (PMA), Humanitarian Device Exemptions (HDE), and EU MDR filings. ",
    "New products and technologies – from concept to commercialization including research, pre-clinical studies and clinical trials.  ",
    "Educational technologies, learning styles, psychology, and neuroscience of learning",
    "Continual improvement – Lean, Six Sigma, Finite Element Modeling, Project Management, Risk Management",
    "Corrective and Preventive Action, Management Controls, Purchasing, and Supplier Controls",
    "Post-market activities such as surveillance, data collection, analysis, and competitor benchmarking ",
    "Remediation and 483s – strategies and actions essential to addressing outcomes from regulatory inspections.",
  ];
  return (
    <div className={styles.container}>
      <h5 className={`${styles.aboutheading} mb-3`}>ABOUT US</h5>
      <div className={styles.shadowcontainer}>
        <CardComponent>
          <div className="container">
            <div className="row" style={{ padding: "14px" }}>
              <div className="col-12 col-lg-8 pt-lg-4 pt-md-0 d-flex align-items-center">
                <div className={styles.infoText}>
                  <p className="mb-4">
                    <strong>
                      <NavLink
                        to="/"
                        className="text-decoration-none"
                        style={{ color: "#374151" }}
                      >
                        quizandteach.com
                      </NavLink>
                    </strong>{" "}
                    &nbsp; harnesses the power of frequent short bursts of
                    learning over time. This focused approach is especially
                    useful since attending long lectures / workshops is becoming
                    unfeasible.
                  </p>
                  <p className="mb-4">
                    Our quizzes are designed to help you quickly focus on key
                    points in a topic by assessing your current knowledge,
                    providing the correct answer(s), and explanations. Each quiz
                    lasts just a few minutes. Taking them multiple times expands
                    and deepens your understanding because different questions
                    are presented each time.
                  </p>
                  <p className="mb-4">
                    Work with the quizzes regularly and see your scores improve
                    steadily. It will create a powerful feedback loop that takes
                    you to new heights and a mastery over the topic. You can
                    also print your certificate as evidence of training
                    verification / competence.
                  </p>
                  <p className="mb-4">
                    All quizzes are designed by well-credentialed / certified
                    staff with decades of industrial experience in their
                    respective domains.
                  </p>
                </div>
              </div>
              <div className="col-12 col-lg-4 px-0">
                <div className="imagediv ms-md-5">
                  <img
                    src={AboutUsImg}
                    className="w-100 img-fluid"
                    alt="About Us"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={`${styles.about_container_block} row`}>
            <div className="col-12">
              <div className={`${styles.join_us_div} mt-5`}>
                <p className="font_text medium mb-0">
                  Join us today to start your journey. Pick a topic, subscribe,
                  and soon you will be on your way!
                </p>
              </div>
            </div>
            <div className="col-12">
              <h5 className={`${styles.sample_list_heading} my-4 pt-2`}>
                OUR TEAM AT YOUR SERVICE
              </h5>
              <p className={styles.sample_list_paragraph}>
                We have assembled a team with diverse set of knowledge, skills,
                and abilities to ensure you have a great experience with our
                quizzes. This ensures what you learn will be accurate, and to
                the point as you move forward on your journey to mastering the
                topics of your choice.
              </p>
              <p className={styles.list_header}>
                Here is a sample list of their expertise:
              </p>
              <div className={styles.service_list}>
                <ul className="list-unstyled">
                  {serviceList.map((service, index) => {
                    return (
                      <li className="d-md-flex align-items-center" key={index}>
                        <svg
                          xmlns="
                            http://www.w3.org/2000/svg"
                          width="19"
                          height="18"
                          viewBox="0 0 19 18"
                          fill="none"
                        >
                          <path
                            d="M7.49998 12.251C7.30704 12.2362 7.12755 12.1465 6.99998 12.001L3.99998 9.00096C3.93314 8.861 3.91133 8.70377 3.93756 8.55091C3.96379 8.39805 4.03676 8.25707 4.14643 8.1474C4.2561 8.03774 4.39707 7.96476 4.54993 7.93854C4.70279 7.91231 4.86003 7.93412 4.99998 8.00096L7.46998 10.471L16 2.00096C16.1399 1.93412 16.2972 1.91231 16.45 1.93854C16.6029 1.96476 16.7439 2.03774 16.8535 2.14741C16.9632 2.25707 17.0362 2.39805 17.0624 2.55091C17.0886 2.70377 17.0668 2.861 17 3.00096L7.99998 12.001C7.87241 12.1465 7.69292 12.2362 7.49998 12.251Z"
                            fill="#374151"
                          />
                          <path
                            d="M9.00011 18.0008C7.39157 17.9982 5.81313 17.5646 4.42903 16.7451C3.04494 15.9255 1.90578 14.75 1.13011 13.3408C0.540493 12.2908 0.176085 11.1295 0.0601083 9.93082C-0.122911 8.1729 0.215718 6.4 1.03375 4.83328C1.85179 3.26657 3.11302 1.97539 4.66011 1.12082C5.71013 0.531203 6.87147 0.166795 8.07011 0.0508189C9.26426 -0.0776329 10.4721 0.0380434 11.6201 0.390819C11.7226 0.41129 11.8196 0.452944 11.905 0.513109C11.9905 0.573274 12.0624 0.650624 12.1162 0.7402C12.1699 0.829776 12.2044 0.929602 12.2174 1.03328C12.2303 1.13696 12.2215 1.2422 12.1914 1.34225C12.1613 1.44231 12.1106 1.53498 12.0427 1.61432C11.9747 1.69365 11.8909 1.75791 11.7966 1.80296C11.7023 1.84802 11.5997 1.87288 11.4952 1.87596C11.3908 1.87903 11.2869 1.86025 11.1901 1.82082C10.2187 1.52874 9.19878 1.43355 8.19011 1.54082C7.19311 1.64291 6.22706 1.94565 5.35011 2.43082C4.50524 2.89692 3.75825 3.52168 3.15011 4.27082C2.52397 5.03398 2.0564 5.91441 1.7748 6.86054C1.49319 7.80667 1.4032 8.79948 1.51011 9.78082C1.6122 10.7778 1.91494 11.7439 2.40011 12.6208C2.86621 13.4657 3.49096 14.2127 4.24011 14.8208C5.00327 15.447 5.8837 15.9145 6.82982 16.1961C7.77595 16.4777 8.76877 16.5677 9.75011 16.4608C10.7471 16.3587 11.7132 16.056 12.5901 15.5708C13.435 15.1047 14.182 14.48 14.7901 13.7308C15.4162 12.9677 15.8838 12.0872 16.1654 11.1411C16.447 10.195 16.537 9.20216 16.4301 8.22082C16.4203 8.11773 16.4308 8.01371 16.4612 7.9147C16.4915 7.81569 16.5411 7.72363 16.607 7.64377C16.6729 7.56391 16.7539 7.49782 16.8454 7.44928C16.9369 7.40073 17.037 7.37067 17.1401 7.36082C17.2432 7.35097 17.3472 7.36152 17.4462 7.39187C17.5452 7.42222 17.6373 7.47178 17.7172 7.53771C17.797 7.60364 17.8631 7.68465 17.9117 7.77612C17.9602 7.86759 17.9903 7.96773 18.0001 8.07082C18.1822 9.82979 17.8421 11.6034 17.0222 13.1702C16.2023 14.7371 14.9391 16.0276 13.3901 16.8808C12.3289 17.4946 11.1497 17.8763 9.93011 18.0008C9.62011 18.0008 9.30011 18.0008 9.00011 18.0008Z"
                            fill="#374151"
                          />
                        </svg>
                        <span className="ps-3 font_text medium ">
                          {service}
                        </span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </CardComponent>
      </div>
    </div>
  );
};

export default AboutUs;
