import React, { useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import styles from "./viewQuiz.module.scss";
import { Toast } from "primereact/toast";
import { imageUpload } from "../../../services/imageUpload";
import { Skeleton } from "primereact/skeleton";

const ViewDialogBox = ({
  visible,
  onHide,
  quizData,
  header,
  initialQuestionIndex,
  refreshQuizData,
}) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] =
    useState(initialQuestionIndex);
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);
  const fileUpdateRef = useRef(null);
  const toast = useRef(null);
  useEffect(() => {
    if (visible) {
      setCurrentQuestionIndex(initialQuestionIndex);
      setLoading(false);
    }
  }, [visible, initialQuestionIndex]);

  const goToPreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
      setLoading(false);
    }
  };

  const goToNextQuestion = () => {
    if (currentQuestionIndex < quizData?.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setLoading(false);
    }
  };
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  const updateImage = () => {
    fileUpdateRef.current.click();
  };

  const showSuccess = () => {
    toast?.current?.show({
      severity: "success",
      summary: "Image Uploaded Successfully",
      life: 3000,
    });
  };
  const showError = (msg) => {
    toast?.current?.show({
      severity: "error",
      summary: "Error",
      detail: msg,
      life: 3000,
    });
  };
  const handleChange = async () => {
    const file = fileInputRef.current.files[0];
    const quizId = quizData[currentQuestionIndex].quizId;
    const questionId = quizData[currentQuestionIndex].questionId;
    if (file) {
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append("imageFile", file);
        const response = await imageUpload(quizId, questionId, formData);
        showSuccess();
        await refreshQuizData();
      } catch (error) {
        showError(error?.message);
      } finally {
        setLoading(false);
      }
    }
  };
  const handleUpdate = async () => {
    const file = fileUpdateRef.current.files[0];
    const quizId = quizData[currentQuestionIndex].quizId;
    const questionId = quizData[currentQuestionIndex].questionId;
    if (file) {
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append("imageFile", file);
        const response = await imageUpload(quizId, questionId, formData);
        showSuccess();
        await refreshQuizData();
      } catch (error) {
        showError(error?.message);
      } finally {
        setLoading(false);
      }
    }
  };
  return (
    <div>
      <Toast ref={toast} />
      <Dialog
        className={styles.quizDialog}
        visible={visible}
        onHide={onHide}
        style={{ width: "65vw" }}
        header={header}
      >
        {quizData?.length > 0 && (
          <div>
            <div>
              <div className={`${styles.question_type} d-flex`}>
                <p>Answer Type:</p>
                <p
                  className={`${styles.question_type_answer} d-flex align-items-center ms-2`}
                >
                  {quizData[currentQuestionIndex]?.questionType ===
                  "RadioButton" ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M1.5 8C1.5 4.41136 4.41136 1.5 8 1.5C11.5886 1.5 14.5 4.41136 14.5 8C14.5 11.5886 11.5886 14.5 8 14.5C4.41136 14.5 1.5 11.5886 1.5 8ZM8 2.5C4.96364 2.5 2.5 4.96364 2.5 8C2.5 11.0364 4.96364 13.5 8 13.5C11.0364 13.5 13.5 11.0364 13.5 8C13.5 4.96364 11.0364 2.5 8 2.5Z"
                        fill="#15803D"
                      />
                      <path
                        d="M8 12.5C10.4853 12.5 12.5 10.4853 12.5 8C12.5 5.51472 10.4853 3.5 8 3.5C5.51472 3.5 3.5 5.51472 3.5 8C3.5 10.4853 5.51472 12.5 8 12.5Z"
                        fill="#0F172A"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M11.3216 5.11716C11.533 5.29477 11.5605 5.61016 11.3829 5.82161L7.18286 10.8216C7.08977 10.9324 6.95324 10.9975 6.80854 10.9999C6.66384 11.0024 6.52517 10.9421 6.42836 10.8345L4.62836 8.83449C4.44363 8.62924 4.46027 8.31309 4.66552 8.12836C4.87078 7.94363 5.18692 7.96027 5.37165 8.16553L6.78699 9.73813L10.6172 5.17842C10.7948 4.96697 11.1102 4.93955 11.3216 5.11716Z"
                        fill="#15803D"
                      />

                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M3.5 2.5C2.94772 2.5 2.5 2.94772 2.5 3.5V12.5C2.5 13.0523 2.94772 13.5 3.5 13.5H12.5C13.0523 13.5 13.5 13.0523 13.5 12.5V3.5C13.5 2.94772 13.0523 2.5 12.5 2.5H3.5ZM1.5 3.5C1.5 2.39543 2.39543 1.5 3.5 1.5H12.5C13.6046 1.5 14.5 2.39543 14.5 3.5V12.5C14.5 13.6046 13.6046 14.5 12.5 14.5H3.5C2.39543 14.5 1.5 13.6046 1.5 12.5V3.5Z"
                        fill="#15803D"
                      />
                    </svg>
                  )}
                  <span className="ps-1">
                    {quizData[currentQuestionIndex]?.questionType}
                  </span>
                </p>
              </div>
            </div>
            <p className={`${styles.quiz_question} font_text medium`}>
              <span className="me-1">
                Question #{currentQuestionIndex + 1}:
              </span>
              {quizData[currentQuestionIndex]?.question}
            </p>
            <div>
              {quizData[currentQuestionIndex]?.questionImage ? (
                <div className="d-flex align-items-center mb-4">
                  {loading === false && (
                    <div style={{ width: "35%" }}>
                      <img
                        src={quizData[currentQuestionIndex]?.questionImage}
                        className="w-100"
                      />
                    </div>
                  )}
                  <div className="ms-4">
                    {loading ? (
                      <Skeleton width="14rem" height="14rem" />
                    ) : (
                      <div>
                        <input
                          type="file"
                          id="updateImage"
                          name="updateImage"
                          className="d-none"
                          accept=".jpg, .jpeg, .png"
                          ref={fileUpdateRef}
                          onChange={handleUpdate}
                        />
                        <Button
                          className={`${styles.add_img}`}
                          onClick={updateImage}
                        >
                          Change Image
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div>
                  <input
                    type="file"
                    id="imageFile"
                    name="imageFile"
                    className="d-none"
                    accept=".jpg, .jpeg, .png"
                    ref={fileInputRef}
                    onChange={handleChange}
                  />
                  {loading ? (
                    <Skeleton width="14rem" height="14rem"></Skeleton>
                  ) : (
                    <Button
                      className={`${styles.add_img} mb-2`}
                      onClick={handleButtonClick}
                    >
                      Add Image
                    </Button>
                  )}
                </div>
              )}
            </div>
            <div>
              <p className={`${styles.answer} mb-1 mt-1`}>Answers:</p>
              <div className={`${styles.options}`}>
                <ol>
                  {quizData[currentQuestionIndex]?.answers?.map(
                    (answer, index) => (
                      <li key={index}>
                        <span>{answer?.answer}</span>
                        {answer?.isCorrect && (
                          <span className="ms-2">
                            <span className={`${styles.correct_answer}`}>
                              Answer
                            </span>
                            <span className={`${styles.option_point} ms-2`}>
                              {answer?.points} Points
                            </span>
                          </span>
                        )}
                      </li>
                    )
                  )}
                </ol>
              </div>
            </div>
          </div>
        )}
        <div className="mt-4 d-flex justify-content-between align-items-center">
          <div>
            <Button
              label="Previous"
              onClick={goToPreviousQuestion}
              disabled={currentQuestionIndex === 0}
              className={`${styles.prev_button}`}
            />
            <Button
              label="Next"
              onClick={goToNextQuestion}
              disabled={currentQuestionIndex === quizData?.length - 1}
              className={`${styles.next_button}`}
            />
          </div>
          <div className={`${styles.indexno}`}>
            {currentQuestionIndex + 1} / {quizData?.length}
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default ViewDialogBox;
