import { api } from "../api/api";
import { appInsights } from "../AppInsights";
export const getFirstQuestion = async (id, levelId) => {
  const getUserData = JSON.parse(localStorage.getItem("userData"));
  try {
    const headers =
      id == 1 && (!getUserData || getUserData == null)
        ? { "Skip-Token": "true" }
        : {};
    const response = await api.post(
      `api/Quizzes/${id}/Start?difficultyLevelId=${levelId}`,
      {},
      { headers }
    );
    return response?.data?.result || [];
  } catch (error) {
    appInsights.trackException("UI", { exception: error });
    throw error?.response?.data;
  }
};

export const fetchNextQuestion = async (
  id,
  questionId,
  userQuizId,
  answers
) => {
  const getUserData = JSON.parse(localStorage.getItem("userData"));
  try {
    const headers =
      id == 1 && (!getUserData || getUserData === null)
        ? { "Skip-Token": "true" }
        : {};
    const response = await api.post(
      `api/Quizzes/${id}/Question/${questionId}/Next?userQuizId=${userQuizId}`,
      answers,
      { headers }
    );
    return response?.data?.result || [];
  } catch (error) {
    appInsights.trackException("UI", { exception: error });
    throw new Error(error?.response?.data?.title);
  }
};

export const fetchPrevQuestion = async (
  id,
  questionId,
  userQuizId,
  answers
) => {
  const getUserData = JSON.parse(localStorage.getItem("userData"));
  try {
    const headers =
      id == 1 && (!getUserData || getUserData === null)
        ? { "Skip-Token": "true" }
        : {};
    const response = await api.post(
      `api/Quizzes/${id}/Question/${questionId}/Previous?userQuizId=${userQuizId}`,
      answers,
      { headers }
    );
    return response?.data?.result || [];
  } catch (error) {
    appInsights.trackException("UI", { exception: error });
    throw new Error(error?.response?.data?.title);
  }
};

export const submitQuiz = async (id, userQuizId, formattedTimeTaken) => {
  const getUserData = JSON.parse(localStorage.getItem("userData"));
  try {
    const headers =
      id == 1 && (!getUserData || getUserData === null)
        ? { "Skip-Token": "true" }
        : {};
    const response = await api.post(
      `api/Quizzes/${id}/Submit?userQuizId=${userQuizId}&endTime=${formattedTimeTaken}`,
      {},
      { headers }
    );
    return response?.data?.result || [];
  } catch (error) {
    appInsights.trackException("UI", { exception: error });
    throw new Error(error?.response?.data?.title);
  }
};
export const requestDownload = async (id, userQuizId) => {
  try {
    const response = await api.post(
      `api/Quizzes/CertificateRequest?quizId=${id}&userquizId=${userQuizId}`
    );
    return response?.data?.result || [];
  } catch (error) {
    throw error?.response?.data;
  }
};

export const getAnswers = async (id, userQuizId) => {
  const getUserData = JSON.parse(localStorage.getItem("userData"));
  try {
    const headers =
      id == 1 && (!getUserData || getUserData === null)
        ? { "Skip-Token": "true" }
        : {};
    const response = await api.get(
      `api/Quizzes/${id}/Result?userQuizId=${userQuizId}`,
      { headers }
    );
    return response?.data?.result || [];
  } catch (error) {
    appInsights.trackException("UI", { exception: error });
    throw new Error(error?.response?.data?.title);
  }
};

export const cancelQuiz = async (id, userQuizId) => {
  const getUserData = JSON.parse(localStorage.getItem("userData"));
  try {
    const headers =
      id == 1 && (!getUserData || getUserData === null)
        ? { "Skip-Token": "true" }
        : {};
    const response = await api.put(
      `api/Quizzes/${userQuizId}/Cancel?quizId=${id}`,{},
      { headers }
    );
    return response?.data?.result || [];
  } catch (error) {
    appInsights.trackException("UI", { exception: error });
    throw new Error(error?.response?.data?.title);
  }
};
