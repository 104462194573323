import Table from "../../components/Table/Table";
import { cartDataColums } from "../../components/Table-Columns/TableColumns";
import * as constants from "../../constants/tableConstants";
import { cartFooterTemplate } from "../../components/Table-Footer/TableFooter";
import { cartHeaderTemplate } from "../../components/Table-Header/TableHeader";
// import CartSkeleton from "../Cart/CartSkeleton";
import styles from "./Cart.module.scss";
import { useDispatch, useSelector } from "react-redux";
import * as actionTypes from "../../store/actions";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { appInsights } from "../../AppInsights";

const Cart = () => {
  appInsights.trackPageView("UI",{name: 'Cart.Jsx component visited' });
  const navigate = useNavigate();
  const { id } = useParams();
  const [processedData, setProcessedData] = useState([]);
  const [referralEmail, setReferralEmail] = useState("");
  const [referralEmailError, setReferralEmailError] = useState(false);
  const [total, setTotal] = useState(0);
  const [showDialog, setShowDialog] = useState(false);
  const [subTopicList, setSubTopicList] = useState([]);
  const [cartItems, setCartItems] = useState([])
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const heading = "Order Details";
  const subHeading = "Displaying all the quizzes you've selected for purchase.";
  const dispatch = useDispatch();
  const topicDetailsList = useSelector((state) => {
    return state.domainReducer.topicDetails;
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const cartData = JSON.parse(localStorage.getItem("ItemsAddedToCart"));
    setCartItems(cartData)
  }, []);
  useEffect(() => {
    if (cartItems?.length > 0) {
      const alteredData = processedDataForTable(cartItems);
     localStorage.setItem("cartItems", JSON.stringify(alteredData));
    }
  }, [cartItems]);


  useEffect(() => {
    if (id !== undefined && id !== null) {
      const data = JSON.parse(localStorage.getItem("paymentSummary"));
      processedDataForTable(data);
    }
  }, []);

  function processedDataForTable(topicDetailsList) {
    let processedData = processQuizzes(topicDetailsList);
    let processData = calculateExpiryAndPrice(processedData);
    let total = id !== undefined && id !== null ? JSON.parse(localStorage.getItem("paymentAmount")) : calculateTotal(processedData);
    setTotal(total);
    const bundleItem = processedData.filter((item) => item?.isBundle);
    const alaCarteItems = processedData.filter((item) => !item?.isBundle);
    const data = [...bundleItem, ...alaCarteItems];
    setProcessedData(data);
    return processedData;
  }

  function processQuizzes(data) {
    let result = [];
    data.forEach((topic) => {
      let bundle = topic.topicQuizList.find(
        (quiz) => quiz.isBundle && quiz.addToCart
      );
      if (bundle) {
        if (!bundle?.addedSubscription) {
          bundle.addedSubscription = { name: "One Month", code: 1 };
        }
        bundle.TopicName = bundle.topicName;
        bundle.children = topic.topicQuizList.filter((quiz) => quiz !== bundle);
        result.push(bundle);
      } else {
        topic.topicQuizList.forEach((quiz) => {
          if (quiz.addToCart) {
            quiz.TopicName = topic.topicQuizList[1]?.topicName;
            result.push(quiz);
          }
        });
      }
    });
    return result;
  }

  function calculateExpiryAndPrice(data) {
    let result = [];
    data?.map((item) => {
      if (item?.addedSubscription) {
        item.selectedPlanPrice =
          item?.addedSubscription?.code == 1
            ? item?.quizPricePerMonth
            : item?.addedSubscription?.code == 2
            ? item?.quizPricePerQuarter
            : item?.quizPricePerAnum;
      }
      let currentDate = new Date();
      let duration =
        item?.addedSubscription?.code == 1
          ? 1
          : item?.addedSubscription?.code == 2
          ? 3
          : 12;
      currentDate.setMonth(currentDate.getMonth() + duration);
      if (currentDate.getMonth() === 1 && currentDate.getDate() > 28) {
        currentDate.setDate(0); // Sets the day to the last day of the previous month
      }
      item.expiryDate = formateDate(currentDate);
    });
    return result.push(data);
  }
  function formateDate(currentDate) {
    let months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let date = new Date(currentDate);
    let formattedDate =
      date.getDate().toString().padStart(2, "0") +
      "-" +
      months[date.getMonth()] +
      "-" +
      date.getFullYear();
    return formattedDate;
  }

  function calculateTotal(data) {
    let result = 0;
    data.map((item) => {
      return (result = result + item?.selectedPlanPrice);
    });
    return result;
  }

  function deleteItem(data) {
    const cartItems = JSON.parse(localStorage.getItem('ItemsAddedToCart'));
    const newTopicDetails = cartItems?.map((item) => {
      if (item?.topicId == data?.topicId) {
        if (data?.isBundle) {
          const updatedTopicQuizList = item?.topicQuizList.map((subtopic) => {
            return {
              ...subtopic,
              addToCart: false,
              addedSubscription: { name: "One Month", code: 1 },
              selectedPlan: { name: "One Month", code: 1 },
              selectedPlanPrice: 100,
              addedToCartLabel: null,
            };
          });
          return { ...item, topicQuizList: updatedTopicQuizList };
        } else {
          const updatedTopicQuizList = item?.topicQuizList.map((subtopic) => {
            if (subtopic?.quizId === data?.quizId) {
              return {
                ...subtopic,
                addToCart: false,
                addedSubscription: { name: "One Month", code: 1 },
                selectedPlan: { name: "One Month", code: 1 },
                selectedPlanPrice: 100,
              };
            }
            return subtopic;
          });

          return { ...item, topicQuizList: updatedTopicQuizList };
        }
      }
      return item;
    });
    dispatch({
      type: actionTypes.GET_TOPICDETAILS,
      value: newTopicDetails || [],
    });
    localStorage.setItem("updatedTopics", JSON.stringify(newTopicDetails));
    localStorage.setItem("ItemsAddedToCart", JSON.stringify(newTopicDetails));
    const cartData = JSON.parse(localStorage.getItem("ItemsAddedToCart"));
    setCartItems(cartData)
  }
  const navigateBack = () => {
    if (id !== null && id !== undefined) {
      const data = JSON.parse(localStorage.getItem("paymentSummary"));
      navigate(`/domainpage/${data[0]?.topicId}`);
    } else if(processedData?.length > 0){
      navigate(`/domainpage/${processedData[processedData.length-1]?.topicId}`);
    }
    else if(topicDetailsList.length>0){
      navigate(`/domainpage/${topicDetailsList[0]?.topicId}`);
    }
    else{
      navigate("/")
    }
  };

  const navigateToQuizzes = () => {
    localStorage.removeItem('ItemsAddedToCart')
    localStorage.removeItem('cartItems')
    setShowConfirmationDialog(true)
  };

  const validateEmail = (value) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(value) && value.trim().length > 0) {
      setReferralEmailError(true);
      setReferralEmail(value)
    } else {
      setReferralEmailError(false);
    }
  };
  const navigateToPayment = () => {
    localStorage.setItem("referralEmail",referralEmail);
    navigate("/Payment");
  };
  return (
    <div className="container py-4">
      <div className="d-flex justify-content-between align-items-center">
        <h5 className={`fw-bold ${styles.title}`}>CART</h5>
        <div className="d-flex align-items-center">
          <Button
            label="Back"
            className={`${styles?.backBtn} bg-transparent`}
            icon="pi pi-arrow-left"
            text
            onClick={navigateBack}
          />
        </div>
      </div>
      <div className="pt-4">
        <div className={`bg-white ${styles.heading_container}`}>
          {id !== undefined && id !== null && (
            <div className={`${styles.success_message_blk}`}>
              <div className="d-flex align-items-center justify-content-start">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <g clip-path="url(#clip0_2178_93044)">
                    <path
                      d="M18.3334 9.23355V10.0002C18.3324 11.7972 17.7505 13.5458 16.6745 14.9851C15.5986 16.4244 14.0862 17.4773 12.3629 17.9868C10.6396 18.4963 8.7978 18.4351 7.11214 17.8124C5.42648 17.1896 3.98729 16.0386 3.00922 14.5311C2.03114 13.0236 1.56657 11.2403 1.68481 9.44714C1.80305 7.65402 2.49775 5.94715 3.66531 4.58111C4.83288 3.21506 6.41074 2.26303 8.16357 1.867C9.91641 1.47097 11.7503 1.65216 13.3918 2.38355"
                      stroke="#439F6E"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M18.3333 3.33301L10 11.6747L7.5 9.17467"
                      stroke="#439F6E"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2178_93044">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>{" "}
                <p className={`m-0 ${styles.success_messgae}`}>
                  Payment Approved
                </p>
              </div>
              <div className={`${styles.success_desc}`}>
                {" "}
                Congratulations! Your payment of{" "}
                <span className={`${styles.payment_amount}`}>${total}</span> has
                been successfully processed through Paypal with Order ID #{id}
              </div>
            </div>
          )}
          <h4 className={`${styles?.heading} font_text fw-semibold pb-2`}>
            {heading}
          </h4>
          {id == undefined && id == null && (
            <p className={`${styles?.subHeading} fw-normal pb-2`}>
              {subHeading}
            </p>
          )}
          {id !== undefined && id !== null && (
            <p className={`${styles?.subHeading} fw-normal pb-2`}>
              Below are the list of quizzes you've subscribed to. You can start
              taking quizzes now.
            </p>
          )}
          <div>
            <Table
              content={((id == undefined || id == null) && (JSON.parse(localStorage.getItem("ItemsAddedToCart"))?.length ==0 || JSON.parse(localStorage.getItem("ItemsAddedToCart"))?.length == null)) ?[] :  processedData}
              rowGroupMode="subheader"
              groupRowsBy="isBundle"
              columnsData={cartDataColums(
                styles,
                setShowDialog,
                setSubTopicList,
                deleteItem,
                id
              )}
              rows={constants.defaultRows}
              rowsPerPageOptions={constants.rowsPerPageOptions}
              paginator={false}
              scrollable={constants.scrollable}
              emptyMessage={constants.emptyMessage}
              tableStyle={constants.tableStyle}
              currentPageReportTemplate={constants.currentPageReportTemplate}
              paginatorTemplate={constants.paginatorTemplate}
              headerTemplate={(data) => cartHeaderTemplate(data)}
              footerTemplate={() =>
                cartFooterTemplate(
                  total,
                  styles,
                  referralEmailError,
                  id,
                  validateEmail,
                  navigateBack,
                  navigateToPayment,
                  navigateToQuizzes
                )
              }
            />
          </div>
          {showDialog && (
            <Dialog
              className={styles.cartDialog}
              header="Sub Topics"
              visible={showDialog}
              style={{ width: "50vw" }}
              onHide={() => {
                if (!showDialog) return;
                setShowDialog(false);
              }}
            >
              <div className="pt-3">
                {subTopicList?.length > 0 &&
                  subTopicList?.map((item) => {
                    if (item?.quizTypeId !== 1) {
                      return <p key={item?.quizId}>{item?.quizName}</p>;
                    }
                    if (item?.quizTypeId === 1 && subTopicList.length === 1) {
                      return <p>No À la carte Items available</p>;
                    }
                  })}
                {subTopicList?.length === 0 && (
                  <p>No À la carte Items available</p>
                )}
              </div>
            </Dialog>
          )}
          {showConfirmationDialog && (
            <Dialog
              header=""
              visible={showConfirmationDialog}
              style={{ width: "65vw", textAlign:'center' }}
              className={`${styles.confirmationDialog}`}
              onHide={() => {
                if (!showConfirmationDialog) return;
                setShowConfirmationDialog(false);
              }}
            >
              <h4 className={`m-0 ${styles.confirmation_message} font_text`}>
                You can start taking the quizzes now by clicking on "Take Quiz"
              </h4>
              <Button label="OK" className={`${styles.okBtn}`} onClick={navigateBack}></Button>
            </Dialog>
          )}
        </div>
      </div>
    </div>
  );
};

export default Cart;
