import React, { useEffect, useRef, useState } from "react";
import styles from "./editProfile.module.scss";
import { Field, Form, Formik, useFormik } from "formik";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { api } from "../../../api/api";
import * as YUP from "yup";
import { useLoginUserData } from "../../../store/login";
import ErrorMessage from "../../../components/ErrorMessage";
import { Toast } from "primereact/toast";
import {
  getProfileData,
  updateProfileData,
} from "../../../services/editProfile";
import { appInsights } from "../../../AppInsights";
const EditProfile = () => {
  appInsights.trackPageView("UI",{name: "EditProfile.Jsx component visited" });
  const toast = useRef(null);
  const [selectedTitle, setSelectedTitle] = useState(null);
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    userName: "",
    email: "",
    // editPassword: '****',
    phone: "",
  });
  const { setUserData, loginUserData } = useLoginUserData();
  const title = [
    { name: "Select", value: 1 },
    { name: "Mr", value: 2 },
    { name: "Mrs", value: 3 },
    { name: "Miss", value: 4 },
    { name: "Other", value: 5 },
  ];

  const validationSchema = YUP.object().shape({
    firstName: YUP.string()
      .required("First Name is required")
      .test(
        "no-only-spaces",
        "Field can't be empty",
        (value) => value && value.trim().length > 0
      )
      .matches(
        /^[a-zA-Z\s]*$/,
        "Field cannot contain numbers or special characters"
      )
      .max(50, "Name must be at most 50 characters"),
    lastName: YUP.string()
      .required("Last Name is required")
      .test(
        "no-only-spaces",
        "Field can't be empty",
        (value) => value && value.trim().length > 0
      )
      .matches(
        /^[a-zA-Z\s]*$/,
        "Field cannot contain numbers or special characters"
      )
      .max(50, "Name must be at most 50 characters"),
    userName: YUP.string()
      .required("Username is required")
      .min(6, "Please provide at least 6 characters")
      .max(12, "Username must be at most 12 characters")
      .matches(
        /^[a-zA-Z0-9]*$/,
        "Username can only contain letters and numbers"
      ),
    phone: YUP.string()
      .matches(/^[0-9]*$/, "Phone number must contain only numbers")
      .min(10, "Phone Number should be 10 digits")
      .nullable(),
  });

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await getProfileData();
        const user = response;
        let x = localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("userData", JSON.stringify(user));
        setInitialValues({
          firstName: user?.firstName,
          lastName: user?.lastName,
          userName: user?.userName,
          email: user?.email,
          // editPassword: '',
          phone: user?.phone,
          country: null,
        });

        setSelectedTitle(
          title.find((option) => option.value === user?.titleId)?.value || null
        );
      } catch (error) {
        appInsights.trackException("UI",{ exception: error });

        showError(error?.message);
      }
    };
    fetchUserData();
  }, []);

  const handleTitleChange = (e) => {
    setSelectedTitle(e.value);
  };
  const clearForm = async () => {
    // Clear the form fields
    setInitialValues({
      firstName: "",
      lastName: "",
      userName: "",
      phone: "",
    });
    setSelectedTitle(null);
    await new Promise((resolve) => setTimeout(resolve, 0));
    handleRest();
  };

  function handleRest() {
    const response = JSON.parse(localStorage.getItem("userData"));
    const user = response || {};
    setInitialValues({
      firstName: user.firstName || "",
      lastName: user.lastName || "",
      userName: user.userName || "",
      email: user.email || "",
      phone: user.phone || "",
      country: null,
    });
    setSelectedTitle(
      title.find((option) => option.value === user.titleId)?.value || null
    );
  }

  const handleSave = async (values) => {
    try {
      setLoading(true);
      const userData = {
        ...values,
        titleId: selectedTitle,
      };
      await updateProfileData(userData);
      const updatedUser = {
        firstName: values?.firstName,
        lastName: values?.lastName,
        userName: values?.userName,
        phone: values?.phone,
      };
      const mergedUserData = { ...loginUserData, ...updatedUser };
      setUserData(mergedUserData);

      const response = await getProfileData();
      const user = response;
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("userData", JSON.stringify(user));
      showSuccess();
    } catch (error) {
      appInsights.trackException("UI",{ exception: error });

      showError(error?.message);
    } finally {
      setLoading(false);
    }
  };
  const showSuccess = () => {
    toast?.current?.show({
      severity: "success",
      summary: "Data Saved Successfully",
      life: 3000,
    });
  };
  const showError = (msg) => {
    if (toast?.current) {
      toast?.current?.show({
        severity: "error",
        summary: "Error",
        detail: msg,
        life: 3000,
      });
    }
  };
  return (
    <div className={`${styles.editProfileContent}`}>
      <Toast ref={toast} />
      <h4 className="font_text semibold mt-1 mb-0">Account Information</h4>
      <p className="mb-0">
        Your Account Information is as below. You can update if necessary.
      </p>
      <div>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={handleSave}
          validateOnChange={false}
          validateOnBlur={true}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <Form name="editProfileForm" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-12 col-sm-4 col-lg-3">
                  <div>
                    <label htmlFor="title">
                      <span>Title</span>
                    </label>
                  </div>

                  <Dropdown
                    id="title"
                    value={selectedTitle}
                    onChange={handleTitleChange}
                    options={title}
                    optionLabel="name"
                    placeholder="Select"
                    className="w-100"
                  />
                </div>
                <div className="col-12 col-sm-4 col-lg-3">
                  <div>
                    <label htmlFor="firstName">
                      <span>First Name</span>
                      <span className={`${styles.asterisk} ps-1`}>*</span>
                    </label>
                  </div>
                  <Field
                    id="firstName"
                    name="firstName"
                    className="w-100"
                    value={values.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="off"
                  />
                  <div className="text-danger mt-1">
                    {touched.firstName && errors.firstName ? (
                      <ErrorMessage>{errors.firstName}</ErrorMessage>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-sm-4 col-lg-3">
                  <div>
                    <label htmlFor="lastName">
                      <span>Last Name</span>
                      <span className={`${styles.asterisk} ps-1`}>*</span>
                    </label>
                  </div>
                  <Field
                    id="lastName"
                    name="lastName"
                    className="w-100"
                    value={values.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="off"
                  />
                  <div className="text-danger mt-1">
                    {touched.lastName && errors.lastName ? (
                      <ErrorMessage>{errors.lastName}</ErrorMessage>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-sm-4 col-lg-3">
                  <div>
                    <label htmlFor="userName">
                      <span>User Name</span>
                      <span className={`${styles.asterisk} ps-1`}>*</span>
                    </label>
                  </div>
                  <Field
                    id="userName"
                    name="userName"
                    className="w-100"
                    value={values.userName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="off"
                  />
                  <div className="text-danger mt-1">
                    {touched.userName && errors.userName ? (
                      <ErrorMessage>{errors.userName}</ErrorMessage>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-sm-4 col-lg-3">
                  <div>
                    <label htmlFor="email">
                      <span>Email</span>
                      <span className={`${styles.asterisk} ps-1`}>*</span>
                    </label>
                  </div>
                  <Field
                    id="email"
                    name="email"
                    className="w-100"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="off"
                    disabled
                  />
                </div>
                <div className="col-12 col-sm-4 col-lg-3">
                  <div>
                    <label htmlFor="password">
                      <span>Password</span>
                      <span className={`${styles.asterisk} ps-1`}>*</span>
                    </label>
                  </div>
                  <Field
                    id="password"
                    name="password"
                    className="w-100"
                    placeholder="******"
                    // value={values.editPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="off"
                    disabled
                  />
                </div>
                <div className="col-12 col-sm-4 col-lg-3">
                  <div>
                    <label htmlFor="phone">
                      <span>Phone Number</span>
                    </label>
                  </div>
                  <Field
                    id="phone"
                    name="phone"
                    className="w-100"
                    value={values.phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="off"
                    maxLength={10}
                    placeholder="Country code not required"
                  />
                  <div className="text-danger mt-1">
                    {touched.phone && errors.phone ? (
                      <ErrorMessage>{errors.phone}</ErrorMessage>
                    ) : null}
                  </div>
                </div>
                <div className="col-12">
                  <div className="d-flex justify-content-end mt-4">
                    <div className={`${styles.savebtn} me-2`}>
                      <Button
                        type="submit"
                        className="d-flex justify-content-center"
                      >
                        {loading ? (
                          <div className={`${styles.loader_spinner}`}></div>
                        ) : (
                          <div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 18"
                              fill="none"
                            >
                              <path
                                d="M13 17.75H3C2.27065 17.75 1.57118 17.4603 1.05546 16.9445C0.539731 16.4288 0.25 15.7293 0.25 15V3C0.25 2.27065 0.539731 1.57118 1.05546 1.05546C1.57118 0.539731 2.27065 0.25 3 0.25H10.5C10.6988 0.250175 10.8895 0.329308 11.03 0.47L15.53 5C15.6707 5.14052 15.7498 5.33115 15.75 5.53V15C15.75 15.7293 15.4603 16.4288 14.9445 16.9445C14.4288 17.4603 13.7293 17.75 13 17.75ZM3 1.75C2.66848 1.75 2.35054 1.8817 2.11612 2.11612C1.8817 2.35054 1.75 2.66848 1.75 3V15C1.75 15.3315 1.8817 15.6495 2.11612 15.8839C2.35054 16.1183 2.66848 16.25 3 16.25H13C13.3315 16.25 13.6495 16.1183 13.8839 15.8839C14.1183 15.6495 14.25 15.3315 14.25 15V5.81L10.19 1.75H3Z"
                                fill="#134E4A"
                              />
                              <path
                                d="M12.75 17H11.25V10.75H4.75V17H3.25V10.5C3.25 10.1685 3.3817 9.85054 3.61612 9.61612C3.85054 9.3817 4.16848 9.25 4.5 9.25H11.5C11.8315 9.25 12.1495 9.3817 12.3839 9.61612C12.6183 9.85054 12.75 10.1685 12.75 10.5V17Z"
                                fill="#374151"
                              />
                              <path
                                d="M8.47004 5.75H4.53004C4.36063 5.74869 4.19314 5.71403 4.03713 5.64799C3.88112 5.58195 3.73965 5.48582 3.62079 5.36511C3.50193 5.24439 3.40801 5.10144 3.3444 4.94443C3.28078 4.78741 3.24872 4.61941 3.25004 4.45V1H4.75004V4.25H8.25004V1H9.75004V4.45C9.75136 4.61941 9.71929 4.78741 9.65568 4.94443C9.59207 5.10144 9.49815 5.24439 9.37929 5.36511C9.26043 5.48582 9.11896 5.58195 8.96295 5.64799C8.80694 5.71403 8.63945 5.74869 8.47004 5.75Z"
                                fill="#374151"
                              />
                            </svg>
                            <span className="ms-2">Save</span>
                          </div>
                        )}
                      </Button>
                    </div>
                    <div className={`${styles.cancelbtn}`}>
                      <Button type="button" onClick={(x) => clearForm(x)}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M18.75 6.81984L17.1802 5.25L12 10.4302L6.81984 5.25L5.25 6.81984L10.4302 12L5.25 17.1802L6.81984 18.75L12 13.5698L17.1802 18.75L18.75 17.1802L13.5698 12L18.75 6.81984Z"
                            fill="#14B8A6"
                          />
                        </svg>
                        <span className="ms-2">Cancel</span>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default EditProfile;
