import { api } from "../api/api";
import { appInsights } from "../AppInsights";
export const getSubTopics = async (topicId) => {
    try {
      const response = await api.get(`api/Topics?topicId=${topicId}`);
      return response?.data?.result || [];
    } catch (error) {
      appInsights.trackException("UI",{ exception: error });
      throw new Error(error?.response?.data?.title);
    }
  };
  