import React, { useState } from "react";
import OrderItems from "./orderItems";
import OrderDetailsDisplay from "./orderDetailsDisplay";
import { appInsights } from "../../../AppInsights";
const OrderDetails = () => {
  appInsights.trackPageView("UI",{name: 'orderDetails.Jsx component visited' });
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const handleOrderIdSelect = (orderId) => {
    setSelectedOrderId(orderId);
  };
  const handleBackToOrderItems = () => {
    setSelectedOrderId(null);
  };
  return (
    <div>
      {!selectedOrderId ? (
        <OrderItems onSelectOrderId={handleOrderIdSelect} />
      ) : (
        <OrderDetailsDisplay orderId={selectedOrderId} onBack={handleBackToOrderItems}/>
      )}
    </div>
  );
};

export default OrderDetails;
