import { Button } from "primereact/button";
import styles from "./Payment.module.scss";
import React, { useState, useEffect, useRef } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import { useDispatch } from "react-redux";
import * as actionTypes from "../../store/actions";
import { Skeleton } from "primereact/skeleton";
import { appInsights } from "../../AppInsights";
const Payment = () => {
  appInsights.trackPageView("UI",{name: 'Payment.Jsx component visited' });
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const [renderPaymentBtn, setRenderPaymentBtn] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");
  const [orderID, setOrderID] = useState(false);
  const [paymentSkeleton, setPaymentSkeleton] = useState(true);
  const toast = useRef(null);
  const dispatch = useDispatch();
  const paypalClientId =
    "AW9Q1fFvebnK1CqWrtYJbfoD4gqfNHP4WiCbg2eQ1vnJQ8i6Tm_mEie6U563yp5oIkHJv4hYTFjvAUpK";
  const userData = localStorage.getItem("authToken");
  const referralemail = localStorage.getItem("referralEmail");
  useEffect(() => {
    if (userData === undefined || userData === null) {
      navigate("/Login");
    } else {
      setRenderPaymentBtn(true);
    }
  }, [userData, navigate]);

  useEffect(()=>{
    setTimeout(()=>{
      setPaymentSkeleton(false)
    }, 2500)
  },[])

  // creates a paypal order
  const createOrder = async (data, actions) => {
    try {
      const cartItems = JSON.parse(localStorage.getItem("cartItems"));
      const orderObj = cartItems.map((item) => {
        const expiryDate = new Date(item?.expiryDate);
        const localYear = expiryDate.getFullYear();
        const localMonth = expiryDate.getMonth();
        const localDay = expiryDate.getDate();
        const localHours = expiryDate.getHours();
        const localMinutes = expiryDate.getMinutes();
        const localSeconds = expiryDate.getSeconds();
        const localExpiryDate = new Date(localYear, localMonth, localDay, localHours, localMinutes, localSeconds);
        const formattedExpiryDate = `${localExpiryDate.getFullYear()}-${String(localExpiryDate.getMonth() + 1).padStart(2, '0')}-${String(localExpiryDate.getDate()).padStart(2, '0')}T${String(localExpiryDate.getHours()).padStart(2, '0')}:${String(localExpiryDate.getMinutes()).padStart(2, '0')}:${String(localExpiryDate.getSeconds()).padStart(2, '0')}`;

        return {
          quizId: item?.quizId,
          numberOfMonths:
            item?.addedSubscription?.code === 1
              ? 1
              : item?.addedSubscription?.code === 2
              ? 3
              : 12,
          expiryDate: formattedExpiryDate,
          amount:
            item?.addedSubscription?.code === 1
              ? item?.quizPricePerMonth
              : item?.addedSubscription?.code === 2
              ? item?.quizPricePerQuarter
              : item?.quizPricePerAnum,
          promoCode: null,
        };
      });

      const token = localStorage.getItem("authToken");
      const headers = new Headers();
      headers.append("Authorization", `Bearer ${token}`);
      headers.append("Content-Type", "application/json");

      const options = {
        method: "POST",
        headers,
        body: JSON.stringify(orderObj),
      };

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/Payments/Order`,
        options
      );
      const data = await response.json();

      if (data?.statusCode === 201 || data?.statusCode === 200) {
        // return actions.order.create({
        //   purchase_units: [
        //     {
        //       amount: {
        //         value: data.result?.id, // or any other value needed by PayPal
        //       },
        //     },
        //   ],
        // });
        return data.result?.id;
      } else if (data?.status === 401) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Session Expired. Please login again",
          life: 3000,
        });
        return null;
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: data?.title,
          life: 3000,
        });
        return null;
      }
    } catch (error) {
      console.error("Error in create order function:", error);
      appInsights.trackException({
        exception: error,
        properties: {
          additionalInfo: "UI-Exception while getting Payment",
        },
        measurements: {
          severityLevel: 2
        }
      });
    }
  };

  // check Approval
  const onApprove = async (data, actions) => {
    if (data?.orderID) {
      try {
        const token = localStorage.getItem("authToken");
        const headers = new Headers();
        headers.append("Authorization", `Bearer ${token}`);
        headers.append("Content-Type", "application/json");

        const options = {
          method: "POST",
          headers,
        };

        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/Payments/Capture?orderId=${data?.orderID}&refferalEmail=${referralemail}`,
          options
        );
        const result = await response.json();
        const cartItems = JSON.parse(localStorage.getItem("updatedTopics"));
        localStorage.setItem("paymentSummary", JSON.stringify(cartItems));
        localStorage.setItem("paymentAmount", JSON.stringify(result?.result?.purchase_units[0]?.payments?.captures[0]?.amount?.value));
        localStorage.removeItem("cartItems");
        localStorage.removeItem("updatedTopics");
        localStorage.removeItem("ItemsAddedToCart");
        localStorage.removeItem("addToCart");
        dispatch({
          type: actionTypes.GET_TOPICDETAILS,
          value: [],
        });
        if (result) {
          navigate(`/cart/${result.result?.id}`);
        }
      } catch (error) {
        appInsights.trackException({
          exception: error,
          properties: {
            additionalInfo: "Exception while getting  payment",
          },
          measurements: {
            severityLevel: 1
          }
        });
  
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: error?.message,
          life: 3000,
        });
      }
    }
  };

  // capture likely error
  const onError = () => {
    setErrorMessage("An Error occurred with your payment");
  };

  useEffect(() => {
    if (success) {
      alert("Payment successful!!");
    }
  }, [success, orderID]);

  const navigateToCart = () => {
    navigate("/cart");
  };

  return (
    <div className="container py-4">
      <div className="d-flex justify-content-between align-items-center">
        <h5 className={`fw-bold ${styles.title}`}>CHECKOUT</h5>
        <div className="d-flex align-items-center">
          <Button
            label="Back"
            className={`${styles?.backBtn} bg-transparent`}
            icon="pi pi-arrow-left"
            text
            onClick={navigateToCart}
          />
        </div>
      </div>
      <div className="col-12 text-center pt-5 mt-4">
        <p>
          Please proceed and pay using your PayPal account or any debit/credit
          card of your choice.
        </p>
      </div>
      {renderPaymentBtn && !paymentSkeleton &&  (
        <PayPalScriptProvider options={{ "client-id": paypalClientId }}>
          <div className="d-flex align-items-center justify-content-center">
            <div
              className="col-12 p-3 w-50"
              style={{ width: "500px", marginBottom: "70px" }}
            >
              <PayPalButtons
                style={{ layout: "vertical" }}
                createOrder={createOrder}
                onApprove={onApprove}
                onError={onError}
              />
            </div>
          </div>
        </PayPalScriptProvider>
      )}
      {
        paymentSkeleton && (
          <div className={styles.skeleton_container}>
               <Skeleton
                    width="50%"
                    height={60}
                    className={`my-2 mx-1 ${styles.customSkeleton}`}
                  />
                  <Skeleton
                    width="50%"
                    height={60}
                    className={`my-2 mx-1 ${styles.customSkeleton}`}
                  />
          </div>
        )
      }
      <Toast ref={toast} />
    </div>
  );
};

export default Payment;
