import React, { useEffect, useRef, useState } from "react";
import styles from "./viewQuiz.module.scss";
import { Form, Formik } from "formik";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../../store/action-creators";
import { bindActionCreators } from "redux";
import * as YUP from "yup";
import ErrorMessage from "../../../components/ErrorMessage";
import Table from "../../../components/Table/Table";
import * as constants from "../../../constants/tableConstants";
import { quizQuestionColumns } from "../../../components/Table-Columns/TableColumns";
import {
  deleteQuizQuestion,
  fetchQuizQuestions,
} from "../../../services/uploadQuiz";
import ViewDialogBox from "./viewDialogBox";
import { getSubTopics } from "../../../services/subTopicList";
import { Toast } from "primereact/toast";
import { Skeleton } from "primereact/skeleton";
import { appInsights } from "../../../AppInsights";

const ViewQuiz = () => {
  
  appInsights.trackPageView("UI",{name: 'ViewQuiz.Jsx component visited' });

  const dispatch = useDispatch();
  const toast = useRef(null);
  const { getAllDomains } = bindActionCreators(actionCreators, dispatch);
  const domains = useSelector((state) => state.domainReducer.alldomains || []);
  const [selectedDomain, setSelectedDomain] = useState(null);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [selectedSubTopic, setSelectedSubTopic] = useState(null);
  const [data, setData] = useState("");
  const [displayTable, setDisplayTable] = useState(false);
  const [quizData, setQuizData] = useState(null);
  const [recordError, setRecordError] = useState("");
  const [loading, setLoading] = useState(false);
  const [subtopicLoading, setSubtopicLoading] = useState(false);
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [dropDownLoading, setDropDownLoading] = useState(true);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [topicSubTopicOptions, setTopicSubTopicOptions] = useState([
    { label: "Select Subtopic", id: null },
  ]);
  const initialValues = {
    domain: "",
    topic: "",
    subTopic: "",
  };
  const validationSchema = YUP.object({
    domain: YUP.number().required("Domain is required"),
    topic: YUP.mixed().when("domain", {
      is: (domain) => !domain,
      then: () => YUP.mixed().required("Please select a domain first"),
      otherwise: () => YUP.mixed().nullable(),
    }),
    subTopic: YUP.mixed().when(["topic", "domain"], {
      is: (topic, domain) => !domain && (!topic || topic === null),
      then: () => YUP.mixed().required("Please select a topic first"),
      otherwise: () => YUP.mixed().nullable(),
    }),
  });
  useEffect(() => {
    if (domains == undefined || domains == null || domains.length == 0) {
      getAllDomains();
    } else {
      setDropDownLoading(false);
    }
  }, [domains]);
  const topicOptions = selectedDomain ? domains?.flatMap((domain) =>
    domain?.subCategories?.map((subcategory) => ({
      label: subcategory?.name,
      value: subcategory?.id,
    }))
  ):[];
  const domainTopicOptions = [
    { label: "Select Topic", id: null },
    ...topicOptions,
  ];

  const fetchSubtopics = async (topicId) => {
    try {
      setSubtopicLoading(true);
      const response = await getSubTopics(topicId);
      const subTopicOptions = response?.map((subtopic) => ({
        label: subtopic?.name,
        value: subtopic?.id,
      }));
      setTopicSubTopicOptions([
        { label: "Select Subtopic", id: null },
        ...subTopicOptions,
      ]);
    } catch (error) {
      appInsights.trackException("UI",{ exception: error });
      setSelectedSubTopic(null);
      setTopicSubTopicOptions([{ label: "Select Subtopic", id: null }]);
      setSubtopicLoading(false);
    } finally{
      setSubtopicLoading(false);
    }
  };
  const handleTopicChange = (e, setFieldValue) => {
    const newValue = e.value;
    if (newValue?.id === null) {
      setSelectedTopic(null);
      setSelectedSubTopic(null);
      setTopicSubTopicOptions([{ label: "Select Subtopic", id: null }]);
      setFieldValue("topic", null);
      setFieldValue("subTopic", null);
    }
  };
  // const subTopicOptions = domains.flatMap((domain) =>
  //   domain.subCategories
  //     .filter((subcategory) => subcategory.id === selectedTopic)
  //     .flatMap((subcategory) =>
  //       subcategory.subCategories !== null &&
  //       Array.isArray(subcategory.subCategories)
  //         ? subcategory.subCategories.map((subtopic) => ({
  //             label: subtopic.name,
  //             value: subtopic.id,
  //           }))
  //         : []
  //     )
  // );
  // const topicSubTopicOptions = [
  //   { label: "Select Subtopic", id: null },
  //   ...subTopicOptions,
  // ];
  const handleSubmit = async () => {
    let categoryId;
    if (selectedSubTopic) {
      categoryId = selectedSubTopic;
    } else if (selectedTopic) {
      categoryId = selectedTopic;
    } else if (selectedDomain) {
      categoryId = selectedDomain;
    }
    try {
      setLoading(true);
      setShowSkeleton(true);
      const response = await fetchQuizQuestions(categoryId);
      const apiData = response?.map((item, index) => ({
        questionId: (index + 1).toString(),
        question: item?.question,
        view: (
          <div className="d-flex justify-content-center">
            <Button
              className={`${styles.dialog_view} p-0 me-3`}
              onClick={() => handleViewClick(index)}
            >
              <i
                className="pi pi-eye"
                style={{ fontSize: "1rem", color: "black" }}
              ></i>
            </Button>
            <Button
              className="p-0"
              onClick={() => deleteQuestion(categoryId, item?.questionId)}
            >
              <i
                className="pi pi-trash"
                style={{ fontSize: "1rem", color: "red" }}
              ></i>
            </Button>
          </div>
        ),
      }));
      setLoading(false);
      setData(apiData);
      setDisplayTable(true);
      setQuizData(response);
      setRecordError("");
    } catch (error) {
      appInsights.trackException("UI",{ exception: error });
      setRecordError(error?.message);
      setDisplayTable(false);
      setLoading(false);
      setShowSkeleton(false);
    } finally {
      setLoading(false);
      setShowSkeleton(false);
    }
  };
  const handleViewClick = (index) => {
    setCurrentQuestionIndex(index);
    setDialogVisible(true);
  };
  const deleteQuestion = async (categoryId, quizId) => {
    try {
      setShowSkeleton(true);
      const response = await deleteQuizQuestion(categoryId, quizId);
      showSuccess();
      await handleSubmit();
    } catch (error) {
      appInsights.trackException("UI",{ exception: error });
      showError(error?.message);
      setShowSkeleton(false);
    } finally {
      setShowSkeleton(false);
    }
  };
  const hideDialog = () => {
    setDialogVisible(false);
  };
  const refreshQuizData = async () => {
    let categoryId;
    if (selectedSubTopic) {
      categoryId = selectedSubTopic;
    } else if (selectedTopic) {
      categoryId = selectedTopic;
    } else if (selectedDomain) {
      categoryId = selectedDomain;
    }
    try {
      const response = await fetchQuizQuestions(categoryId);
      setQuizData(response);
    } catch (error) {
      appInsights.trackException("UI",{ exception: error });
      showError(error?.message);
    }
  };
  const showSuccess = () => {
    toast?.current?.show({
      severity: "success",
      summary: "Question deleted successfully",
      life: 3000,
    });
  };
  const showError = (msg) => {
    toast?.current?.show({
      severity: "error",
      summary: "Error",
      detail: msg,
      life: 3000,
    });
  };
  return (
    <div>
      <Toast ref={toast} />
      <div className={`${styles.select_option}`}>
        <p>Select Domain, Topic, and Subtopic from below</p>
      </div>
      <div className={`${styles.dropdownfields}`}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          validateOnChange={true}
          validateOnBlur={true}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            setFieldTouched,
            handleSubmit,
            isValid,
            dirty,
          }) => (
            <Form name="quizView" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-12 col-md-4">
                  <div>
                    <label htmlFor="domain">Domain</label>
                  </div>
                  {dropDownLoading ? (
                    <Dropdown
                      loading
                      placeholder="Select Domain"
                      className="w-100"
                    />
                  ) : (
                    <Dropdown
                      id="domain"
                      className="w-100"
                      placeholder="Select"
                      value={selectedDomain}
                      options={domains.map((domain) => ({
                        label: domain.name,
                        value: domain.id,
                      }))}
                      onChange={(e) => {
                        setSelectedDomain(e.value);
                        setSelectedTopic(null);
                        setFieldValue("domain", e.value);
                      }}
                    />
                  )}

                  {touched.domain && errors.domain ? (
                    <ErrorMessage>{errors.domain}</ErrorMessage>
                  ) : null}
                </div>
                <div className="col-12 col-md-4">
                  <div>
                    <label htmlFor="topic">Topic</label>
                  </div>
                  {dropDownLoading ? (
                    <Dropdown
                      loading
                      placeholder="Select Topic"
                      className="w-100"
                    />
                  ) : (
                    <Dropdown
                      id="topic"
                      className="w-100"
                      placeholder="Select"
                      value={selectedTopic}
                      options={domainTopicOptions}
                      onChange={(e) => {
                        handleTopicChange(e, setFieldValue);
                        if (e?.value?.id === null) {
                          setSelectedTopic(e?.value?.id);
                          setSelectedSubTopic(null);
                          setFieldValue("topic", e?.value?.id);
                          setFieldValue("subTopic", null);
                          setFieldTouched("topic", true);
                        } else {
                          setSelectedTopic(e.value);
                          setSelectedSubTopic(null);
                          setFieldValue("topic", e.value);
                          setFieldValue("subTopic", null);
                          setFieldTouched("topic", true);
                          fetchSubtopics(e.value);
                        }
                      }}
                    />
                  )}

                  {touched.topic && errors.topic ? (
                    <ErrorMessage>{errors.topic}</ErrorMessage>
                  ) : null}
                </div>
                <div className="col-12 col-md-4">
                  <div>
                    <label htmlFor="subTopic">Subtopic</label>
                  </div>
                  {dropDownLoading || subtopicLoading ? (
                    <Dropdown
                      loading
                      placeholder="Select Subtopic"
                      className="w-100"
                    />
                  ) : (
                    <Dropdown
                      id="subTopic"
                      className="w-100"
                      placeholder="Select"
                      value={selectedSubTopic}
                      options={topicSubTopicOptions}
                      onChange={(e) => {
                        if (e?.value?.id === null) {
                          setSelectedSubTopic(e?.value?.id);
                          setFieldValue("subTopic", e?.value?.id);
                          setFieldTouched("subTopic", true);
                        } else {
                          setSelectedSubTopic(e.value);
                          setFieldValue("subTopic", e.value);
                          setFieldTouched("subTopic", true);
                        }
                      }}
                    />
                  )}

                  {touched.subTopic && errors.subTopic ? (
                    <ErrorMessage>{errors.subTopic}</ErrorMessage>
                  ) : null}
                </div>
                <div className="col-12">
                  <div className={`${styles.searchbtn} mt-3`}>
                    <Button
                      type="submit"
                      className="d-flex justify-content-center"
                    >
                      {loading ? (
                        <div className={`${styles.loader_spinner}`}></div>
                      ) : (
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 18 18"
                            fill="none"
                          >
                            <path
                              d="M7.77024 15.3C6.28094 15.3 4.8251 14.8584 3.58679 14.031C2.34849 13.2036 1.38335 12.0275 0.813425 10.6516C0.243497 9.27567 0.0943779 7.76164 0.384925 6.30096C0.675471 4.84029 1.39263 3.49857 2.44572 2.44548C3.49881 1.39239 4.84053 0.675227 6.30121 0.38468C7.76188 0.0941338 9.27592 0.243253 10.6518 0.813181C12.0278 1.38311 13.2038 2.34825 14.0312 3.58655C14.8586 4.82485 15.3002 6.2807 15.3002 7.76999C15.3002 8.75885 15.1055 9.73802 14.727 10.6516C14.3486 11.5652 13.794 12.3953 13.0948 13.0945C12.3955 13.7937 11.5654 14.3484 10.6518 14.7268C9.73826 15.1052 8.75909 15.3 7.77024 15.3ZM7.77024 1.74999C6.58355 1.74999 5.42351 2.10189 4.43682 2.76118C3.45012 3.42046 2.68109 4.35754 2.22696 5.45389C1.77283 6.55025 1.65401 7.75665 1.88553 8.92054C2.11704 10.0844 2.68848 11.1535 3.5276 11.9926C4.36671 12.8317 5.43581 13.4032 6.5997 13.6347C7.76358 13.8662 8.96998 13.7474 10.0663 13.2933C11.1627 12.8391 12.0998 12.0701 12.7591 11.0834C13.4183 10.0967 13.7702 8.93668 13.7702 7.74999C13.7702 6.15869 13.1381 4.63257 12.0129 3.50735C10.8877 2.38213 9.36154 1.74999 7.77024 1.74999Z"
                              fill="#134E4A"
                            />
                            <path
                              d="M17.0005 17.75C16.9019 17.7505 16.8043 17.7312 16.7133 17.6935C16.6222 17.6557 16.5397 17.6001 16.4705 17.53L12.3405 13.4C12.208 13.2578 12.1358 13.0698 12.1393 12.8755C12.1427 12.6812 12.2214 12.4958 12.3588 12.3584C12.4962 12.221 12.6816 12.1422 12.8759 12.1388C13.0702 12.1354 13.2583 12.2075 13.4005 12.34L17.5305 16.47C17.6709 16.6106 17.7498 16.8012 17.7498 17C17.7498 17.1987 17.6709 17.3894 17.5305 17.53C17.4612 17.6001 17.3787 17.6557 17.2876 17.6935C17.1966 17.7312 17.099 17.7505 17.0005 17.75Z"
                              fill="#134E4A"
                            />
                          </svg>
                          <span className="ms-2">Search</span>
                        </div>
                      )}
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <div className="mt-2">
        {recordError && <ErrorMessage>{recordError}</ErrorMessage>}
      </div>
      {displayTable && (
        <div className={`mt-3 ${styles.questiontable}`}>
          {showSkeleton ? <Skeleton width="50%" className="mb-2" height="2rem" />:<h4 className="mt-5 mb-4">{quizData[0]?.categoryName}</h4>}
          {showSkeleton ? (
            <div>
              <Skeleton width="100%" className="mb-2" height="3rem" />
              <Skeleton width="100%" className="mb-2" height="3rem" />
              <Skeleton width="100%" className="mb-2" height="3rem" />
              <Skeleton width="100%" className="mb-2" height="3rem" />
              <Skeleton width="100%" className="mb-2" height="3rem" />
              <Skeleton width="100%" className="mb-2" height="3rem" />
            </div>
          ) : (
            <div>
              <Table
                content={data}
                rows={constants.defaultRows}
                rowsPerPageOptions={constants.rowsPerPageOptions}
                paginator={true}
                columnsData={quizQuestionColumns()}
                scrollable={constants.scrollable}
                emptyMessage={constants.emptyMessage}
                tableStyle={constants.tableStyle}
                currentPageReportTemplate={constants.currentPageReportTemplate}
                paginatorTemplate={constants.paginatorTemplate}
              ></Table>
            </div>
          )}
          <ViewDialogBox
            visible={dialogVisible}
            onHide={hideDialog}
            quizData={quizData}
            header={quizData[0]?.categoryName}
            initialQuestionIndex={currentQuestionIndex}
            refreshQuizData={refreshQuizData}
          />
        </div>
      )}
    </div>
  );
};

export default ViewQuiz;
