import React from "react";
import { Dialog } from "primereact/dialog";
import styles from "./LevelDialogBox.module.scss";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";

const LevelDialogBox = ({ visible, setVisible,quizId,topicName }) => {
  const navigate = useNavigate();
  const levelDefinition = [
    {
      levelId: 1,
      heading: "Introduction / Basics",
      body: "Focused on terminologies / basic terms / definitions",
    },
    {
      levelId: 2,
      heading: "Intermediate",
      body: "Tests knowledge / understanding of various sections / clauses of standard",
    },
    {
      levelId: 3,
      heading: "Advanced",
      body: "Evaluates detaild understanding of sections / clauses, sub clauses, and interrelationship between clauses",
    },
    {
      levelId: 4,
      heading: "Professional / Practitioner",
      body: "Tests the ability to apply the requirements of the standard / regulation to real life scenarios, and identification of conformance / compliance as applicable",
    },
  ];
  const handleLevelSelect = (levelId) => {
    navigate(`/Quiz/${quizId}/${levelId}`);
  };
  return (
    <div>
      <Dialog
      className={styles.difficultyDialog}
        header="Select Difficulty Level"
        visible={visible}
        style={{ width: "65vw" }}
        onHide={() => setVisible(false)}
      >
        <div>
          <p className={styles.levelheader}>You can take the <span><strong>{topicName}</strong></span>  Quiz at any of the levels below.</p>
          <div>
            {levelDefinition?.map((level) => {
              return (
                <div className={`${styles.leveldiv} mb-4`} key={level?.levelId}>
                  <div className="row">
                    <div className="col-12 col-sm-10">
                      {" "}
                      <div>
                        <h4 className="font_text semibold mb-4">
                          {level?.heading}
                        </h4>
                        <p className="mb-0">{level?.body}</p>
                      </div>
                    </div>
                    <div className="col-12 col-sm-2 d-md-flex align-items-center justify-content-center">
                      {" "}
                      <Button onClick={() => handleLevelSelect(level.levelId)}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="19"
                          height="17"
                          viewBox="0 0 19 17"
                          fill="none"
                        >
                          <path
                            d="M10.6667 16.3747C10.5517 16.3752 10.4378 16.3528 10.3317 16.3087C10.2255 16.2646 10.1292 16.1998 10.0484 16.118C9.88452 15.9539 9.79248 15.7315 9.79248 15.4997C9.79248 15.2678 9.88452 15.0454 10.0484 14.8813L16.43 8.49966L10.0484 2.11799C9.89382 1.95212 9.80967 1.73273 9.81367 1.50605C9.81767 1.27936 9.9095 1.06308 10.0698 0.902766C10.2301 0.742451 10.4464 0.65062 10.6731 0.646621C10.8998 0.642621 11.1192 0.726765 11.285 0.881325L18.285 7.88132C18.4489 8.04539 18.5409 8.26778 18.5409 8.49966C18.5409 8.73153 18.4489 8.95393 18.285 9.11799L11.285 16.118C11.2043 16.1998 11.108 16.2646 11.0018 16.3087C10.8956 16.3528 10.7817 16.3752 10.6667 16.3747V16.3747Z"
                            fill="white"
                          />

                          <path
                            d="M17.6668 9.375H1.3335C1.10143 9.375 0.878872 9.28281 0.714778 9.11872C0.550683 8.95462 0.458496 8.73206 0.458496 8.5C0.458496 8.26794 0.550683 8.04538 0.714778 7.88128C0.878872 7.71719 1.10143 7.625 1.3335 7.625H17.6668C17.8989 7.625 18.1215 7.71719 18.2855 7.88128C18.4496 8.04538 18.5418 8.26794 18.5418 8.5C18.5418 8.73206 18.4496 8.95462 18.2855 9.11872C18.1215 9.28281 17.8989 9.375 17.6668 9.375Z"
                            fill="white"
                          />
                        </svg>{" "}
                      </Button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default LevelDialogBox;
