import { useState, useEffect } from "react";
import styles from "./quizHistory.module.scss";
import { getQuizHistory, getStudentsList } from "../../../services/orderHistory";
import { Skeleton } from "primereact/skeleton";
import { quizHistoryColumns, quizHistoryDetailsColumn } from "../../../components/Table-Columns/TableColumns";
import * as constants from "../../../constants/tableConstants";
import Table from "../../../components/Table/Table";
import { Dialog } from "primereact/dialog";
import { QuizHistoryTableHeader } from "../../../components/Table-Header/TableHeader";
import { appInsights } from "../../../AppInsights";
import { getProfileData } from "../../../services/editProfile";
const QuizHistoryList = () => {
   
  appInsights.trackPageView("UI",{name: 'QuizHistory.Jsx component visited' });
  const [userData, setUserData] = useState(null);
  const [quizHistoryData, setQuizHistoryData] = useState([]);
  const [quizHistoryDataCopy, setQuizHistoryDataCopy] = useState([]);
  const [loader, setLoader] = useState(false);
  const [userDetailData, setUserDetailData] = useState([]);
  const [showuserDetailDialog, setShowUserDetailDoalog] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [studentsList, SetStudentsList] = useState([])
  // console.log(userData);

  const getUserData = async()=>{
    try{
      const response = await getProfileData();
      setUserData(response);
    } catch(error){
      console.log(error?.message); 
    }
  }
  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("userData"));
    if(data?.userId === undefined){
      getUserData().then(getUserData => {
        if (getUserData) {
          setUserData(getUserData);
        }
      });
    } else{
      setUserData(data);
    } 
  }, []);

  useEffect(() => {
    if (userData?.roleId == 3) {
      setLoader(true);
      fetchUserQuizHistory(userData?.userId);
    }
    else if(userData?.roleId == 1 || userData?.roleId == 2 || userData?.roleId == 4 || userData?.roleId == 5){
        setLoader(true);
        fetchStudentsList();
    }
  }, [userData]);

  const fetchUserQuizHistory = async (userId) => {
    try {
      const response = await getQuizHistory(userId);
      setQuizHistoryData(response);
      setQuizHistoryDataCopy(response)
      setLoader(false);
    } catch (error) {
       
 appInsights.trackException("UI",{ exception: error });
      setLoader(false);
      setQuizHistoryData([]);
      setQuizHistoryDataCopy([])
    }
  };

  const fetchStudentsList = async () =>{
    try{
        const response = await getStudentsList();
        SetStudentsList(response);
        setLoader(false);
    }
    catch(error){
       
 appInsights.trackException("UI",{ exception: error });
        setLoader(false);
        SetStudentsList([]);
    }
  }

  const onSearch = (event) => {
    const searchString = event.target.value?.trim();
    setSearchTerm(searchString);
    if(searchString === ""){
      setQuizHistoryData(quizHistoryDataCopy);
    }
    else {
      const data = quizHistoryDataCopy.filter((item) =>
        Object.values(item).some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase().includes(searchString.toLowerCase())
        )
      );
      setQuizHistoryData(data)
    }
  };

  const onStudentSelection = async (event) =>{
    try{
        setLoader(true);
        const data = event?.value?.label ? event?.value?.value : event?.value
        setSelectedStudent(data);
        const response = await getQuizHistory(event?.target?.value?.userId);
        setQuizHistoryData(response);
        setQuizHistoryDataCopy(response);
        setSearchTerm("");
        setLoader(false);
    }
    catch(error){

       
 appInsights.trackException("UI",{ exception: error });
        setLoader(false);
        setQuizHistoryData([]);
        setQuizHistoryDataCopy([]);
    }
  }
  return (
    <div className={`${styles.quizHistoryContent}`}>
      <h4 className="font_text semibold mt-1 mb-3">Quiz History</h4>
      {loader && (
        <div>
          <Skeleton width="100%" className="mb-2" height="3rem"></Skeleton>
          <Skeleton width="100%" className="mb-2" height="3rem"></Skeleton>
          <Skeleton width="100%" className="mb-2" height="3rem"></Skeleton>
          <Skeleton width="100%" className="mb-2" height="3rem"></Skeleton>
          <Skeleton width="100%" className="mb-2" height="3rem"></Skeleton>
          <Skeleton width="100%" className="mb-2" height="3rem"></Skeleton>
        </div>
      )}
       {!loader && <Table
          content={quizHistoryData}
          columnsData={quizHistoryColumns(
            styles,
            setShowUserDetailDoalog,
            setUserDetailData
          )}
          rows={constants.defaultRows}
          rowsPerPageOptions={constants.rowsPerPageOptions}
          paginator={constants.paginator}
          scrollable={constants.scrollable}
          emptyMessage={constants.emptyMessage}
          tableStyle={constants.tableStyle}
          currentPageReportTemplate={constants.currentPageReportTemplate}
          paginatorTemplate={constants.paginatorTemplate}
          header={() => QuizHistoryTableHeader({searchTerm, onSearch, selectedStudent, onStudentSelection, studentsList,userData})}
        />}
      {showuserDetailDialog && (
        <Dialog
          className={styles.quizHistoryDialog}
          header={userDetailData?.quizname}
          visible={showuserDetailDialog}
          style={{ width: "65vw" }}
          onHide={() => {
            setShowUserDetailDoalog(false);
          }}
        >
          <Table
            content={userDetailData?.userQuizAttempts}
            columnsData={quizHistoryDetailsColumn(userData?.roleId)}
            rows={constants.defaultRows}
            rowsPerPageOptions={constants.rowsPerPageOptions}
            paginator={constants.paginator}
            scrollable={constants.scrollable}
            emptyMessage={constants.emptyMessage}
            tableStyle={constants.tableStyle}
            currentPageReportTemplate={constants.currentPageReportTemplate}
            paginatorTemplate={constants.paginatorTemplate}
          />
        </Dialog>
      )}
    </div>
  );
};
export default QuizHistoryList;
