import React, { useEffect, useRef, useState } from "react";
import CardComponent from "../../components/Card/CardComponent";
import styles from "./contactUs.module.scss";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import { contactUs } from "../../services/contactus";
import { Toast } from "primereact/toast";
import * as Yup from "yup";
import { appInsights } from "../../AppInsights";

const initialValue = {
  fullName: "",
  phoneNumber: "",
  email: "",
  comment: "",
};

const ContactUs = () => {
  appInsights.trackPageView("UI",{name: 'ContactUs.Jsx component visited' });
  const toast = useRef(null);
  const [loading, setLoding] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required("Full Name is required") .test(
      "no-only-spaces",
      "Full name can't be empty",
      (value) => value && value.trim().length > 0
    )
    .max(50, "Full Name must be at most 50 characters")
    .matches(/^[a-zA-Z\s]*$/, "Full Name cannot contain numbers or special characters"),  
    phoneNumber: Yup.string()
      .matches(/^\d+$/, "Phone Number must be a number and can not contain space")
      .min(10, "Phone Number must be at least 10 digits")
      .max(12, "Phone Number must be at most 12 digits")
      .required("Phone Number is required"),
    email: Yup.string().required("Email is Required")
      .test('no-spaces', 'Email cannot contain spaces', value => !/\s/.test(value))
      .test('is-valid-email', 'Invalid email address', value => {
      if (value) {
        return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value);
      }
      return false;
    }),
    comment: Yup.string()
      .required("Comment is required")
      .test(
        "no-only-spaces",
        "Comment can't be empty",
        (value) => value && value?.trim()?.length > 0
      )
  });

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      submitInformation(values);
    },
  });

  const submitInformation = async (formbody) => {
    try {
      setLoding(true);
      const formData = new FormData();
      formData.append("fullName", formbody.fullName);
      formData.append("phoneNumber", formbody.phoneNumber);
      formData.append("email", formbody.email);
      formData.append("comment", formbody.comment);

      const result = await contactUs(formData);
      if (result) {
        setLoding(false);
        toast?.current?.show({
          severity: "success",
          summary: "Thank you for contacting us",
          detail: "Your message sent successfully",
          life: 4000,
        });
        clearForm1();
      }
    } catch (error) {
      setLoding(false);
      toast?.current?.show({
        severity: "error",
        summary: "Error",
        detail: error?.message,
        life: 4000,
      });
    }
  };
  const clearForm1 = () => {
    formik.resetForm();
  };

  const clearForm = (x) => {
    x.preventDefault();
    formik.resetForm();
  };

  return (
    <div className={styles?.container}>
      <Toast ref={toast} />
      <h5 className={`${styles.heading} mb-3`}>CONTACT</h5>
      <div className={styles.shadowcontainer}>
      <CardComponent>
        <div className={`${styles.container_block} row`}>
          <div
            className={`${styles.form_heading} font_text col-12 col-md-7 pe-0`}
          >
            <h5 className="font_text medium">
              We would love to hear from you. Please provide your contact
              information below and any questions / comments you might have.
            </h5>
            <form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className={`${styles.control_block} col-12`}>
                  <label htmlFor="fullName">
                    <span className={styles.formLabel}>
                      Full Name <span className={styles.star}>*</span>
                    </span>
                  </label>
                  <input
                    type="text"
                    id="fullName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.fullName}
                    className="form-control py-2"
                  />
                  {formik.errors.fullName && formik.touched.fullName && (
                    <small className={styles.errorMsg}>
                      {formik.errors.fullName}
                    </small>
                  )}
                </div>
                <div className={`${styles.control_block} col-12`}>
                  <label htmlFor="phoneNumber">
                    <span className={styles.formLabel}>
                      Phone Number <span className={styles.star}>*</span>
                    </span>
                  </label>
                  <input
                    type="text"
                    id="phoneNumber"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phoneNumber}
                    className="form-control py-2"
                  />
                  {formik.errors.phoneNumber && formik.touched.phoneNumber && (
                    <small className={styles.errorMsg}>
                {formik.errors.phoneNumber}
                    </small>
                  )}
                </div>
                <div className={`${styles.control_block} col-12`}>
                  <label htmlFor="email">
                    <span className={styles.formLabel}>
                      Email <span className={styles.star}>*</span>
                    </span>
                  </label>
                  <input
                    type="text"
                    id="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    className="form-control py-2"
                  />
                  {formik.errors.email && formik.touched.email && (
                    <small className={styles.errorMsg}>
                   {formik.errors.email}
                    </small>
                  )}
                </div>
                <div className={`${styles.control_block} col-12`}>
                  <label htmlFor="comment">
                    <span className={styles.formLabel}>
                      Comments <span className={styles.star}>*</span>
                    </span>
                  </label>
                  <textarea
                    id="comment"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.comment}
                    className="form-control"
                  />
                  {formik.errors.comment && formik.touched.comment && (
                    <small className={styles.errorMsg}>
                  {formik.errors.comment}
                    </small>
                  )}
                </div>
                <div
                  className={`${styles.control_block} ${styles.Btn_block} col-12 col-md-6 mt-3`}
                >
                  <div className="row">
                    <div className="col-3 col-md-7 col-xl-4">
                      <Button
                        type="Submit"
                        className={`${styles.nextBtn} ${styles.Btn} d-flex justify-content-center`}
                      >
                        {loading ? (
                          <span className={`${styles.loader} py-md-2`}></span>
                        ) : (
                          "Submit"
                        )}
                      </Button>
                    </div>
                    <div className="col-2 col-md-5 col-xl-6">
                      <Button
                        label="Reset"
                        onClick={($event)=> clearForm($event)}
                        className={`${styles.prevBtn} ${styles.Btn} ms-2`}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div
            className={`${styles.form_heading} font_text col-12 col-md-5 ps-md-5`}
          >
            <h5 className="font_text medium ps-3" style={{ fontSize: "22px" }}>
              Contact Information
            </h5>
            <div className={`${styles.address_block} row ps-3`}>
              <div className="col-12">
                <h5 className={styles.address_heading}>Address</h5>
                <p className={styles.address_subHeading}>KPI System, LLC.</p>
                <p className={styles.address_subHeading}>
                  3465 W. 111th. Loop, Unit A,
                </p>
                <p className={styles.address_subHeading}>
                  Westminster, CO 80031
                </p>
                <link href="#" />
              </div>
              <div className="col-12 py-3">
                <h5 className={`${styles.address_heading} mt-3`}>Call Us</h5>
                <p className={styles.address_subHeading}>
                Phone: <a href="tel:+1 (720) 257 9572">+1 (720) 257 9572</a>
                </p>
                {/* <p className={styles.address_subHeading}>Fax: (206) 623-4474</p>
                <p className={styles.address_subHeading}>
                  Phone: (123) 123-1234
                </p> */}
              </div>
              <div className="col-12">
                <h5 className={`${styles.address_heading} mt-3`}>Mail Us</h5>
                <a
                  href="mailto:info@quizandteach.com"
                  className="text-decoration-none"
                >
                  info@quizandteach.com
                </a>
              </div>
            </div>
          </div>
        </div>
      </CardComponent>
      </div>
     
    </div>
  );
};

export default ContactUs;
