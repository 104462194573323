import { api } from "../api/api";
import { appInsights } from "../AppInsights";

export const contactUs = async (formdata) => {
  try {
    const response = await api.post(`/api/ContactUs`, formdata, {
      headers: {
        "Content-Type": "application/json",
        "Skip-Token": "true",
      },
    });
    return response?.data?.result || [];
  } catch (error) {
    appInsights.trackException("UI", { exception: error });
    throw new Error(error?.response?.data?.title);
  }
};
