import React, { useEffect } from "react";
import styles from "./policyAndTerms.module.scss";
import CardComponent from "../../components/Card/CardComponent";
import { privacyData } from "./privacyTermsData";

const PrivacyPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <div className={styles.container}>
      <h5 className={`${styles.policyheading} mb-3`}>PRIVACY POLICY</h5>
      <div className={styles.shadowcontainer}>
        <CardComponent>
          <div className={`${styles.policy_container_block} row`}>
            <div>
              {privacyData.map((privacydata, index) => {
                return (
                  <div key={index}>
                    <ol start={index + 1} className={`ps-3 mb-0 ${styles.custom_list}`}>
                      <li className="pt-3">
                        <strong>{privacydata?.header}</strong>
                      </li>
                    </ol>
                    <p className="mb-0">{privacydata?.desc}</p>
                    {privacydata?.subdesc?.length > 0 && (
                      <ol type="a" className="ps-5">
                        {privacydata?.subdesc.map((sub, subIndex) => (
                          <li key={subIndex} className={`pt-2 ${styles.subList}`}>{sub}</li>
                        ))}
                      </ol>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </CardComponent>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
