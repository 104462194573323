import React, { useEffect, useState } from "react";
import DomainHead from "../domain-header/domainHead";
import TopicList from "../topic-list/topicList";
import styles from "./domainPage.module.scss";
import BundleCard from "../topicCards/bundleCard";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../store/action-creators";
import { Skeleton } from "primereact/skeleton";
import { useParams } from 'react-router-dom';
import * as actionTypes from "../../store/actions";
import { useNavigate } from "react-router-dom";

const DomainPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [selectedTopicId, setSelectedTopicId] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const [domains, setDomains] = useState([]);
  const [showSkeleton, setShowSkeleton] = useState(true);
  const dispatch = useDispatch();
  const { getAllDomains } = bindActionCreators(actionCreators, dispatch);
  const domainList = useSelector((state) => {
    return state.domainReducer.alldomains;
  });


  //Fetch details to update the parameters when changing the topic id
  const topicDetails =  useSelector((state) => {
    return state.domainReducer.topicDetails;
  });

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (!domainList) {
      fetchDomains();
    }
  }, [domainList]);
  useEffect(() => {
    setDomains(domainList);
    if (domainList?.length > 0) {
      setSelectedTopicId(id);
      setShowSkeleton(false)
    }
  }, [domainList]);
  const fetchDomains = async () => {
    try {
        getAllDomains();
    } catch (error) {}
  };

  const handleTopicSelect = (topic) => {
    if (domains?.length > 0) {
      setSelectedTopicId(topic.id);
      //Logic to revert the parameters to there original state on topic change
      const newTopicDetails = topicDetails.map((item, index) => {
        if (item?.topicId == topic.id) {
          const updatedTopicQuizList = item?.topicQuizList.map((subTopic) => {
            if (!subTopic?.addToCart) {
              return { ...subTopic, addedSubscription: {name: "One Month", code: 1} };
            }
            return subTopic;
          });
          return { ...item, topicQuizList: updatedTopicQuizList };
        }
        return item;
      });
      dispatch({
        type: actionTypes.GET_TOPICDETAILS,
        value: newTopicDetails|| []
      })
      localStorage.setItem("updatedTopics", JSON.stringify(newTopicDetails))
    } else {
      //print no data for topic detail view
    }
    navigate(`/domainpage/${topic.id}`);
  };

  return (
    <div>
      {showSkeleton && (
        <div className="row py-3 px-lg-5 mx-4">
          <div className="col-12 col-md-3">
            <div className="d-flex justify-content-between">
              <Skeleton
                width="16rem"
                height={30}
                className={`my-2 mx-1 ${styles.customSkeleton}`}
              />
              {/* <Skeleton
                width="20rem"
                height={30}
                className={`my-2 mx-1 ${styles.customSkeleton}`}
              /> */}
            </div>
            {Array.from({
              length: 10,
            }).map((_, index) => (
              <Skeleton
                key={index}
                height="3rem"
                className={styles.customSkeleton}
              ></Skeleton>
            ))}
          </div>
          <div className="col-12 col-md-9 pt-5">
            <Skeleton height={35} className={styles.customSkeleton}></Skeleton>
            <Skeleton
              height={50}
              className={`mt-2 ${styles.customSkeleton}`}
            ></Skeleton>
            <Skeleton
              height={45}
              className={`mt-3 mb-2 ${styles.customSkeleton}`}
            ></Skeleton>
            <Skeleton
              height="15rem"
              className={`mt-4 ${styles.customSkeleton}`}
            ></Skeleton>
          </div>
        </div>
      )}
      {domains?.length > 0 && (
        <section className={`container-fluid ${styles.domain_page} py-4`}>
          <div className="px-lg-5 mx-4">
            <DomainHead heading={domains[0]?.name} />
            <div className={`${isMobile ? styles.customRow : 'row'} py-3`}>
              <div className={`${isMobile ? '' : 'col-12 col-md-3'}`}>
                <TopicList
                  topics={domains[0]?.subCategories}
                  onTopicSelect={handleTopicSelect}
                  selectedTopicId={selectedTopicId}
                />
              </div>
              <div className={`${isMobile ? '' : 'col-12 col-md-9'} ps-2 pe-0 pt-md-0`}>
                <BundleCard topicId={selectedTopicId} />
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default DomainPage;
