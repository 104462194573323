export const sortData = (param, arr) => {
  if (arr?.length > 0) {
    return arr.sort((a, b) => a[param].localeCompare(b[param]));
  } else {
    return [];
  }
};

export const sortNumericData = (param, arr) => {
  if (arr?.length > 0) {
    return arr.sort((a, b) => a[param] - b[param]);
  } else {
    return [];
  }
};
