import { Formik, Form, Field } from "formik";
import * as YUP from "yup";
import styles from './Login.module.scss'
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useLoginUserData } from "../../store/login";
import { api } from '../../api/api';
import { useEffect, useState} from "react";
import { setaxiosheader } from "../../api/api-client-factory";
import { useDispatch } from "react-redux";
import * as actionTypes from "../../store/actions";
import loginApi from "../../services/loginApi";

import { appInsights } from "../../AppInsights";
 

const initialValue = {
  userNameOrEmail: '',
  password: '',
};
const Login = () => {
  appInsights.trackPageView("UI",{name: 'Login.Jsx component visited' });
  const { setUserData } = useLoginUserData();
  const navigate = useNavigate();
  const [generalError, setGeneralError] = useState('');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const commonValidation = YUP.mixed().nullable();
  const validationSchema = YUP.object().shape({
    userNameOrEmail: YUP.string()
      .required("Email or Username is required")
      .test(
        "is-email-or-username",
        "Please enter a valid email address or username",
        value => {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          const usernameRegex = /^[a-zA-Z0-9_!@#$%^&*()]{3,20}$/;
          return emailRegex.test(value) || usernameRegex.test(value);
        }
      ),
    password: commonValidation.required("Password is required"),
  });

  const handleLogin = async (credentials, { setFieldError }) => {
    try {
      setLoading(true);
      const response = await loginApi(credentials);
      const token = response.token;
      const user = response?.userName;
      handleReducerData()
      localStorage.setItem('authToken', token);
      setaxiosheader(`Bearer ${token}`);
      localStorage.setItem('userData', JSON.stringify(response));
      setUserData(response);
      navigate(from,{replace:true})
      // navigate("/")
       // return response.data;
    } catch (error) {
      appInsights.trackException("UI",{ exception: error });
      setLoading(false)
      setGeneralError(error.message);
      setFieldError("userNameOrEmail", " ");
      setFieldError("password", " ");
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      const storedUserData = localStorage.getItem('userData');
      if (storedUserData) {
        setUserData(JSON.parse(storedUserData));
      }
    }
    // if (token) {
    //   setUserData({ token });
    // }
  }, []);

  const handleReducerData = () => {
    const data = JSON.parse(localStorage.getItem('updatedTopics'));
    // if(data == null || data == undefined){
    //   dispatch({
    //     type: actionTypes.GET_TOPICDETAILS,
    //     value: []
    //   })
    // }
    dispatch({
      type: actionTypes.GET_TOPICDETAILS,
      value: []
    })
  }

  return (
    <div className="container-fluid">
      <div className="row justify-content-center">
        <div className="col-12 col-md-6 col-lg-4 px-lg-0" style={{marginBottom:'100px'}}>
          <Formik
            initialValues={initialValue}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              handleLogin(values, actions)
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <Form onSubmit={handleSubmit}>
                <div className="container py-3 px-3 my-5 bg-white">
                  <div className="row justify-content-md-center px-1">
                    <div className="col-12">
                      <div className={`${styles.login_header} mb-4`}>
                        <h4>LOGIN</h4>
                        <p>Welcome back ! Please Login your account.</p>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className={`${styles.login_fields}`}>
                        <div className="mb-2">
                          <label htmlFor="userNameOrEmail">
                            <span>Username or Email</span>
                          </label>
                        </div>
                        <Field
                          id="userNameOrEmail"
                          name="userNameOrEmail"
                          className="w-100 p-2 mb-1"
                          placeholder="John@email.com"
                          autoComplete="on"
                          value={values?.userNameOrEmail}
                          onChange={(e)=>{handleChange(e);setGeneralError("")}}
                          onBlur={handleBlur}
                        />
                        {touched.userNameOrEmail && errors.userNameOrEmail ? (
                          <div className="text-danger mb-1">{errors.userNameOrEmail}</div>
                        ) : null}
                        <div className="mb-2 mt-3">
                          <label htmlFor="password">
                            <span>Password</span>
                          </label>
                        </div>
                        <Field
                          type="password"
                          id="password"
                          name="password"
                          className="w-100 p-2 mb-1"
                          autoComplete="off"
                          value={values?.password}
                          onChange={(e)=>{handleChange(e);setGeneralError("")}}
                          onBlur={handleBlur}
                        />
                        {touched.password && errors.password ? (
                          <div className="text-danger">{errors.password}</div>
                        ) : null}
                      </div>
                    </div>
                    {generalError && (
                        <div className="text-danger">
                          {generalError}
                        </div>)}
                    <div className="col-12">
                      <div className="text-end mt-3">
                        <p className={`${styles.forgot_password}`}><NavLink to="/ForgotPassword">Forgot Password</NavLink></p>
                      </div>
                    </div>
                   
                    <div className={`col-12 pt-2 ${styles.login_btn}`}>
                      <button type="submit" className="btn w-100 d-flex justify-content-center align-items-center" disabled={loading}>
                        {loading ? <span className={`${styles.loader} py-2`}></span> : 'Login'}
                      </button>
                    </div>
                    <div className="mt-4">
                      <p className={`${styles.new_user} mb-0`}>New User? <span className="ms-1"><NavLink to="/Signup" className="text-black">Sign Up</NavLink></span></p>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default Login;
