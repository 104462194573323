import React, { useEffect } from "react";
import CardComponent from "../../components/Card/CardComponent";
import styles from "./policyAndTerms.module.scss";
import { termsdata } from "./privacyTermsData";
import { NavLink } from "react-router-dom";

const Terms = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={styles.container}>
      <h5 className={`${styles.policyheading} mb-3`}>TERMS AND CONDITIONS</h5>
      <div className={styles.shadowcontainer}>
        <CardComponent>
          <div className={`${styles.policy_container_block} row`}>
            <p>
              Welcome to{" "}
              <NavLink to="/" style={{ color: "#495057" }}>
                www.quizandteach.com
              </NavLink>{" "}
              This is a website owned by KPI System LLC(the Company).
            </p>
            <p className="mb-0">
              These Terms & Conditions (T&Cs) govern your use of {" "}
              <NavLink to="/" style={{ color: "#495057" }}>
                www.quizandteach.com
              </NavLink>{" "}
              (website) and any services offered by the Company through this
              website. By accessing or using the website or services, you agree
              to comply with and be bound by these T&Cs. If you do not agree to
              these T&Cs please do not use the website or any services offered
              therefrom.
            </p>
            <div>
              {termsdata.map((terms, index) => {
                return (
                  <div key={index}>
                    <ol
                      start={index + 1}
                      className={`ps-3 mb-1 mt-4 ${styles.custom_list}`}
                    >
                      <li>
                        <strong>{terms?.header}</strong>
                      </li>
                    </ol>
                    {terms?.desc &&
                      terms?.desc.map((descItem, descIndex) => {
                        const mainIndex = `${index + 1}.${descIndex + 1}`;
                        return (
                          <div key={descIndex}>
                            <p>
                              {mainIndex} {descItem.text}
                            </p>
                            {descItem?.subdesc && (
                              <ul>
                                {descItem?.subdesc.map(
                                  (subDescItem, subDescIndex) => {
                                    const subIndex = `${mainIndex}.${
                                      subDescIndex + 1
                                    }`;
                                    return (
                                      <p
                                        key={subDescIndex}
                                        className="mb-1"
                                        style={{ fontSize: "14px" }}
                                      >{`${subIndex} ${subDescItem}`}</p>
                                    );
                                  }
                                )}
                              </ul>
                            )}
                          </div>
                        );
                      })}
                  </div>
                );
              })}
            </div>
            <p>
              By using our website and / or services, you acknowledge that you
              have read, understood, and agreed to these T&Cs.
            </p>
          </div>
        </CardComponent>
      </div>
    </div>
  );
};

export default Terms;
