import React, { useEffect, useState } from "react";
import styles from "./orderDetails.module.scss";
import Table from "../../../components/Table/Table";
import * as constants from "../../../constants/tableConstants";
import { orderDetailsColums } from "../../../components/Table-Columns/TableColumns";
import { getStudentsList, orderDetails } from "../../../services/orderHistory";
import { Skeleton } from "primereact/skeleton";
import { appInsights } from "../../../AppInsights";
import { getProfileData } from "../../../services/editProfile";
import { QuizHistoryTableHeader } from "../../../components/Table-Header/TableHeader";

const OrderItems = ({ onSelectOrderId }) => {
  appInsights.trackPageView("UI", { name: "orderItems.Jsx component visited" });
  const [userData, setUserData] = useState(null);
  const [orderData, setOrderData] = useState([]);
  const [orderDataCopy, setOrderDataCopy] = useState([]);
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [studentsList, SetStudentsList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedStudent, setSelectedStudent] = useState(null);

  const getUserData = async () => {
    try {
      const response = await getProfileData();
      setUserData(response);
    } catch (error) {
      console.log(error?.message);
    }
  };
  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("userData"));
    if (data?.userId === undefined) {
      getUserData().then((getUserData) => {
        if (getUserData) {
          setUserData(getUserData);
        }
      });
    } else {
      setUserData(data);
    }
  }, []);
  useEffect(() => {
    if (userData?.roleId == 3) {
      setShowSkeleton(true);
      fetchOrderdetails(userData?.userId);
    } else if (
      userData?.roleId == 1 ||
      userData?.roleId == 2 ||
      userData?.roleId == 4 ||
      userData?.roleId == 5
    ) {
      setShowSkeleton(true);
      fetchStudentsList();
    }
  }, [userData]);
  // useEffect(() => {
  //   setShowSkeleton(true);
  //   const fetchOrders = async () => {
  //     try {
  //       const response = await orderDetails();
  //       setOrderData(response);
  //       setShowSkeleton(false);
  //     } catch (error) {
  //       setShowSkeleton(true);
  //       console.error(error?.message);
  //     }
  //   };
  //   fetchOrders();
  // }, []);
  const fetchOrderdetails = async (userId) => {
    try {
      const response = await orderDetails(userId);
      setOrderData(response);
      setOrderDataCopy(response);
      setShowSkeleton(false);
    } catch (error) {
      appInsights.trackException("UI", { exception: error });
      setShowSkeleton(false);
      setOrderData([]);
      setOrderDataCopy([]);
    }
  };
  const fetchStudentsList = async () => {
    try {
      const response = await getStudentsList();
      SetStudentsList(response);
      setShowSkeleton(false);
    } catch (error) {
      appInsights.trackException("UI", { exception: error });
      setShowSkeleton(false);
      SetStudentsList([]);
    }
  };
  const onSearch = (event) => {
    const searchString = event.target.value?.trim();
    setSearchTerm(searchString);

    if (searchString === "") {
      setOrderData(orderDataCopy);
    } else {
      const data = orderDataCopy?.filter((item) =>
        Object.values(item).some((value) => {
          if (typeof value !== "string") {
            value = String(value);
          }
          return value.toLowerCase().includes(searchString.toLowerCase());
        })
      );
      setOrderData(data);
    }
  };

  const onStudentSelection = async (event) => {
    try {
      setShowSkeleton(true);
      const data = event?.value?.label ? event?.value?.value : event?.value;
      setSelectedStudent(data);
      const response = await orderDetails(event?.target?.value?.userId);
      setOrderData(response);
      setOrderDataCopy(response);
      setSearchTerm("");
      setShowSkeleton(false);
    } catch (error) {
      appInsights.trackException("UI", { exception: error });
      setShowSkeleton(false);
      setOrderData([]);
      setOrderDataCopy([]);
    }
  };
  return (
    <div className={`${styles.orderdetails_content}`}>
      <h4 className="font_text semibold mt-1 mb-3">Order History</h4>
      {showSkeleton ? (
        <div>
          <Skeleton width="100%" className="mb-2" height="3rem"></Skeleton>
          <Skeleton width="100%" className="mb-2" height="3rem"></Skeleton>
          <Skeleton width="100%" className="mb-2" height="3rem"></Skeleton>
          <Skeleton width="100%" className="mb-2" height="3rem"></Skeleton>
          <Skeleton width="100%" className="mb-2" height="3rem"></Skeleton>
        </div>
      ) : (
        <div>
          <Table
            content={orderData}
            rows={constants.defaultRows}
            rowsPerPageOptions={constants.rowsPerPageOptions}
            paginator={true}
            columnsData={orderDetailsColums(onSelectOrderId)}
            scrollable={constants.scrollable}
            emptyMessage={constants.emptyMessage}
            tableStyle={constants.tableStyle}
            currentPageReportTemplate={constants.currentPageReportTemplate}
            paginatorTemplate={constants.paginatorTemplate}
            header={() =>
              QuizHistoryTableHeader({
                searchTerm,
                onSearch,
                selectedStudent,
                onStudentSelection,
                studentsList,
                userData,
              })
            }
          ></Table>
        </div>
      )}
    </div>
  );
};

export default OrderItems;
