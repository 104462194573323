import React, { useEffect, useRef, useState } from "react";
import styles from "./myQuiz.module.scss";
import Table from "../../../components/Table/Table";
import * as constants from "../../../constants/tableConstants";
import { myQuizzesColumns } from "../../../components/Table-Columns/TableColumns";
import { getMyQuizDetails } from "../../../services/orderHistory";
import { Button } from "primereact/button";
import { cartHeaderTemplate } from "../../../components/Table-Header/TableHeader";
import { Dialog } from "primereact/dialog";
import LevelDialogBox from "../../../components/LevelDialogBox/LevelDialogBox";
import { useNavigate } from "react-router-dom";
import { Skeleton } from "primereact/skeleton";
import { getProfileData } from "../../../services/editProfile";
import { Toast } from "primereact/toast";
import { appInsights } from "../../../AppInsights";


const MyQuiz = () => {
   
  appInsights.trackPageView("UI",{name: 'Myquiz.Jsx component visited' });
  const toast = useRef(null);
  const [quizData, setQuizData] = useState([]);
  const [userData,setUserData]= useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [subTopicList, setSubTopicList] = useState([]);
  const [visible, setVisible] = useState(false);
  const [selectedQuizId, setSelectedQuizId] = useState(null);
  const [selectedTopicName, setSelectedTopicName] = useState("");
  const [showSkeleton, setShowSkeleton] = useState(false);
  const getUserDetails = JSON.parse(localStorage.getItem("userData"));
  const userId = getUserDetails?.userId || userData?.userId;
  const navigate = useNavigate();

  useEffect(()=>{
    const getUserId=async()=>{
      try{
        const response=await getProfileData();
        setUserData(response)
      }catch(error){
        showError(error?.message); 
      }
    }
    getUserId();
  },[])
  useEffect(() => {
    const fetchQuizData = async () => {
      try {
        setShowSkeleton(true);
        const response = await getMyQuizDetails(userId);
        const { bundles, alaCarteItems } = response;
        const combinedQuizData = [...(bundles || []), ...(alaCarteItems || [])];
        setQuizData(combinedQuizData);
      } catch (error) {
        showError(error?.message);
      } finally {
        setShowSkeleton(false);
      }
    };
    if(userData){
      fetchQuizData();
    } 
  }, []);
  const handleSubtopicQuiz = (subtopicquizId) => {
    navigate(`/Quiz/${subtopicquizId}`);
  };
  const formatISODate = (isoDateString) => {
    const date = new Date(isoDateString);
    
    const day = String(date.getDate()).padStart(2, '0');
    const monthAbbreviated = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
    
    return `${day}-${monthAbbreviated}-${year}`;
  };
  const myquizData = quizData?.map((quiz) => {
    const subtopics = Array.isArray(quiz?.subTopics)
      ? quiz.subTopics.map((subTopic) => ({
          names: subTopic?.names,
          quizId: subTopic?.quizId,
        }))
      : typeof quiz?.subTopics === "string"
      ? [quiz.subTopics]
      : [];

    const handleTakeQuizClick = (quiz) => {
      if (quiz?.isBundle) {
        setSelectedQuizId(quiz?.quizId);
        setSubTopicList(subtopics);
        setSelectedTopicName(quiz?.topic);
        setVisible(true);
      } else {
        navigate(`/Quiz/${quiz.quizId}`);
      }
    };
    return {
      quizdomain: quiz?.domainName,
      quiztopic: quiz?.topic,
      subtopics: subtopics,
      subscription: quiz?.subscription,
      subscriptiondate: formatISODate(quiz?.subscriptionDate),
      quizattempt: quiz?.totalQuizAttempts,
      isBundle: quiz?.isBundle || false,
      children: quiz?.children || [],
      takequizbtn: (
        <Button
          className={styles.myquizbtn}
          onClick={() => handleTakeQuizClick(quiz)}
        >
          Take Quiz
        </Button>
      ),
    };
  });
  const showError = (msg) => {
    toast?.current?.show({
      severity: "error",
      summary: "Error",
      detail: msg,
      life: 3000,
    });
  };
  return (
    <div className={styles.myQuizContent}>
      <Toast ref={toast} />
      <h4 className="font_text semibold mt-1 mb-3">My Quizzes</h4>
      <div>
        {showSkeleton ? (
          <div>
            <Skeleton width="100%" className="mb-2" height="3rem"></Skeleton>
            <Skeleton width="100%" className="mb-2" height="3rem"></Skeleton>
            <Skeleton width="100%" className="mb-2" height="3rem"></Skeleton>
            <Skeleton width="100%" className="mb-2" height="3rem"></Skeleton>
            <Skeleton width="100%" className="mb-2" height="3rem"></Skeleton>
            <Skeleton width="100%" className="mb-2" height="3rem"></Skeleton>
          </div>
        ) : (
          <div>
            <Table
              content={myquizData}
              rowGroupMode="subheader"
              groupRowsBy="isBundle"
              rows={constants.defaultRows}
              rowsPerPageOptions={constants.rowsPerPageOptions}
              paginator={true}
              columnsData={myQuizzesColumns(
                styles,
                setShowDialog,
                setSubTopicList
              )}
              scrollable={constants.scrollable}
              emptyMessage={constants.emptyMessage}
              tableStyle={constants.tableStyle}
              currentPageReportTemplate={constants.currentPageReportTemplate}
              paginatorTemplate={constants.paginatorTemplate}
              headerTemplate={(data) => cartHeaderTemplate(data)}
            ></Table>
          </div>
        )}
      </div>
      {showDialog && (
        <Dialog
        className={styles.myQuizDialog}
          header="Sub Topics"
          visible={showDialog}
          style={{width: "50vw" }}
          onHide={() => setShowDialog(false)}
        >
          <div className="pt-3">
            {subTopicList?.length > 0 &&
              subTopicList.map((subtopic, index) => (
                <div className="d-lg-flex align-items-center justify-content-between mb-3">
                  <p key={index} className="mb-0" style={{ fontSize: "15px" }}>
                    {subtopic?.names}
                  </p>
                  <Button
                    className={styles.myquizbtn}
                    onClick={() => handleSubtopicQuiz(subtopic?.quizId)}
                  >
                    Take Quiz
                  </Button>
                </div>
              ))}
            {subTopicList?.length === 0 && <p>No À la carte Items available</p>}
          </div>
        </Dialog>
      )}
      <LevelDialogBox
        visible={visible}
        setVisible={setVisible}
        quizId={selectedQuizId}
        topicName={selectedTopicName}
      />
    </div>
  );
};

export default MyQuiz;
