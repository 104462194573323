import React, { useEffect } from "react";
import styles from "./testimonials.module.scss";
import CardComponent from "../../components/Card/CardComponent";
import { testimonialData } from "./testimonialData";
import { appInsights } from "../../AppInsights";
const Testimonials = () => {
  appInsights.trackPageView("UI",{name: 'Testimonials.Jsx component visited' });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={styles.container}>
      <h5 className={`${styles.testimonialheading} mb-3`}>TESTIMONIALS</h5>
      <div className={styles.shadowcontainer}>
        <CardComponent>
          <div className={`${styles.testimonial_container_block} px-md-4 px-2 row`}>
            {testimonialData.map((testimony) => {
              return (
                <div className={`mb-4 ${styles.testimonydiv}`}>
                  <div className="px-1 pt-4 pb-3">
                    <p className={`mb-4 ${styles.testimony}`}>{testimony?.testimonyDesc}</p>
                    <p className={`mb-0 ${styles.testimony}`}>{testimony?.testimonyName}</p>
                    <p className={`mb-0 ${styles.testimonydesignation}`}>{testimony?.testimonyDesignation}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </CardComponent>
      </div>
    </div>
  );
};

export default Testimonials;
