import React from "react";
import Domain from "../homepage/domain/domain";
import styles from "./quizzes.module.scss";

const Quizzes = () => {
  return (
    <div className={styles.container}>
      <div>
        <h5 className={`${styles.quizzesheading} mb-3`}>DOMAINS</h5>
        <div className={styles.shadowcontainer}>
          <Domain />
        </div>
      </div>
    </div>
  );
};

export default Quizzes;
