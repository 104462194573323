const TagComponent=(props) =>{
    const {icon, content, className} = props;

    return (
        <div className={`d-flex ${className}`}>
            {icon}
            {content}
        </div>
    )
}

export default TagComponent;