import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./container/homepage/home";
import DomainPage from "./container/domainpage/domainPage";
import Login from "./container/login/Login";
import PageNotFound from "./container/pageNotFound/PageNotFound";
import Cart from "./container/Cart/cart";
import Result from "./container/Quiz/Result";
import Quiz from "./container/Quiz/Quiz";
import ForgotPassword from "./container/forgotPassword/forgotPassword";
import Payment from "./container/payment/Payment";
import SignUp from "./container/signUp/signUp";
import MyAccount from "./container/myAccount/accountTabContainer/myAccount";
import AdminTab from "./container/admin/adminTabContainer/adminTab";
import ContactUs from "./container/contact-us/contactUs";
import AboutUs from "./container/about-us/aboutUs";
import Faqs from "./container/faqs/faqs";
import PrivacyPolicy from "./container/policy-and-terms/privacyPolicy";
import Terms from "./container/policy-and-terms/terms";
import Testimonials from "./container/testimonials/testimonials";
import AddAdmin from "./container/admin/addAdmin/addAdmin";
import RequireAuth from "./components/RequireAuth";
import Quizzes from "./container/quizzesTab/quizzes";

const Routing = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/domainpage/:id" element={<DomainPage />} />
    
        <Route path="/ForgotPassword" element={<ForgotPassword />} />
        <Route path="/Cart" element={<Cart />} />
        <Route path="/Quiz/:id" element={<Quiz />} />
        <Route path="/Quiz/:id/:levelId?" element={<Quiz />} />
        <Route path="/Cart/:id" element={<Cart />} />
        <Route path="/Payment" element={<Payment />} />
        <Route path="/Result/:userQuizId/:quizId" element={<Result />} />
        <Route path="/aboutUs" element={<AboutUs />} />
        <Route path="/quizzes" element={<Quizzes/>}/>
        <Route path="/faqs" element={<Faqs />} />
        <Route path="/testimonials" element={<Testimonials />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="*" element={<PageNotFound />} />
        {/*below are used for sign and log in  */}
        <Route element={<RequireAuth allowedRoles={[null]}/>}>
        <Route path="/Signup" element={<SignUp />} />
        <Route path="/Login" element={<Login />} />
        </Route>
        {/* below are admin page   role id = 3 for user , 1 for super admin , 4 for primary admin , 5 for secondary adim  */}
        <Route element={<RequireAuth allowedRoles={[1,4]}/>}>
        <Route path="/Addadmin" element={<AddAdmin/>} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[1,4,5]}/>}>
        <Route path="/QuizUpload" element={<AdminTab />} />
        </Route>
         {/* below are admin page role id = 3 for user , 1 for super admin , 4 for primary admin , 5 for secondary adim  */}
<Route element={<RequireAuth allowedRoles={[5,1,3,4]}/>}>
<Route path="/MyAccount" element={<MyAccount />} />
</Route>

      </Routes>
    </div>
  );
};

export default Routing;
