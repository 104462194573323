import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { sortData } from "../../constants/DataSort";

export const cartHeaderTemplate = (props) => {
  const { isBundle } = props;
  return (
    <div>
      {isBundle ? <span>Bundle Items</span> : <span>À la carte Items</span>}
    </div>
  );
};

export const QuizHistoryTableHeader = (props) => {
  return (
    <div className="row">
      {props?.userData?.roleId !== 3 &&<div className="col-12 col-md-5">
        <Dropdown
        filter
          value={props?.selectedStudent}
          onChange={props?.onStudentSelection}
          options={sortData("email", props?.studentsList)}
          optionLabel="email"
          placeholder="Select Student"
         className="w-100 mb-3"
        />
      </div>}
      <div className={`col-12 col-md-5 ${props?.userData?.roleId == 3 ?'offset-md-7' : 'offset-md-2'}`}>
        <div className="p-inputgroup flex-1">
          <span className="p-inputgroup-addon">
            <i className="pi pi-search"></i>
          </span>
          <InputText
            value={props?.searchTerm}
            onChange={props?.onSearch}
            placeholder="Search"
            className="form-control px-3 py-2 border-0"
          />
        </div>
      </div>
    </div>
  );
};
