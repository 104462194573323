import React, { useState } from "react";
import { Paginator } from 'primereact/paginator';

const PaginationComponent = ({ first, rows, totalRecords, onPageChange,className }) => {
    return (
        <div>
           <Paginator first={first} rows={rows} totalRecords={totalRecords} onPageChange={onPageChange} className={className}/>
        </div>
    )
}
export default PaginationComponent;