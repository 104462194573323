import React from 'react'

const ErrorMessage = ({ children }) => {
    return (
        <div>
            <div className="mb-2 d-flex">
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 20 20" fill="none" style={{marginTop:"2px"}}>
                    <g clipPath="url(#clip0_2437_57305)">
                        <path d="M9.9974 18.3346C14.5998 18.3346 18.3307 14.6037 18.3307 10.0013C18.3307 5.39893 14.5998 1.66797 9.9974 1.66797C5.39502 1.66797 1.66406 5.39893 1.66406 10.0013C1.66406 14.6037 5.39502 18.3346 9.9974 18.3346Z" stroke="#F93232" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M10 6.66797V10.0013" stroke="#F93232" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M10 13.332H10.0083" stroke="#F93232" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </g>
                    <defs>
                        <clipPath id="clip0_2437_57305">
                            <rect width="20" height="20" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
                <div className='ms-2' style={{fontSize:"15px"}}>
                    {children}
                </div>
            </div></div>
    )
}

export default ErrorMessage