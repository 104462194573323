import { api } from "../api/api";
import { appInsights } from "../AppInsights";
export const quizUpload = async (formData, categoryId, isFreeQuiz) => {
  try {
    const response = await api.post(
      `/api/Quizzes/Upload?categoryId=${categoryId}&isFreeQuiz=${isFreeQuiz}`,
      formData,
      {
        headers: {
          //   Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response?.data || [];
  } catch (error) {
    appInsights.trackException("UI",{ exception: error });
    throw error?.response?.data;
  }
};

export const quizEdit = async (formData, categoryId, isFreeQuiz) => {
  try {
    const response = await api.put(
      `/api/Quizzes/Update?categoryId=${categoryId}`,
      formData,
      {
        headers: {
          //   Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response?.data?.result || [];
  } catch (error) {
    appInsights.trackException("UI",{ exception: error });
    throw error?.response?.data;
  }
};

export const fetchQuizQuestions = async (categoryId) => {
  try {
    const response = await api.get(
      `/api/Quizzes/ViewQuestions?categoryId=${categoryId}`
    );
    return response?.data?.result || [];
  } catch (error) {
    throw new Error(error?.response?.data?.title);
  }
};

export const deleteQuizQuestion = async (quizId,questionId) => {
  try {
    const response = await api.put(
      `api/Quizzes/QuestionInActive?questionId=${questionId}&quizId=${quizId}`
    );
    return response?.data?.result || [];
  } catch (error) {
    appInsights.trackException("UI",{ exception: error });
    throw new Error(error?.response?.data?.title);
  }
};
