import { api } from "../api/api";
import * as actionTypes from "./actions";

export const getAllDomains = () => async (dispatch, getState) => {
  try {
    const response = await api.get(`/api/Topics`);
    dispatch({
      type: actionTypes.GET_DOMAINS_ALL,
      value: response?.data?.result || [],
    });
  } catch (error) {
    console.log(error);
  }
};

export const getAllDomainsHome = () => async (dispatch, getState) => {
  try {
    const response = await api.get(`/api/Topics/Home`);
    dispatch({
      type: actionTypes.GET_DOMAINS_ALL_HOME,
      value: response?.data?.result || [],
    });
  } catch (error) {
    console.log(error);
  }
};

export const getTopicDetails =
  ({ params }) =>
  async (dispatch, getState) => {
    try {
      const response = await api.get(`api/Topics/${params?.topicId}/quizzes`,{ headers: { 'Skip-Token': 'true' }});
      const localStorageData = JSON.parse(
        localStorage.getItem("updatedTopics")
      );
      if (
        getState()?.domainReducer?.topicDetails?.length === 0 &&
        localStorageData != null && localStorageData?.length > 0
      ) {
        //during reload of appalication
        const updatedDataAfterMerge = getUpdatedResponse(
          response.data.result,
          localStorageData,
          false
        );

        dispatch({
          type: actionTypes.GET_TOPICDETAILS,
          value: [...updatedDataAfterMerge] || [],
        });
      } else if (getState()?.domainReducer?.topicDetails?.length === 0) {
        //for the initial call
        dispatch({
          type: actionTypes.GET_TOPICDETAILS,
          value:
            [
              {
                topicId: params?.topicId,
                topicQuizList: response?.data?.result,
              },
            ] || [],
        });
      } else {
        //when the data is already exists
        dispatch({
          type: actionTypes.GET_TOPICDETAILS,
          value:
            [
              ...getState()?.domainReducer?.topicDetails,
              {
                topicId: params?.topicId,
                topicQuizList: response?.data?.result,
              },
            ] || [],
        });
      }
      // localStorage.setItem("visitedTopicsOriginal", JSON.stringify(getState()?.domainReducer?.topicDetails))
    } catch (error) {
      console.log(error);
    }
  };

const getUpdatedResponse = (response, localStorageData, isloggedIn) => {
  console.log(response, localStorageData);
  if (response.length > 0) {
    let isThere = false;
    const topicId = response[0]?.topicId;
    const filterr = localStorageData.filter((item) => item?.topicId == topicId);
    const filteredData = response.map((item) => {
      const data = filterr[0]?.topicQuizList.filter(
        (obj) => obj.quizId == item.quizId
      )[0];
      if (data?.addToCart && !item?.isPurchased) {
        item.addToCart = data?.addToCart;
      }
      if (item?.isPurchased) {
        item.addToCart = false;
      }
      if (data?.addedSubscription) {
        item.addedSubscription = data?.addedSubscription;
      }
      if (data?.addedToCartLabel) {
        item.addedToCartLabel = data?.addedToCartLabel;
      }
      if (data?.selectedPlan) {
        item.selectedPlan = data?.selectedPlan;
      }
      if (data?.selectedPlanPrice) {
        item.selectedPlanPrice = data?.selectedPlanPrice;
      }
      if (data?.isExpanded) {
        item.isExpanded = data?.isExpanded;
      }
      return item;
    });
    const data = localStorageData.map((item) => {
      if (item?.topicId == filteredData[0]?.topicId) {
        item.topicQuizList = filteredData;
        isThere = true;
      }
      return item;
    });
    if(!isThere){
      data.push({topicId:filteredData[0]?.topicId, topicQuizList:filteredData })
    }
    return data;
  }
};

export const getTopicDetailsAfterLogin =
  ({ params }) =>
  async (dispatch, getState) => {
    try {
      const response = await api.get(
        `api/Quizzes/${params?.topicId}/user/Purchased`
      );
      const localStorageData = JSON.parse(
        localStorage.getItem("updatedTopics")
      );
      if (
        getState()?.domainReducer?.topicDetails?.length === 0 &&
        localStorageData != null
      ) {
        //during reload of appalication and login
        const updatedDataAfterMerge = getUpdatedResponse(
          response.data.result,
          localStorageData,
          true
        );
        dispatch({
          type: actionTypes.GET_TOPICDETAILS,
          value: [...updatedDataAfterMerge] || [],
        });
      } else if (getState()?.domainReducer?.topicDetails?.length === 0) {
        //for the initial call
        dispatch({
          type: actionTypes.GET_TOPICDETAILS,
          value:
            [
              {
                topicId: params?.topicId,
                topicQuizList: response?.data?.result,
              },
            ] || [],
        });
      } else {
        //when the data is already exists
        dispatch({
          type: actionTypes.GET_TOPICDETAILS,
          value:
            [
              ...getState()?.domainReducer?.topicDetails,
              {
                topicId: params?.topicId,
                topicQuizList: response?.data?.result,
              },
            ] || [],
        });
      }

      handleCart(response.data.result);
    } catch (error) {
      console.log(error);
    }
  };

const handleCart = async () => {
  const response = await api.get(`api/Quizzes/user/Purchased`);
  const purchasedItems = response?.data?.result;
  const cartItems = JSON.parse(localStorage.getItem("ItemsAddedToCart"));
  console.log("response from api of cart items", response?.data?.result);
  console.log(
    "response localstorage",
    JSON.parse(localStorage.getItem("ItemsAddedToCart"))
  );

  if (cartItems?.length > 0) {
    let isExist = false;
    let arr = [];
    for (let i = 0; i <= cartItems.length - 1; i++) {
      isExist = false; // Reset isExist flag for each cart item
      for (let j = 0; j <= purchasedItems.length - 1; j++) {
        if (
          cartItems[i]?.topicId == purchasedItems[j]?.topicId &&
          purchasedItems[j]?.isBundle
        ) {
          isExist = true;
          break; // No need to check further if a bundle is found
        } else if (
          cartItems[i]?.topicId == purchasedItems[j]?.topicId &&
          !purchasedItems[j]?.isBundle
        ) {
          cartItems[i]?.topicQuizList.forEach((item) => {
            if (item.quizId === purchasedItems[j].quizId) {
              item.addToCart = false; // Set addToCart flag to false for matching quizId
            }
          });
        }
      }
      if (!isExist) {
        arr.push(cartItems[i]); // Add cart item to the final array if no bundle is found
      }
    }
    localStorage.setItem("ItemsAddedToCart", JSON.stringify(arr));
  }
};
