import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import { Dialog } from "primereact/dialog";
import styles from "./signUp.module.scss";
import * as YUP from "yup";
import { Button } from "primereact/button";
import { api } from "../../api/api";
import { otpVerification } from "../../services/forgotPassword";

const initialValue = {
  signUpOtpFirst: "",
  signUpOtpSecond: "",
  signUpOtpThird: "",
  signUpOtpFourth: "",
};
const SignUpDialog = ({ visible, onHide, email, onOTPVerified }) => {
  const [buttonClicked, setButtonClicked] = useState(false);
  const [timeLeft, setTimeLeft] = useState(30);
  // const [canResend, setCanResend] = useState(false);
  const [resend, setResend] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  // const [buttonLabel, setButtonLabel] = useState('Continue');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (visible) {
      setTimeLeft(30);
    }
  }, [visible]);

  // useEffect(() => {
  //     let timer;
  //     if (visible && timeLeft > 0) {
  //         timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
  //         if (timeLeft === 1) {
  //             setCanResend(true);
  //         }
  //     }
  //     return () => clearTimeout(timer);
  // }, [timeLeft, visible]);
  useEffect(() => {
    if (visible && timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [timeLeft, visible]);
  // useEffect(() => {
  //     setButtonLabel(timeLeft > 0 ? `Continue` : 'Resend OTP');
  // }, [timeLeft]);

  // const formatTime = (seconds) => {
  //     const minutes = Math.floor(seconds / 60);
  //     const remainingSeconds = Math.round(seconds % 60);
  //     const formattedMinutes = String(minutes).padStart(2, '0');
  //     const formattedSeconds = String(remainingSeconds).padStart(2, '0');
  //     return `${formattedMinutes}:${formattedSeconds}`;
  // };
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}.${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  const validationSchema = YUP.object().shape({
    signUpOtpFirst: YUP.string().required("OTP Required"),
    signUpOtpSecond: YUP.string().required("OTP Required"),
    signUpOtpThird: YUP.string().required("OTP Required"),
    signUpOtpFourth: YUP.string().required("OTP Required"),
  });

  const handleInputChange = (e, setFieldValue, fieldName, nextFieldName) => {
    const value = e.target.value;
    if (/^[0-9]$/.test(value)) {
      setFieldValue(fieldName, value);
      setErrorMessage("");
      if (nextFieldName) {
        document.getElementById(nextFieldName).focus();
      }
    } else if (value === "") {
      setFieldValue(fieldName, "");
    }
    setButtonClicked(false);
  };
  const handleKeyDown = (e, prevFieldName) => {
    if (e.key === "Backspace" && !e.target.value && prevFieldName) {
      document.getElementById(prevFieldName).focus();
    }
  };

  const submitHandlers = async (values, { setFieldValue }) => {
    setLoading(true);
    const otpValue = `${values.signUpOtpFirst}${values.signUpOtpSecond}${values.signUpOtpThird}${values.signUpOtpFourth}`;
    try {
      const response = await otpVerification(email, otpValue);
      onHide();
      onOTPVerified(otpValue);
    } catch (error) {
      setLoading(false);
      setErrorMessage(error.message);
      setFieldValue("signUpOtpFirst", "");
      setFieldValue("signUpOtpSecond", "");
      setFieldValue("signUpOtpThird", "");
      setFieldValue("signUpOtpFourth", "");
    } finally {
      setLoading(false);
    }
  };
  const handleButtonClick = async (setFieldValue) => {
    setButtonClicked(true);
    setErrorMessage('')
    if (timeLeft === 0) {
      try {
        await api.post(`/api/User/Register/SendOTP`, { email });
        setTimeLeft(30);
        setFieldValue("signUpOtpFirst", "");
        setFieldValue("signUpOtpSecond", "");
        setFieldValue("signUpOtpThird", "");
        setFieldValue("signUpOtpFourth", "");
      } catch (error) {
        console.log(error);
      }
    }
  };
  return (
    <div>
      <Dialog
        header="Verify your email address"
        visible={visible}
        style={{ width: "70vw" }}
        onHide={onHide}
        className={`p-fluid ${styles.otpDialog}`}
      >
        <div className={`${styles.dialog_desc}`}>
          <p className="mb-0">
            Please check your email account for the verification code we just
            sent you and enter that code in the box below.
          </p>
        </div>
        <div className={`${styles.dialog_content}`}>
          <Formik
            initialValues={initialValue}
            validationSchema={validationSchema}
            onSubmit={submitHandlers}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                <div className={`${styles.dialog_otp}`}>
                  <div className={`${styles.otp_label}`}>Enter OTP</div>
                  <div className="d-flex w-75 justify-content-start mb-2">
                    <Field
                      id="signUpOtpFirst"
                      name="signUpOtpFirst"
                      className="mb-3 text-center"
                      autoComplete="off"
                      value={values.signUpOtpFirst}
                      onChange={(e) =>
                        handleInputChange(
                          e,
                          setFieldValue,
                          "signUpOtpFirst",
                          "signUpOtpSecond"
                        )
                      }
                      onKeyDown={(e) => handleKeyDown(e, null)}
                      onBlur={handleBlur}
                    />
                    <Field
                      id="signUpOtpSecond"
                      name="signUpOtpSecond"
                      className="mb-3 ms-3 text-center"
                      autoComplete="off"
                      value={values.signUpOtpSecond}
                      onChange={(e) =>
                        handleInputChange(
                          e,
                          setFieldValue,
                          "signUpOtpSecond",
                          "signUpOtpThird",
                          "signUpOtpFirst"
                        )
                      }
                      onKeyDown={(e) => handleKeyDown(e, "signUpOtpFirst")}
                      onBlur={handleBlur}
                    />
                    <Field
                      id="signUpOtpThird"
                      name="signUpOtpThird"
                      className="mb-3 ms-3 text-center"
                      autoComplete="off"
                      value={values.signUpOtpThird}
                      onChange={(e) =>
                        handleInputChange(
                          e,
                          setFieldValue,
                          "signUpOtpThird",
                          "signUpOtpFourth",
                          "signUpOtpSecond"
                        )
                      }
                      onKeyDown={(e) => handleKeyDown(e, "signUpOtpSecond")}
                      onBlur={handleBlur}
                    />
                    <Field
                      id="signUpOtpFourth"
                      name="signUpOtpFourth"
                      className="mb-3 ms-3 text-center"
                      autoComplete="off"
                      value={values.signUpOtpFourth}
                      onChange={(e) =>
                        handleInputChange(
                          e,
                          setFieldValue,
                          "signUpOtpFourth",
                          null,
                          "signUpOtpThird"
                        )
                      }
                      onKeyDown={(e) => handleKeyDown(e, "signUpOtpThird")}
                      onBlur={handleBlur}
                    />
                  </div>
                  <div className="text-danger mb-2">
                    {errorMessage ? (
                      <div>{errorMessage}</div>
                    ) : (
                      errors.signUpOtpFourth &&
                      touched.signUpOtpFourth &&
                      !resend && <div>{errors.signUpOtpFourth}</div>
                    )}
                  </div>
                  {/* {timeLeft > 0 && (
                                        <div className={`mb-3 ${styles.otptimer_text}`}>Code expires in:<strong className='ps-1'>{formatTime(timeLeft)}</strong></div>
                                    )} */}
                  <div className={`${styles.dialog_button}`}>
                    <Button
                      type="submit"
                      // disabled={timeLeft === 0 && buttonLabel === 'Continue'}
                      className="d-flex justify-content-center"
                    >
                      {loading ? (
                        <span className={`${styles.loader_spinner}`}></span>
                      ) : (
                        <span>Continue</span>
                      )}
                    </Button>
                  </div>
                  <div className={`${styles.resend_timer} mt-3`}>
                    <p>
                      Didn't receive the OTP?{" "}
                      {timeLeft > 0 ? (
                        <>
                          {" "}
                          Resend after <span>{formatTime(timeLeft)}</span>
                        </>
                      ) : (
                        <span>
                          <a
                            href="#"
                            onClick={() => {
                              handleButtonClick(setFieldValue);
                            }}
                          >
                            Resend OTP
                          </a>
                        </span>
                      )}
                    </p>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Dialog>
    </div>
  );
};

export default SignUpDialog;
