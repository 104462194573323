import { useNavigate, useParams } from "react-router-dom";
import { ConfirmDialog } from "primereact/confirmdialog"; // For <ConfirmDialog /> component
import {
  getFirstQuestion,
  fetchNextQuestion,
  fetchPrevQuestion,
  submitQuiz,
  cancelQuiz,
} from "../../services/quizQuestions";
import QuizBar from "./QuizBar";
import QuizQuestions from "./QuizQuestions";
import { useEffect, useState, useRef } from "react";
import { Toast } from "primereact/toast";
import { Skeleton } from "primereact/skeleton";
import styles from "./Quiz.module.scss";
import { useLoginUserData } from "../../store/login";
import { appInsights } from "../../AppInsights";
const Quiz = () => {
  appInsights.trackPageView("UI",{name: 'Quiz.Jsx component visited' });
  const toast = useRef(null);
  const navigate = useNavigate();
  const { id, levelId } = useParams();
  const [questionNo, setQuestionNo] = useState(1);
  const [answeredQuestionCount, setAnsweredQuestionCount] = useState(0);
  const [showDialog, setShowDialog] = useState(false);
  const [quizDetails, setQuizDetails] = useState(null);
  const [questionData, setQuestionData] = useState(null);
  const [showSkeleton, setShowSkeleton] = useState(true);
  const [showExit, setshowExit] = useState(true);
  const [totalNumberOfQuestions, setTotalNumberOfQuestions] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [list,setList] = useState([])
  const [isTimerPaused, setIsTimerPaused] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [quizDuration, setQuizDuration] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [autoSubmit,setAutoSubmit] =useState(false);
  const totalTimeTaken = useRef(0);
  const { loginUserData } = useLoginUserData();
  const superAdminLogin = loginUserData && (loginUserData?.roleId === 1 || loginUserData.roleId === 4 || loginUserData.roleId === 5);
  const title = "Quiz";

  const navigateBackHome = () => {
    setShowDialog(true);
    setIsTimerPaused(true);
  };
  // const handleResumeTimer = () => {
  //   setIsTimerPaused(false); // Resume the timer when returning
  //   setShowDialog(false); // Close the dialog
  // };
  const accept = async() => {
    let userQuizId = questionData?.[0]?.userQuizId
    try{
      const response = await cancelQuiz(id,userQuizId);
      navigate("/");
    }catch(error){
      showError(error);
      appInsights.trackException("UI", { exception: error });
    }
  };

  const reject = () => {
    setShowDialog(false);
    setIsTimerPaused(false);
  };


  useEffect(() => {
    fetchQuizFirstQuestion();
  }, [id]);

  const formatTimeTaken = (timeInSeconds) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;
    return [
      String(hours).padStart(2, "0"),
      String(minutes).padStart(2, "0"),
      String(seconds).padStart(2, "0"),
    ].join(":");
  };
  const fetchQuizFirstQuestion = async () => {
    try {
      setShowSkeleton(true);
      if (id) {
        let result = "";
        let levelDifficultyId = levelId ? levelId : "";
        result = await getFirstQuestion(id, levelDifficultyId);
        const quizDetailObj = {
          quizName: result[0]?.quizName,
          numberOfQuestions: result[0]?.numberOfQuestionsperQuiz,
          quizDuration: result[0]?.quizDurationInSec / 60,
        };
        setQuizDetails(quizDetailObj);
        setTotalNumberOfQuestions(result[0]?.numberOfQuestionsperQuiz);
        setQuestionData(result);
        setQuizDuration(result[0]?.quizDurationInSec);
        setShowSkeleton(false);
      }
    } catch (error) {
      appInsights.trackException("UI",{ exception: error });
      if(error?.status === 404){
         return  setErrorMessage("Quiz Not Available");
      }
      if (error?.status == 401 || error?.status ==  403) {
        return setTimeout(() => {
          navigate("/UnAuthorized", {
            state: { unauthorized: true },
            replace: true,
          });
        }, 1000); 
      }
         if (error?.status == 400) {
          !superAdminLogin? setErrorMessage("Quiz coming soon") : setErrorMessage(error?.title);
      } else{
        return  setErrorMessage("Internal Error");
      }
      // !superAdminLogin? setErrorMessage("Quiz coming soon") : setErrorMessage("Please Upload Questions under this quiz category");
      setShowSkeleton(false);
    }
  };
  
  const getNextOrPrevQuestion = async (nextOrPrev, answers) => {
    try {
      setIsTimerPaused(true);
      const questionId = questionData[0]?.questionId;
      const userQuizId = questionData[0]?.userQuizId;
      let result = "";
      setShowSkeleton(true);
  
      // Track whether the current question is already answered
      const isAlreadyAnswered = list.some(
        (item) => item?.questionId === questionId
      );
  
      // Update the selected options
      setSelectedOptions((prevOptions) => ({
        ...prevOptions,
        [questionNo]: answers,
      }));
  
      // Handle answer selection logic
      const updateAnsweredCount = () => {
        if (answers?.length > 0 && !isAlreadyAnswered) {
          // Increment count if answered and not already in the list
          setList((prevList) => [
            ...prevList,
            { questionId: questionId, optionSelected: answers },
          ]);
          setAnsweredQuestionCount((prevCount) => prevCount + 1);
        } else if (answers?.length === 0 && isAlreadyAnswered) {
          // Decrement count if answers are removed
          setList((prevList) =>
            prevList.filter((item) => item.questionId !== questionId)
          );
          setAnsweredQuestionCount((prevCount) => prevCount - 1);
        }
      };
  
      // Handle next, previous, and submit actions
      if (nextOrPrev === "next") {
        updateAnsweredCount();
        result = await fetchNextQuestion(id, questionId, userQuizId, answers);
        setQuestionNo(questionNo + 1);
        setQuestionData(result);
      } else if (nextOrPrev === "prev") {
        updateAnsweredCount();
        result = await fetchPrevQuestion(id, questionId, userQuizId,answers);
        setQuestionNo(questionNo - 1);
        setQuestionData(result);
      } else if (nextOrPrev === "submit") {
        setButtonDisabled(true);
        updateAnsweredCount();
        
        const timeTaken = totalTimeTaken.current;
        const formattedTimeTaken = formatTimeTaken(timeTaken);
        result = await fetchNextQuestion(id, questionId, userQuizId, answers);
  
        if (result) {
          let submitData = await submitQuiz(id, userQuizId, formattedTimeTaken);
          toast?.current?.show({
            severity: "success",
            summary: "Success",
            detail: "Submitted Quiz successfully",
            life: 4000,
          });
          // setQuestionNo(1);
          localStorage.setItem("result", JSON.stringify(submitData));
          const quizId = id == 1 ? 1 : id;
          // setQuestionData(null);
          setTimeout(() => {
            navigate(`/Result/${userQuizId}/${quizId}`);
          }, 400);
        }
      }
      setIsTimerPaused(false);
      setShowSkeleton(false);
    } catch (error) {
      appInsights.trackException("UI",{ exception: error });
      setShowSkeleton(false);
      showError(error?.message)
      setIsTimerPaused(false);
      if (error?.message === "Unauthorized") {
        return setTimeout(() => {
          navigate("/UnAuthorized", {
            state: { unauthorized: true },
            replace: true,
          });
        }, 1000);
      }
    } 
  };
  
  
  const handleTimerEnd = async () => {
    setButtonDisabled(true);
    setAutoSubmit(true);
  };
  const showError = (msg) => {
    toast?.current?.show({
      severity: "error",
      summary: "Error",
      detail: msg,
      life: 3000,
    });
  };
  return (
    <>
      <div className={styles.container}>
        <div>
          <p className={styles.heading}>{title}</p>
        </div>
        {quizDetails ? (
          <div className="row">
            <div className="col-12">
              <QuizBar
                quizDetails={quizDetails}
                questionNo={questionNo}
                answeredQuestionCount={answeredQuestionCount}
                navigateBackHome={navigateBackHome}
                onTimerEnd={handleTimerEnd}
                isTimerPaused={isTimerPaused}
                setTotalTimeTakenRef={(timeTaken) =>
                  (totalTimeTaken.current = timeTaken)
                }
                buttonDisabled={buttonDisabled}
                showDialog={showDialog}
              />
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-12">
              {errorMessage ? (
                <div></div>
              ) : (
                <div>
                  <Skeleton
                    width="100%"
                    height={30}
                    className={`my-2 mx-1 ${styles.customSkeleton}`}
                  />
                  <Skeleton
                    width="100%"
                    height={50}
                    className={`my-2 mx-1 ${styles.customSkeleton}`}
                  />
                </div>
              )}
            </div>
          </div>
        )}
        {(!showSkeleton && questionData) || (!showSkeleton && autoSubmit) ? (
          <div className="pt-3">
            <QuizQuestions
              questionDetails={questionData}
              questionNo={questionNo}
              totalQuestions={totalNumberOfQuestions}
              selectedOptions={selectedOptions[questionNo] || []}
              nextQuestion={getNextOrPrevQuestion}
              autoSubmit={autoSubmit}
              autoSubmitDuration={quizDuration}
            />
          </div>
        ) : (
          <div>
            {errorMessage ? (
              <div className={`${styles.errorMessage} d-flex justify-content-center align-items-center px-3 text-center`}>
                <h2>{errorMessage}</h2>
              </div>
            ) : (
              <Skeleton
                width="100%"
                height="15rem"
                className={`my-2 mx-1 ${styles.customSkeleton}`}
              />
            )}
          </div>
        )}
        {showDialog && (
          <ConfirmDialog
            group="declarative"
            visible={showDialog}
            onHide={() => setShowDialog(false)}
            message="Are you sure you want to Exit Quiz?"
            header="Confirmation"
            icon="pi pi-exclamation-triangle"
            accept={accept} 
            reject={reject} 
            className={`${styles?.confirmDialog} tokenExpireDialog`}
          />
        )}
        <Toast ref={toast} position="center" />
      </div>
    </>
  );
};

export default Quiz;
