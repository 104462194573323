import React, { useEffect, useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import styles from "./adminTab.module.scss";
import UploadQuiz from "../uploadQuiz/uploadQuiz";
import ViewQuiz from "../viewQuiz/viewQuiz";
import { appInsights } from "../../../AppInsights";

const AdminTab = () => {
  appInsights.trackPageView("UI", { name: "Admin tab.Jsx component visited" });
  const [loading, setLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const savedIndex = localStorage.getItem("activeAdminTabIndex");
    if (savedIndex !== null) {
      setActiveIndex(parseInt(savedIndex, 10));
    }
    return () => {
      localStorage.removeItem("activeAdminTabIndex");
    };
  }, []);
  const handleTabChange = (e) => {
    if (loading) {
      alert(
        "Form submission is in progress. Please wait for it to complete before switching tabs."
      );
      return;
    }
    setActiveIndex(e.index);
    localStorage.setItem("activeAdminTabIndex", e.index);
  };
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (loading) {
        event.preventDefault();
        event.returnValue =
          "You have unsaved changes. Do you really want to leave?";
        return "You have unsaved changes. Do you really want to leave?";
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [loading]);
  
  return (
    <div className="container-fluid">
      <div className="px-lg-5 mx-4 py-3">
        <div className={`${styles.tabheading}`}>
          <h4 className="mt-2 mb-4">MANAGE QUIZZES</h4>
        </div>
        <TabView activeIndex={activeIndex} onTabChange={handleTabChange}>
          <TabPanel header="Upload">
            <UploadQuiz
              flag="create"
              setLoading={setLoading}
              loading={loading}
            />
          </TabPanel>
          <TabPanel header="Update">
            <UploadQuiz flag="edit" setLoading={setLoading} loading={loading} />
          </TabPanel>
          <TabPanel header="View / Add Images">
            <ViewQuiz />
          </TabPanel>
        </TabView>
      </div>
    </div>
  );
};

export default AdminTab;
